import authService from 'services/auth';

export const RESPOND_OTP_CHALLENGE_REQUEST = 'RESPOND_OTP_CHALLENGE_REQUEST';
export const RESPOND_OTP_CHALLENGE_SUCCESS = 'RESPOND_OTP_CHALLENGE_SUCCESS';
export const RESPOND_OTP_CHALLENGE_FAILURE = 'RESPOND_OTP_CHALLENGE_FAILURE';

export function respondSoftwareTokenMFA({username, remember_device, software_token_mfa_code}) {
    return (dispatch) => {
        dispatch({
            type: RESPOND_OTP_CHALLENGE_REQUEST
        });
        authService.respondSoftwareTokenMFA(username, software_token_mfa_code)
            .then((data) => {
                    return dispatch({
                        type: RESPOND_OTP_CHALLENGE_SUCCESS,
                        auth: data,
                        remember_device: remember_device
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: RESPOND_OTP_CHALLENGE_FAILURE,
                    error: error
                });
            });
    }
}
