import styled from "styled-components";
import { palette } from "styled-theme";
import { transition, borderRadius } from "settings/styles/style-util";
import WithDirection from "settings/styles/withDirection";

const TopbarWrapper = styled.div`
  .isomorphicTopbar {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    z-index: 1000;
    ${transition()};

    @media only screen and (max-width: 767px) {
      padding: 0 20px 0 20px !important;
    }

    .isoLeft {
      display: flex;
      align-items: center;

      div {
        &.isoUser {
          cursor: pointer;
          line-height: normal;
          position: relative;
          display: inline-block;

          .isoImgWrapper {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-color: ${palette("grayscale", 9)};
            ${borderRadius("10%")};
            border: solid 1px ${palette("primary", 9)};

            img {
              height: 100%;
              width: 100%;
              object-fit: fill;
            }

            .userActivity {
              width: 10px;
              height: 10px;
              display: block;
              background-color: ${palette("color", 3)};
              position: absolute;
              bottom: 0;
              right: 0px;
              border: 1px solid #ffffff;
              ${borderRadius("10%")};
            }
          }
        }
      }
    }

    .isoLogoWrapper {
      height: 50px;
      background-color: #2f2e3f;
      margin: 0;
      padding: 0 10px;
      text-align: center;
      overflow: hidden;
      ${borderRadius()};

      a {
        font-size: 21px;
        font-weight: 300;
        line-height: 50px;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: ${palette("grayscale", 6)};
        display: block;
        text-decoration: none;
      }
    }

    .isoDashboardMenu {
      border-bottom: 0;

      @media only screen and (max-width: 767px) {
        .anticon {
          margin-right: 0;
        }
        .nav-text {
          display: none;
        }
      }
    }
  }
`;

export default WithDirection(TopbarWrapper);
