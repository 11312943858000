import React from "react";
import ReactDOM from "react-dom";
import App from "./apps/root/App";
import * as serviceWorker from "./helpers/serviceWorker";

const render = () => {
  ReactDOM.render(<App />, document.getElementById("root"));
};

window.onload = () => {
  render();
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
