import usersService from "services/users";
import passwordService from "services/password";

export const LIST_USERS_IDENTITIES_REQUEST = "LIST_USERS_IDENTITIES_REQUEST";
export const LIST_USERS_IDENTITIES_FAILURE = "LIST_USERS_IDENTITIES_FAILURE";
export const LIST_USERS_IDENTITIES_SUCCESS = "LIST_USERS_IDENTITIES_SUCCESS";

export const LIST_USERS_PROFILES_REQUEST = "LIST_USERS_PROFILES_REQUEST";
export const LIST_USERS_PROFILES_FAILURE = "LIST_USERS_PROFILES_FAILURE";
export const LIST_USERS_PROFILES_SUCCESS = "LIST_USERS_PROFILES_SUCCESS";

export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";

export const GET_USER_IDENTITY_REQUEST = "GET_USER_IDENTITY_REQUEST";
export const GET_USER_IDENTITY_FAILURE = "GET_USER_IDENTITY_FAILURE";
export const GET_USER_IDENTITY_SUCCESS = "GET_USER_IDENTITY_SUCCESS";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const SUSPEND_USER_ACCOUNT_REQUEST = "SUSPEND_USER_ACCOUNT_REQUEST";
export const SUSPEND_USER_ACCOUNT_FAILURE = "SUSPEND_USER_ACCOUNT_FAILURE";
export const SUSPEND_USER_ACCOUNT_SUCCESS = "SUSPEND_USER_ACCOUNT_SUCCESS";

export const REINSTATE_USER_ACCOUNT_REQUEST = "REINSTATE_USER_ACCOUNT_REQUEST";
export const REINSTATE_USER_ACCOUNT_FAILURE = "REINSTATE_USER_ACCOUNT_FAILURE";
export const REINSTATE_USER_ACCOUNT_SUCCESS = "REINSTATE_USER_ACCOUNT_SUCCESS";

export const FORCE_RESET_PASSWORD_REQUEST = "FORCE_RESET_PASSWORD_REQUEST";
export const FORCE_RESET_PASSWORD_FAILURE = "FORCE_RESET_PASSWORD_FAILURE";
export const FORCE_RESET_PASSWORD_SUCCESS = "FORCE_RESET_PASSWORD_SUCCESS";

export const INVITE_USER_REQUEST = "INVITE_USER_REQUEST";
export const INVITE_USER_FAILURE = "INVITE_USER_FAILURE";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";

export function listUsersIdentities(params) {
  return (dispatch) => {
    dispatch({
      type: LIST_USERS_IDENTITIES_REQUEST,
    });
    return usersService
      .listUsersIdentities(params)
      .then((data) => {
        return dispatch({
          type: LIST_USERS_IDENTITIES_SUCCESS,
          identities: data.identities,
          next_page: data.next_page,
          previous_page: params.next_page,
        });
      })
      .catch((error) => {
        return dispatch({
          type: LIST_USERS_IDENTITIES_FAILURE,
          error: error,
        });
      });
  };
}

export function listUsersProfiles() {
  return (dispatch) => {
    dispatch({
      type: LIST_USERS_PROFILES_REQUEST,
    });
    return usersService
      .listUsersProfiles()
      .then((data) => {
        return dispatch({
          type: LIST_USERS_PROFILES_SUCCESS,
          profiles: data.profiles,
        });
      })
      .catch((error) => {
        return dispatch({
          type: LIST_USERS_PROFILES_FAILURE,
          error: error,
        });
      });
  };
}

export function getUserIdentity(username) {
  return (dispatch) => {
    dispatch({
      type: GET_USER_IDENTITY_REQUEST,
    });
    return usersService
      .retrieveIdentity(username)
      .then((identity) => {
        return dispatch({
          type: GET_USER_IDENTITY_SUCCESS,
          identity: identity,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_USER_IDENTITY_FAILURE,
          error: error,
        });
      });
  };
}

export function getUserDetails(username) {
  return (dispatch) => {
    dispatch({
      type: GET_USER_DETAILS_REQUEST,
    });
    return usersService
      .retrieveUser(username)
      .then((data) => {
        return dispatch({
          type: GET_USER_DETAILS_SUCCESS,
          selected_user: data.user,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_USER_DETAILS_FAILURE,
          error: error,
        });
      });
  };
}

export function suspendUserAccount(username) {
  return (dispatch) => {
    dispatch({
      type: SUSPEND_USER_ACCOUNT_REQUEST,
    });
    return usersService
      .suspendUserAccount(username)
      .then((data) => {
        return dispatch({
          type: SUSPEND_USER_ACCOUNT_SUCCESS,
          data: data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: SUSPEND_USER_ACCOUNT_FAILURE,
          error: error,
        });
      });
  };
}

export function reinstateUserAccount(username) {
  return (dispatch) => {
    dispatch({
      type: REINSTATE_USER_ACCOUNT_REQUEST,
    });
    return usersService
      .reinstateUserAccount(username)
      .then((data) => {
        return dispatch({
          type: REINSTATE_USER_ACCOUNT_SUCCESS,
          data: data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: REINSTATE_USER_ACCOUNT_FAILURE,
          error: error,
        });
      });
  };
}

export function deleteUser(username) {
  return (dispatch) => {
    dispatch({
      type: DELETE_USER_REQUEST,
    });
    return usersService
      .deleteUser(username)
      .then((data) => {
        return dispatch({
          type: DELETE_USER_SUCCESS,
        });
      })
      .catch((error) => {
        return dispatch({
          type: DELETE_USER_FAILURE,
          error: error,
        });
      });
  };
}

export function forceResetPassword(username) {
  return (dispatch) => {
    dispatch({
      type: FORCE_RESET_PASSWORD_REQUEST,
    });
    return passwordService
      .forceResetPassword(username)
      .then((response) => {
        return dispatch({
          type: FORCE_RESET_PASSWORD_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: FORCE_RESET_PASSWORD_FAILURE,
          error: error,
        });
      });
  };
}

export function inviteUser(invite) {
  return (dispatch) => {
    dispatch({
      type: INVITE_USER_REQUEST,
    });
    return usersService
      .invite(invite)
      .then((data) => {
        return dispatch({
          type: INVITE_USER_SUCCESS,
        });
      })
      .catch((error) => {
        return dispatch({
          type: INVITE_USER_FAILURE,
          error: error,
        });
      });
  };
}
