import {useThemeSwitcher} from "react-css-theme-switcher";

function Illustration(props) {
    const prefix = "/illustrations"
    const { currentTheme } = useThemeSwitcher();

    const getIllustration = (illustration) => {
        let variation = ((currentTheme) ? currentTheme.split("_")[1] : 'downy');
        return `${prefix}/${variation}/${illustration}.svg`
    }

    return (
        <img
            src={getIllustration(props.illustration)}
            alt="Illustration"
            style={props.style}
            className={props.className}
        />
    );
}

export { Illustration };