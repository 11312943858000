import {all, takeEvery, put, fork} from 'redux-saga/effects';
import * as Actions from './actions';
import * as AuthActions from "redux/public/auth/signin/actions";


export function* onRespondSMSChallengeSuccess() {
    yield takeEvery(Actions.RESPOND_SMS_CHALLENGE_SUCCESS, function* ({auth, remember_device}) {
        yield put({type: AuthActions.POST_AUTHENTICATION, auth: auth, remember_device: remember_device});
    });
}

export default function* rootSaga() {
    yield all([
        fork(onRespondSMSChallengeSuccess),
    ]);
}
