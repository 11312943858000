import axios from 'services/axios';
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie"


class AuthUtils {
    // NAIVE TOKENS VALIDATION
    // =======================
    isAuthTokenValid = id_token => {
        if (!id_token) {
            return false;
        }
        const decoded = jwtDecode(id_token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.info('Id token expired');
            return false;
        }
        else {
            return true;
        }
    };


    // COOKIES OPTIONS
    // ===============

    defaultCookiesOptions = () => {
        if (['qa', 'prod'].includes(process.env.REACT_APP_ENV))
            return {
                secure: true,
                path: '/',
                domain: process.env.REACT_APP_DOMAIN_NAME,
                expires: 365
            };
        else
            return {
                path: '/',
                domain: process.env.REACT_APP_DOMAIN_NAME,
                expires: 365
            };
    };


    // CHALLENGE SESSION
    // =================
    setChallengeSession = (challenge_session) => {
        if (challenge_session) {
            let options = this.defaultCookiesOptions();
            Cookies.set('challenge_session', challenge_session, options);
            axios.defaults.headers.common['MIMOTO_CHALLENGE_SESSION'] = challenge_session;
        }
    };


    // GET/SET Identity
    // ================

    setIdentification = (identification) => {
        let identity = JSON.stringify(identification);
        localStorage.setItem('identification', identity);
    };

    getIdentification = () => {
        let identity = localStorage.getItem('identification');
        return JSON.parse((identity) ? identity : '{}');
    };

    // GET/SET User
    // ================

    setUser = (user) => {
        let _user = JSON.stringify(user);
        localStorage.setItem('user', _user);
        this.saveOrUpdateAccount(user);
    };

    getUser = () => {
        let _user = localStorage.getItem('user');
        return JSON.parse((_user) ? _user : '{}');
    };

    saveOrUpdateAccount = (account) => {
        let saved_accounts = localStorage.getItem('accounts');
        saved_accounts = JSON.parse((saved_accounts) ? saved_accounts : '{}');
        saved_accounts[account.data.username] = account;
        localStorage.setItem('accounts', JSON.stringify(saved_accounts));
    };

    // GET/SET JWTs
    // ============

    setSession = (id_token, access_token, refresh_token) => {
        let options = this.defaultCookiesOptions();
        // Id Token
        Cookies.set('id_token', id_token, options);
        // Access token
        Cookies.set('access_token', access_token, options);
        // Refresh token
        if (refresh_token) {
            Cookies.set('refresh_token', refresh_token, options);
        }
    };

    getAuth = () => {
        return {
            id_token: Cookies.get('id_token'),
            access_token: Cookies.get('access_token'),
            refresh_token: Cookies.get('refresh_token')
        }
    };

    revokeSession = () => {
        let options = this.defaultCookiesOptions();
        Cookies.remove('id_token', options);
        Cookies.remove('access_token', options);
        Cookies.remove('refresh_token', options);
    };

    localSignOut = () => {
        this.revokeSession();
    };

    setStayConnected = (stay_connected) => {
        let options = this.defaultCookiesOptions();
        Cookies.set('stay_connected', stay_connected, options);
    };

    getStayConnected = () => {
        return JSON.parse(Cookies.get('stay_connected') || 'false');
    };

    // GET/SET IAM CREDENTIALS
    // =======================

    setAWSSTSAuthorization = (authorization) => {
        localStorage.setItem('aws_sts_authorization', JSON.stringify(authorization));
    };

    getAWSSTSAuthorization = () => {
        let aws_sts = localStorage.getItem('aws_sts_authorization');
        return JSON.parse((aws_sts) ? aws_sts : '{}');
    };

    revokeCredentials = () => {
        localStorage.removeItem('aws_credentials');
    };

    // GET/UPDATE DEVICE METADATA
    // ==========================
    setDeviceMetadata = (device_metadata) => {
        let options = this.defaultCookiesOptions();
        let identification = this.getIdentification();
        let username = identification.username;
        if (device_metadata.device_key) {
            Cookies.set(`${username}-device_key`, device_metadata.device_key, options);
            Cookies.set(`${username}-device_group_key`, device_metadata.device_group_key, options);
            Cookies.set(`${username}-device_password`, device_metadata.device_password, options);
            Cookies.set(`${username}-device_password_verifier`, device_metadata.device_password_verifier, options);
        }
    };

    getDeviceMetadata = () => {
        let identification = this.getIdentification();
        let username = identification.username;
        return {
            device_name: Cookies.get(`${username}-device_name`),
            device_key: Cookies.get(`${username}-device_key`),
            device_group_key: Cookies.get(`${username}-device_group_key`),
            device_password: Cookies.get(`${username}-device_password`),
            device_password_verifier: Cookies.get(`${username}-device_password_verifier`),
            device_salt: Cookies.get(`${username}-device_salt`)
        }
    };

    setDeviceKey = (device_key) => {
        let options = this.defaultCookiesOptions();
        let identification = this.getIdentification();
        let username = identification.username;
        if (device_key) {
            Cookies.set(`${username}-device_key`, device_key, options);
        }
    };
}

const instance = new AuthUtils();

export default (instance);