import * as Actions from "./actions";

const initialState = {
  role: "guest",
  groups: [],
  data: {
    identities: {
      email: "johndoe@withinpixels.com",
      email_verified: false,
      phone_number: "+212662510043",
      phone_number_verified: false,
    },
    profile: {
      first_name: "John",
      last_name: "Doe",
      gender: "male",
      birthdate: "12-10-1993",
      picture: "assets/images/avatars/Velazquez.jpg",
      status_text: "On Vacation",
      status_emoji: { id: "palm_tree", skin: 3 },
      status_busy: "0",
      status_clearance_after: "after_one_week",
      pw_last_changed: "1",
    },
    mfa_options: null,
    sms_mfa_settings: {
      enabled: false,
      preferred: false,
    },
    software_token_mfa_settings: {
      enabled: false,
      preferred: false,
    },
    username: "c88f6443-1859-478e-b5a0-c71594007b1f",
  },
};

const user = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CURRENT_USER_SUCCESS: {
      return {
        ...initialState,
        success: true,
        ...action.user,
      };
    }
    case Actions.GET_CURRENT_USER_FAILURE: {
      return {
        success: false,
        error: action.error,
      };
    }
    case Actions.SET_USER_DATA: {
      return {
        success: true,
        ...action.user,
      };
    }
    case Actions.CLEAR_USER_DATA: {
      return {
        success: true,
        ...initialState,
      };
    }
    case Actions.UPDATE_USER_STATUS_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.UPDATE_USER_STATUS_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.UPDATE_USER_STATUS_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.CLEAR_USER_STATUS_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.CLEAR_USER_STATUS_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.CLEAR_USER_STATUS_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.UPDATE_USER_ABOUT_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.UPDATE_USER_ABOUT_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.UPDATE_USER_ABOUT_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.UPDATE_USER_LOCALE_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.UPDATE_USER_LOCALE_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.UPDATE_USER_LOCALE_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.UPDATE_USER_PICTURE_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.UPDATE_USER_PICTURE_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.UPDATE_USER_PICTURE_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.CLEAR_USER_PICTURE_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.CLEAR_USER_PICTURE_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.CLEAR_USER_PICTURE_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default user;
