import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "helpers/AsyncFunc";

const routes = [
  // Identities
  {
    exact: true,
    path: "identities",
    component: asyncComponent(() =>
      import("containers/manage/identities/list")
    ),
  },
  {
    exact: true,
    path: "identities/new",
    component: asyncComponent(() => import("containers/manage/identities/new")),
  },
  {
    exact: true,
    path: "identities/:username",
    component: asyncComponent(() =>
      import("containers/manage/identities/details")
    ),
  },
  {
    exact: true,
    path: "users/:username",
    component: asyncComponent(() =>
      import("containers/manage/identities/details")
    ),
  },
  // Profiles
  {
    exact: true,
    path: "profiles",
    component: asyncComponent(() => import("containers/manage/profiles/list")),
  },
  // Groups
  {
    exact: true,
    path: "groups",
    component: asyncComponent(() => import("containers/manage/groups/list")),
  },
  {
    exact: true,
    path: "groups/new",
    component: asyncComponent(() => import("containers/manage/groups/new")),
  },
  {
    exact: true,
    path: "groups/:group_name",
    component: asyncComponent(() => import("containers/manage/groups/edit")),
  },
  // Groups
  {
    exact: true,
    path: "authorizer",
    component: asyncComponent(() => import("containers/manage/authorizer")),
  },
  {
    exact: true,
    path: "",
    component: asyncComponent(() => import("containers/manage/dash")),
  },
  {
    exact: false,
    path: "",
    component: asyncComponent(() => import("containers/shared/404")),
  },
];

class AppRouter extends Component {
  render() {
    const { url } = this.props;
    return (
      <Switch>
        {routes.map((singleRoute) => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact !== false}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </Switch>
    );
  }
}

export default AppRouter;
