import React, { Component } from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { Layout, ConfigProvider } from "antd";
import { Debounce } from "react-throttle";
import WindowResizeListener from "react-window-size-listener";
import { ThemeProvider } from "styled-components";

import { AppLocale } from "apps/root/App";
import Topbar from "layout/header";
import AppRouter from "routes/admins";
import appActions from "redux/main/app/actions";
import themes from "settings/themes";
import options from "./options";

const { Content } = Layout;
const { toggleAll } = appActions;

export class AdminsApp extends Component {
  render() {
    const { url } = this.props.match;
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale];
    const appHeight = window.innerHeight;
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
            <Layout style={{ height: appHeight }}>
              <Debounce time="1000" handler="onResize">
                <WindowResizeListener
                  onResize={(windowSize) =>
                    this.props.toggleAll(
                      windowSize.windowWidth,
                      windowSize.windowHeight
                    )
                  }
                />
              </Debounce>
              <Topbar url={url} options={options} />
              <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
                <Layout className="isoContentMainLayout">
                  <Content
                    className="isomorphicContent"
                    style={{
                      padding: "50px 0 0",
                      flexShrink: "0",
                      position: "relative",
                    }}
                  >
                    <AppRouter url={url} />
                  </Content>
                </Layout>
              </Layout>
            </Layout>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

export default connect(
  (state) => ({
    locale: state.LanguageSwitcher.language.locale,
    height: state.App.height,
  }),
  { toggleAll }
)(AdminsApp);
