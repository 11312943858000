import {all, takeEvery, put, fork} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import * as Actions from './actions';

import {notification} from 'antd';

export function* requestResetPasswordSuccess() {
    yield takeEvery(Actions.REQUEST_RESET_PASSWORD_SUCCESS, function* ({feedback}) {
        let context = {
            username: feedback.username,
            alias: feedback.alias,
        };
        if (feedback.delivery_medium === 'EMAIL')
            yield put(
                push({
                    pathname: '/reset-password/with-email',
                    state: context
                })
            );
        else
            yield put(
                push({
                    pathname: '/reset-password/with-phone',
                    state: context
                })
            );
        notification.success({
            message: 'Verification code sent',
            description:
                'We have sent you a verification code to reset your password'
        })
    });
}

export function* resetPasswordSuccess() {
    yield takeEvery(Actions.RESET_PASSWORD_SUCCESS, function* () {
        yield put(
            push({
                pathname: '/auth/identify'
            })
        );
        notification.success({
            message: 'Password reset',
            description:
                'Your password successfully reset.'
        })
    });
}

export default function* rootSaga() {
    yield all([
        fork(requestResetPasswordSuccess),
        fork(resetPasswordSuccess)
    ]);
}
