import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "helpers/AsyncFunc";

const routes = [
  {
    exact: false,
    path: "home",
    component: asyncComponent(() => import("containers/account/home")),
  },
  // Profile
  {
    exact: true,
    path: "profile",
    component: asyncComponent(() => import("containers/account/profile/main")),
  },
  // Identities
  {
    exact: true,
    path: "identities",
    component: asyncComponent(() =>
      import("containers/account/identities/main")
    ),
  },
  {
    exact: true,
    path: "identities/username",
    component: asyncComponent(() =>
      import("containers/account/identities/username")
    ),
  },
  {
    exact: true,
    path: "identities/email",
    component: asyncComponent(() =>
      import("containers/account/identities/email")
    ),
  },
  {
    exact: true,
    path: "identities/phone",
    component: asyncComponent(() =>
      import("containers/account/identities/phone")
    ),
  },
  ...(process.env.REACT_APP_AWS_IAM_IDENTITY_VISIBILITY==="true" ? [{
    exact: true,
    path: "identities/aws",
    component: asyncComponent(() =>
        import("containers/account/identities/aws")
    ),
  }]: []),
  // Security
  {
    exact: true,
    path: "security",
    component: asyncComponent(() => import("containers/account/security/main")),
  },
  {
    exact: true,
    path: "security/password",
    component: asyncComponent(() =>
      import("containers/account/security/password")
    ),
  },
  {
    exact: true,
    path: "security/mfa/otp",
    component: asyncComponent(() =>
      import("containers/account/security/mfa/otp")
    ),
  },
  {
    exact: true,
    path: "security/mfa/sms",
    component: asyncComponent(() =>
      import("containers/account/security/mfa/sms")
    ),
  },
  {
    exact: true,
    path: "security/mfa/preference",
    component: asyncComponent(() =>
      import("containers/account/security/mfa/preference")
    ),
  },
  {
    exact: true,
    path: "security/devices",
    component: asyncComponent(() =>
      import("containers/account/security/devices")
    ),
  },
  {
    exact: false,
    path: "",
    component: asyncComponent(() => import("containers/shared/404")),
  },
];

class AppRouter extends Component {
  render() {
    const { url } = this.props;
    return (
      <>
        <Switch>
          {routes.map((singleRoute) => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact !== false}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
        </Switch>
      </>
    );
  }
}

export default AppRouter;
