import usersService from "services/users";
import AuthUtils from "redux/public/auth/signin/utils";
import _ from "lodash";

export const GET_CURRENT_USER_REQUEST = "GET_CURRENT_USER_REQUEST";
export const GET_CURRENT_USER_FAILURE = "GET_CURRENT_USER_FAILURE";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";

export const UPDATE_USER_STATUS_REQUEST = "UPDATE_USER_STATUS_REQUEST";
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";
export const UPDATE_USER_STATUS_FAILURE = "UPDATE_USER_STATUS_FAILURE";

export const CLEAR_USER_STATUS_REQUEST = "CLEAR_USER_STATUS_REQUEST";
export const CLEAR_USER_STATUS_SUCCESS = "CLEAR_USER_STATUS_SUCCESS";
export const CLEAR_USER_STATUS_FAILURE = "CLEAR_USER_STATUS_FAILURE";

export const UPDATE_USER_ABOUT_REQUEST = "UPDATE_USER_ABOUT_REQUEST";
export const UPDATE_USER_ABOUT_SUCCESS = "UPDATE_USER_ABOUT_SUCCESS";
export const UPDATE_USER_ABOUT_FAILURE = "UPDATE_USER_ABOUT_FAILURE";

export const UPDATE_USER_LOCALE_REQUEST = "UPDATE_USER_LOCALE_REQUEST";
export const UPDATE_USER_LOCALE_SUCCESS = "UPDATE_USER_LOCALE_SUCCESS";
export const UPDATE_USER_LOCALE_FAILURE = "UPDATE_USER_LOCALE_FAILURE";

export const UPDATE_USER_PICTURE_REQUEST = "UPDATE_USER_PICTURE_REQUEST";
export const UPDATE_USER_PICTURE_SUCCESS = "UPDATE_USER_PICTURE_SUCCESS";
export const UPDATE_USER_PICTURE_FAILURE = "UPDATE_USER_PICTURE_FAILURE";

export const CLEAR_USER_PICTURE_REQUEST = "CLEAR_USER_PICTURE_REQUEST";
export const CLEAR_USER_PICTURE_SUCCESS = "CLEAR_USER_PICTURE_SUCCESS";
export const CLEAR_USER_PICTURE_FAILURE = "CLEAR_USER_PICTURE_FAILURE";

export const DEACTIVATE_CURRENT_USER_REQUEST =
  "DEACTIVATE_CURRENT_USER_REQUEST";
export const DEACTIVATE_CURRENT_USER_SUCCESS =
  "DEACTIVATE_CURRENT_USER_SUCCESS";
export const DEACTIVATE_CURRENT_USER_FAILURE =
  "DEACTIVATE_CURRENT_USER_FAILURE";

export const TERMINATE_CURRENT_USER_REQUEST = "TERMINATE_CURRENT_USER_REQUEST";
export const TERMINATE_CURRENT_USER_SUCCESS = "TERMINATE_CURRENT_USER_SUCCESS";
export const TERMINATE_CURRENT_USER_FAILURE = "TERMINATE_CURRENT_USER_FAILURE";

export const SET_USER_DATA = "SET_USER_DATA";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";

export function setUserData(user) {
  return (dispatch) => {
    /*
        Set User Data
         */
    AuthUtils.setUser(user);
    dispatch({
      type: SET_USER_DATA,
      user: user,
    });
  };
}

export function clearUserData() {
  return (dispatch) => {
    /*
        Clear User Data
         */
    dispatch({
      type: CLEAR_USER_DATA,
    });
  };
}

export function getCurrentUser() {
  return (dispatch) => {
    dispatch({
      type: GET_CURRENT_USER_REQUEST,
    });
    return usersService
      .getCurrentUser()
      .then((data) => {
        return dispatch({
          type: GET_CURRENT_USER_SUCCESS,
          user: data.user,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_CURRENT_USER_FAILURE,
          error: error,
        });
      });
  };
}

export function updateUserAbout(about) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_ABOUT_REQUEST,
    });
    return usersService
      .updateUserProfile(about)
      .then((data) => {
        return dispatch({
          type: UPDATE_USER_ABOUT_SUCCESS,
          profile: data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_USER_ABOUT_FAILURE,
          error: error,
        });
      });
  };
}

export function updateUserLocale(locale) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_LOCALE_REQUEST,
    });
    return usersService
      .updateUserProfile(locale)
      .then((data) => {
        return dispatch({
          type: UPDATE_USER_LOCALE_SUCCESS,
          profile: data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_USER_LOCALE_FAILURE,
          error: error,
        });
      });
  };
}

export function updateUserIdentity(attribute_name, attribute_value) {
  return (dispatch, getState) => {
    /*
        Update User data
         */
    const oldUser = getState().User;
    const user = _.merge({}, oldUser, {
      data: {
        identities: {
          [attribute_name]: attribute_value,
        },
      },
    });

    //updateUserData(user);
    return dispatch(setUserData(user));
  };
}

export function updateUserIdentities(attributes) {
  return (dispatch, getState) => {
    /*
        Update User data
         */
    for (const [key, value] of Object.entries(attributes)) {
      dispatch(updateUserIdentity(key, value));
    }
  };
}

export function updateProfileAttribute(attribute_name, attribute_value) {
  return (dispatch, getState) => {
    /*
        Update User claim
         */
    const oldUser = getState().User;
    const user = _.merge({}, oldUser, {
      data: {
        profile: {
          [attribute_name]: attribute_value,
        },
      },
    });

    //updateUserData(user);
    return dispatch(setUserData(user));
  };
}

export function updateProfile(attributes) {
  return (dispatch, getState) => {
    /*
        Update User Profile
         */
    for (const [key, value] of Object.entries(attributes)) {
      dispatch(updateProfileAttribute(key, value));
    }
  };
}

export function updateUserData(attribute_name, attribute_value) {
  return (dispatch, getState) => {
    /*
        Update User data
         */
    const oldUser = getState().User;
    const user = _.merge({}, oldUser, {
      data: {
        [attribute_name]: attribute_value,
      },
    });

    //updateUserData(user);
    return dispatch(setUserData(user));
  };
}

export function updateUserStatus({
  status_text,
  status_emoji,
  status_busy,
  status_clearance_after,
}) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_STATUS_REQUEST,
    });
    return usersService
      .updateUserStatus(
        status_text,
        status_emoji,
        status_busy,
        status_clearance_after
      )
      .then((data) => {
        return dispatch({
          type: UPDATE_USER_STATUS_SUCCESS,
          status: data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_USER_STATUS_FAILURE,
          error: error,
        });
      });
  };
}

export function clearUserStatus() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_USER_STATUS_REQUEST,
    });
    return usersService
      .clearUserStatus()
      .then((data) => {
        return dispatch({
          type: CLEAR_USER_STATUS_SUCCESS,
          status: {
            status_text: null,
            status_emoji: null,
            status_busy: null,
            status_clearance_after: null,
          },
        });
      })
      .catch((error) => {
        return dispatch({
          type: CLEAR_USER_STATUS_FAILURE,
          error: error,
        });
      });
  };
}

export function updateUserPicture(picture) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_PICTURE_REQUEST,
    });
    return usersService
      .updateUserPicture(picture)
      .then((data) => {
        return dispatch({
          type: UPDATE_USER_PICTURE_SUCCESS,
          picture: data.picture,
        });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_USER_PICTURE_FAILURE,
          error: error,
        });
      });
  };
}

export function clearUserPicture() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_USER_PICTURE_REQUEST,
    });
    return usersService
      .clearUserPicture()
      .then((data) => {
        return dispatch({
          type: CLEAR_USER_PICTURE_SUCCESS,
          picture: data.picture,
        });
      })
      .catch((error) => {
        return dispatch({
          type: CLEAR_USER_PICTURE_FAILURE,
          error: error,
        });
      });
  };
}

export function deactivateCurrentUser() {
  return (dispatch) => {
    dispatch({
      type: DEACTIVATE_CURRENT_USER_REQUEST,
    });
    return usersService
      .deactivateCurrentUser()
      .then((data) => {
        return dispatch({
          type: DEACTIVATE_CURRENT_USER_SUCCESS,
        });
      })
      .catch((error) => {
        return dispatch({
          type: DEACTIVATE_CURRENT_USER_FAILURE,
          error: error,
        });
      });
  };
}

export function terminateCurrentUser() {
  return (dispatch) => {
    dispatch({
      type: TERMINATE_CURRENT_USER_REQUEST,
    });
    return usersService
      .terminateCurrentUser()
      .then((data) => {
        return dispatch({
          type: TERMINATE_CURRENT_USER_SUCCESS,
        });
      })
      .catch((error) => {
        return dispatch({
          type: TERMINATE_CURRENT_USER_FAILURE,
          error: error,
        });
      });
  };
}
