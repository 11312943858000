import * as Actions from "./actions";

const initialState = {
  success: false,
  policies: [],
  policy_view_visible: false,
  policy_document: {},
  role: {
    name: null,
    max_session_duration: null,
  },
};

const authorizer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.LIST_GROUP_POLICIES_ATTACHMENTS_REQUEST: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.LIST_GROUP_POLICIES_ATTACHMENTS_SUCCESS: {
      return {
        ...state,
        success: true,
        policies: action.policies,
        policy_view_visible: true,
      };
    }
    case Actions.LIST_GROUP_POLICIES_ATTACHMENTS_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.RETRIEVE_POLICY_REQUEST: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.RETRIEVE_POLICY_SUCCESS: {
      return {
        ...state,
        success: true,
        policy_document: action.policy_document,
      };
    }
    case Actions.RETRIEVE_POLICY_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.RETRIEVE_ROLE_REQUEST: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.RETRIEVE_ROLE_SUCCESS: {
      return {
        ...state,
        success: true,
        role: action.role,
      };
    }
    case Actions.RETRIEVE_ROLE_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.UPDATE_STS_SESSION_DURATION_SUCCESS: {
      return {
        ...state,
        success: true,
        role: {
          ...state.role,
          max_session_duration: action.max_session_duration,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default authorizer;
