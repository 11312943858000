import { all, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as Actions from "./actions";
import * as AuthActions from "redux/public/auth/signin/actions";
import { notification } from "antd";

export function* onChangePasswordSuccess() {
  yield takeEvery(
    Actions.CHANGE_PASSWORD_SUCCESS,
    function* ({ sign_out_all_devices }) {
      notification.success({
        message: "Password changed",
        description: "Your password changed successfully",
      });
      if (sign_out_all_devices) yield put(AuthActions.localSignOut());
      else
        yield put(
          push({
            pathname: "/u/security",
          })
        );
    }
  );
}

export default function* rootSaga() {
  yield all([fork(onChangePasswordSuccess)]);
}
