import * as Actions from './actions';

const initialState = {
    success: false,
    error  : {
        confirmation_code: null
    }
};

const confirm = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.CONFIRM_SIGNUP_SUCCESS:
        {
            return {
                ...initialState,
                success: true,
            };
        }
        case Actions.CONFIRM_SIGNUP_FAILURE:
        {
            return {
                success: false,
                error  : {
                    confirmation_code: 'Wrong confirmation code.'
                }
            };
        }
        case Actions.REQUEST_CONFIRMATION_SUCCESS:
        {
            return {
                ...initialState,
                success: true,
            };
        }
        default:
        {
            return state
        }
    }
};

export default confirm;