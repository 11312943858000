import usersService from "services/users";

export const UPDATE_PHONE_REQUEST = "UPDATE_PHONE_REQUEST";
export const UPDATE_PHONE_FAILURE = "UPDATE_PHONE_FAILURE";
export const UPDATE_PHONE_SUCCESS = "UPDATE_PHONE_SUCCESS";

export const VERIFY_PHONE_RQST_REQUEST = "VERIFY_PHONE_RQST_REQUEST";
export const VERIFY_PHONE_RQST_FAILURE = "VERIFY_PHONE_RQST_FAILURE";
export const VERIFY_PHONE_RQST_SUCCESS = "VERIFY_PHONE_RQST_SUCCESS";

export const VERIFY_PHONE_PROCEED_REQUEST = "VERIFY_PHONE_PROCEED_REQUEST";
export const VERIFY_PHONE_PROCEED_FAILURE = "VERIFY_PHONE_PROCEED_FAILURE";
export const VERIFY_PHONE_PROCEED_SUCCESS = "VERIFY_PHONE_PROCEED_SUCCESS";

export const PHONE_UI_RESET = "PHONE_UI_RESET";

export function updatePhone({ phone_number }) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PHONE_REQUEST,
    });
    return usersService
      .updateUserIdentity("phone_number", phone_number)
      .then((data) => {
        return dispatch({
          type: UPDATE_PHONE_SUCCESS,
          pending_verification_phone_number: phone_number,
        });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_PHONE_FAILURE,
          error: error,
        });
      });
  };
}

export function verifyPhoneRequest() {
  return (dispatch) => {
    dispatch({
      type: VERIFY_PHONE_RQST_REQUEST,
    });
    return usersService
      .verifyAttributeRequest("phone_number")
      .then((data) => {
        return dispatch({
          type: VERIFY_PHONE_RQST_SUCCESS,
        });
      })
      .catch((error) => {
        return dispatch({
          type: VERIFY_PHONE_RQST_FAILURE,
          error: error,
        });
      });
  };
}

export function verifyPhoneProceed({
  verification_code,
  enable_mfa,
  pending_verification_phone_number,
}) {
  return (dispatch) => {
    dispatch({
      type: VERIFY_PHONE_PROCEED_REQUEST,
    });
    return usersService
      .verifyAttributeProceed("phone_number", verification_code)
      .then((data) => {
        return dispatch({
          type: VERIFY_PHONE_PROCEED_SUCCESS,
          enable_mfa: enable_mfa,
          new_phone_number: pending_verification_phone_number,
        });
      })
      .catch((error) => {
        return dispatch({
          type: VERIFY_PHONE_PROCEED_FAILURE,
          error: error,
        });
      });
  };
}

export function phoneUIReset() {
  return (dispatch) => {
    return dispatch({
      type: PHONE_UI_RESET,
    });
  };
}
