import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as Actions from "./actions";
import { push } from "connected-react-router";
import { notification } from "antd";

export function* onListGroupsSuccess() {
  yield takeEvery(Actions.LIST_GROUPS_SUCCESS, function* ({ groups }) {
    yield;
  });
}

export function* onCreateGroupSuccess() {
  yield takeEvery(Actions.CREATE_GROUP_SUCCESS, function* () {
    yield put(
      push({
        pathname: "/a/groups",
      })
    );
  });
}

export function* onDeleteGroupSuccess() {
  yield takeEvery(Actions.DELETE_GROUP_SUCCESS, function* () {
    yield put(
      push({
        pathname: "/a/groups",
      })
    );
  });
}

export function* onUpdateGroupSuccess() {
  yield takeEvery(Actions.UPDATE_GROUP_SUCCESS, function* () {
    yield put(
      push({
        pathname: "/a/groups",
      })
    );
  });
}

export function* onDeleteGroupFailure() {
  yield takeEvery(Actions.DELETE_GROUP_FAILURE, function* (error) {
    notification.error({
      message: error.error.message,
      description: error.error.reason,
    });
    yield;
  });
}

export default function* rootSaga() {
  yield all([
    fork(onListGroupsSuccess),
    fork(onCreateGroupSuccess),
    fork(onDeleteGroupSuccess),
    fork(onUpdateGroupSuccess),
    fork(onDeleteGroupFailure),
  ]);
}
