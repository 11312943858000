import React from "react";
import { Link } from "react-router-dom";
import { Menu, Select } from "antd";

import IntlMessages from "components/utility/misc/intlMessages";

import tz from "data/tz";
import locales from "data/locales";
import countries from "data/countries";

const Option = Select.Option;

export function generateTZOptions() {
  return tz.map((singleTZ) => (
    <Option value={singleTZ.value} key={singleTZ.value}>
      {singleTZ.label}
    </Option>
  ));
}

export function generateLocalesOptions() {
  return locales.map((locale) => (
    <Option value={locale.value} key={locale.value}>
      {locale.label}
    </Option>
  ));
}

export function getLocalLabel(value) {
  let result = locales.filter((obj) => {
    return obj.value === value;
  });
  return result[0].label;
}

export function getTZLabel(value) {
  let result = tz.filter((obj) => {
    return obj.value === value;
  });
  return result[0].label;
}

export function generatePhoneDialCodes() {
  return countries.map((country) => (
    <Option
      value={country.dial_code}
      key={country.name}
      p_title={
        <div>
          {/*<span className={`flag-icon flag-icon-${country.code.toLowerCase()}`}*/}
          {/*      style={{width: 30, height: 20, verticalAlign: "middle"}}/>*/}
          {country.dial_code}
        </div>
      }
    >
      <span /> {country.name} ({country.dial_code})
    </Option>
  ));
}

export function getMenuItem({ singleOption, role }) {
  const { key, label, leftIcon } = singleOption;
  if (key === "/u/security" && role === "federated_user") return;
  return (
    <Menu.Item key={key}>
      <Link to={`${key}`}>
        <div className="isoMenuHolder">
          {leftIcon} <IntlMessages id={label} className="nav-text" />
        </div>
      </Link>
    </Menu.Item>
  );
}
