import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as Actions from "./actions";
import * as UserActions from "redux/account/profile/actions";
import { notification } from "antd";

// SUCCESS EFFECTS

export function* onUpdateEmailSuccess() {
  yield takeEvery(Actions.UPDATE_EMAIL_SUCCESS, function* () {
    notification.success({
      message: "Email pending verification",
      description: "Your email will be updated after the verification",
    });
  });
}

export function* onVerifyEmailRequestSuccess() {
  yield takeEvery(Actions.VERIFY_EMAIL_RQST_SUCCESS, function* () {
    notification.success({
      message: "Verification email sent",
      description: "We have sent you a verification email",
    });
    yield;
  });
}

export function* onVerifyEmailSuccess() {
  yield takeEvery(
    Actions.VERIFY_EMAIL_PROCEED_SUCCESS,
    function* ({ new_email }) {
      notification.success({
        message: "Email verified",
        description: "Your email has been verified",
      });
      yield put(UserActions.updateUserIdentity("email", new_email));
    }
  );
}

// FAILURE EFFECTS

export function* onUpdateEmailFailure() {
  yield takeEvery(Actions.UPDATE_EMAIL_FAILURE, function* ({ email }) {
    notification.error({
      message: "Email not updated",
      description: "Email already taken",
    });
    yield;
  });
}

export function* onVerifyEmailRequestFailure() {
  yield takeEvery(Actions.VERIFY_EMAIL_RQST_FAILURE, function* () {
    notification.error({
      message: "Verification email sent",
      description: "We have sent you a verification email",
    });
    yield;
  });
}

export function* onVerifyEmailFailure() {
  yield takeEvery(Actions.VERIFY_EMAIL_PROCEED_FAILURE, function* () {
    notification.error({
      message: "Email not verified",
      description:
        "Email already used by another account or incorrect verification code",
    });
    yield;
  });
}

export default function* rootSaga() {
  yield all([
    fork(onUpdateEmailSuccess),
    fork(onVerifyEmailSuccess),
    fork(onVerifyEmailRequestSuccess),
    fork(onUpdateEmailFailure),
    fork(onVerifyEmailRequestFailure),
    fork(onVerifyEmailFailure),
  ]);
}
