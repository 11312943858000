import React, { Component } from "react";
import { connect } from "react-redux";
import { Picker, Emoji } from "emoji-mart";
import {
  Typography,
  Row,
  Col,
  Divider,
  Button,
  Input,
  Checkbox,
  Form,
  Select,
} from "antd";

import "emoji-mart/css/emoji-mart.css";
import StatusDialogFormWrapper from "./style";

const FormItem = Form.Item;
const Option = Select.Option;
const Paragraph = Typography.Paragraph;
const Text = Typography.Text;

class StatusDialogForm extends Component {
  state = {
    confirmDirty: false,
    pickerVisible: false,
    suggestionsVisible: false,
    selectedEmoji: null,
    defaultEmoji: { id: "smile", skin: "3" },
    suggestions: [
      { id: "palm_tree", skin: "3", text: "On Vacation" },
      { id: "house", skin: "3", text: "Working from home" },
      { id: "face_with_thermometer", skin: "3", text: "Out sick" },
      { id: "dart", skin: "3", text: "Focusing" },
    ],
  };

  formRef = React.createRef();

  handleUpdateStatus = (values) => {
    console.log(this.state.selectedEmoji);
    let status_emoji = this.state.selectedEmoji
      ? this.state.selectedEmoji
      : this.props.UserState.data.profile["status_emoji"];
    delete status_emoji.text;
    if (status_emoji.skin) status_emoji.skin = status_emoji.skin.toString();
    let status = { ...values, status_emoji: status_emoji };
    this.props.updateUserStatus(status);
  };

  toggleEmojiPicker = () => {
    this.setState({ pickerVisible: !this.state.pickerVisible });
  };

  handleEmojiClick = (code) => {
    console.log(code);
    this.setState({ selectedEmoji: code, pickerVisible: false });
  };

  handleStatusFocus = () => {
    this.setState({ suggestionsVisible: true });
  };

  applySuggestion = (selected) => {
    this.setState({ selectedEmoji: this.state.suggestions[selected] });
    this.formRef.current.setFieldsValue({
      status_text: this.state.suggestions[selected].text,
    });
  };

  evalClearance = () => {
    if (this.props.UserState.data.profile["status_clearance_after"])
      return this.props.UserState.data.profile["status_clearance_after"];
    else return "never";
  };

  evalBusy = () => {
    let checked = null;
    if (this.props.UserState.data.profile["status_busy"])
      checked = !!+this.props.UserState.data.profile["status_busy"];
    else checked = false;
    return checked;
  };

  render() {
    const user = this.props.UserState.data;
    const {
      pickerVisible,
      selectedEmoji,
      defaultEmoji,
      suggestionsVisible,
      suggestions,
    } = this.state;

    return (
      <Form
        className="isoUpdateEmailForm"
        id="status-form"
        onFinish={this.handleUpdateStatus}
        ref={this.formRef}
        initialValues={{
          status_text: user.profile["status_text"],
          status_busy: this.evalBusy(),
          status_clearance_after: this.evalClearance(),
        }}
      >
        <StatusDialogFormWrapper className="isoStatusDialogFormWrapper">
          <div className="isoInputWrapper" style={{ height: "50px" }}>
            <FormItem
              name="status_text"
              rules={[
                {
                  required: true,
                  message: "Please input your status!",
                },
              ]}
            >
              <Input
                addonBefore={
                  <Emoji
                    emoji={
                      selectedEmoji
                        ? selectedEmoji
                        : user.profile["status_emoji"]
                        ? user.profile["status_emoji"]
                        : defaultEmoji
                    }
                    size={20}
                    onClick={this.toggleEmojiPicker}
                  />
                }
                onFocus={this.handleStatusFocus}
                onKeyUp={this.handleStatusFocus}
                placeholder="What's happening"
                autoComplete="off"
              />
            </FormItem>
          </div>
          {pickerVisible && (
            <Picker
              onSelect={this.handleEmojiClick}
              style={{ position: "absolute", zIndex: 30 }}
              theme={
                process.env.REACT_APP_THEME_ENABLE_DARK_MODE === "true"
                  ? "dark"
                  : "light"
              }
            />
          )}
          {suggestionsVisible && (
            <Row style={{ marginTop: "12px" }}>
              <Row style={{ width: "100%", marginBottom: "6px" }}>
                <Text strong>Suggestions:</Text>
              </Row>
              <Row style={{ width: "100%" }}>
                <Col span={12}>
                  <Button
                    type="link"
                    className="suggestionBtn"
                    onClick={() => this.applySuggestion(0)}
                  >
                    <Emoji emoji={suggestions[0]} size={18} />
                    <span>{suggestions[0].text}</span>
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="link"
                    className="suggestionBtn"
                    onClick={() => this.applySuggestion(1)}
                  >
                    <Emoji emoji={suggestions[1]} size={18} />
                    <span>{suggestions[1].text}</span>
                  </Button>
                </Col>
              </Row>
              <Row style={{ width: "100%", marginBottom: "6px" }}>
                <Col span={12}>
                  <Button
                    type="link"
                    className="suggestionBtn"
                    onClick={() => this.applySuggestion(2)}
                  >
                    <Emoji emoji={suggestions[2]} size={18} />
                    <span>{suggestions[2].text}</span>
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="link"
                    className="suggestionBtn"
                    onClick={() => this.applySuggestion(3)}
                  >
                    <Emoji emoji={suggestions[3]} size={18} />
                    <span>{suggestions[3].text}</span>
                  </Button>
                </Col>
              </Row>
            </Row>
          )}
          <div>
            <FormItem name="status_busy" rules={[]} valuePropName="checked">
              <Checkbox defaultChecked={this.evalBusy()}>Busy</Checkbox>
            </FormItem>
          </div>
          <Row>
            <Paragraph type="secondary">
              When others mention you, assign you, or request your review,we
              will let them know that you have limited availability.
            </Paragraph>
          </Row>
          <Divider style={{ marginTop: "0px", marginBottom: "6px" }} />
          <Row type="flex" align="middle">
            <Col span={4}>
              <div>
                <Text> Clear status</Text>
              </div>
            </Col>
            <Col span={10}>
              <div className="isoInputWrapper">
                <FormItem name="status_clearance_after">
                  <Select>
                    <Option value="never">Never</Option>
                    <Option value="after_1_week">After one week</Option>
                  </Select>
                </FormItem>
              </div>
            </Col>
          </Row>
        </StatusDialogFormWrapper>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  UserState: state.User,
});

export default connect(mapStateToProps, {})(StatusDialogForm);
