import axios, { getUserData } from "./axios";
import { authHeaders } from "./axios";

const basePath = "/accounts/v1/users";

class UsersService {
  // =======================================================
  // IDENTIFY USER:
  //  - Check if a user with the specific alias exists
  // =======================================================

  identifyUser = (username) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/${username}/identify`, {})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // INITIATE PASSWORD SIGN UP:
  //  - Check if user already exists and Password is strong
  // =======================================================

  initiateSignUp = (alias, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${basePath}/signup/initiate-password`, {
          // Required
          alias: alias,
          password: password,
        })
        .then((response) => {
          if (typeof response.data.error === "undefined") {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // INITIATE PASSWORDLESS SIGN UP:
  //  - Check if user already exists
  // =======================================================

  initiatePasswordlessSignUp = (alias) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${basePath}/signup/initiate-passwordless`, {
          // Required
          alias: alias,
        })
        .then((response) => {
          if (typeof response.data.error === "undefined") {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // PASSWORD SIGN UP:
  //  - Register a new user
  // =======================================================

  signUp = (user) => {
    // prepare profile
    let usr = {
      email: user.email,
      password: user.password,
      phone_number: user.phone ? user.phone : undefined,
      profile: {
        first_name: user.first_name,
        last_name: user.last_name,
        gender: user.gender ? user.gender : "rather_not_say",
        birthdate:
          user.birthdate === "Invalid date" ? undefined : user.birthdate,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${basePath}/signup/password`, usr, {
          headers: {
            ...getUserData(user.email),
          },
        })
        .then((response) => {
          if (typeof response.data.error === "undefined") {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // PASSWORDLESS SIGN UP:
  //  - Register a new user without password
  // =======================================================

  passwordlessSignUp = (user) => {
    // remove extra info
    if (!user.gender) user.gender = "rather_not_say";
    if (!user.phone) delete user.phone_number;
    if (user.birthdate === "Invalid date") delete user.birthdate;
    // remove prefix and password confirm
    delete user.prefix;
    delete user.phone;
    delete user.confirm;
    return new Promise((resolve, reject) => {
      axios
        .post(`${basePath}/signup/passwordless`, user, {
          headers: {
            ...getUserData(user.email),
          },
        })
        .then((response) => {
          if (typeof response.data.error === "undefined") {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // INVITE SIGN UP:
  //  - Signup/Invite a user as admin
  // =======================================================

  invite = (invite) => {
    // prepare profile
    let inv = {
      // ==
      send_invitation: invite.send_invitation,
      invite_mediums: invite.invite_mediums,
      temporary_password: invite.temporary_password,
      // ==
      email: invite.email,
      phone_number: invite.phone_number ? invite.phone_number : undefined,
      email_verified: invite.email_verified,
      phone_number_verified: invite.phone_number_verified,
      // ==
      profile: {
        first_name: invite.first_name,
        last_name: invite.last_name,
      },
      // ==
      groups: invite.groups,
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${basePath}/signup/invite`, inv, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          if (typeof response.data.error === "undefined") {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // CONFIRM USER:
  //  - Confirm a user using sent confirmation code
  // =======================================================
  confirm = (username, confirmation_code) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/${username}/confirm`,
          {
            confirmation_code: confirmation_code,
          },
          {
            headers: {
              ...getUserData(username),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // RESEND CONFIRMATION CODE:
  //  - Resend confirmation code in case of broken delivery
  // =======================================================
  resendConfirmation = (username) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/${username}/resend-confirmation`, {
          headers: {
            ...getUserData(username),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // GET CURRENT USER:
  //  - Retrieve current user data, his role and groups
  // =======================================================
  getCurrentUser = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/current`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // LIST USERS IDENTITIES:
  //  - List users identities
  // =======================================================
  listUsersIdentities = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/identities`, {
          params: params,
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // LIST USERS PROFILES:
  //  - List users profiles
  // =======================================================
  listUsersProfiles = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/profiles`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // RETRIEVE IDENTITY:
  //  - Retrieve identity
  // =======================================================
  retrieveIdentity = (username) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/identities/${username}`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // RETRIEVE USER:
  //  - Retrieve user
  // =======================================================
  retrieveUser = (username) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/${username}`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // REQUEST ACCOUNT REACTIVATION:
  //  - Request account reactivation
  // =======================================================
  requestAccountReactivation = (username) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/${username}/reactivate`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // ACCOUNT REACTIVATION:
  //  - Account reactivation
  // =======================================================
  accountReactivation = (username, token) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${basePath}/${username}/reactivate`,
          {
            token: token,
          },
          {}
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // REQUEST ACCOUNT RECOVERY:
  //  - Request account recovery
  // =======================================================
  requestCancelTermination = (username) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/${username}/recover`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // ACCOUNT RECOVERY:
  //  - Account recovery
  // =======================================================
  cancelTermination = (username, token) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${basePath}/${username}/recover`,
          {
            token: token,
          },
          {}
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // UPDATE USER ATTRIBUTES:
  //  - Update user attributes
  // =======================================================
  updateUserProfile = (attributes) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${basePath}/current`, attributes, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // UPDATE USER IDENTITIES:
  //  - Update user identities
  // =======================================================
  updateUserIdentity = (identity_name, identity_value) => {
    let attribute = {
      identity_name,
      identity_value,
    };
    return new Promise((resolve, reject) => {
      axios
        .patch(`${basePath}/current/identities`, attribute, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // VERIFY ATTRIBUTE REQUEST:
  //  - Request to verify user attribute,
  // =======================================================
  verifyAttributeRequest = (attribute_name) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/current/identities/verify`,
          {
            attribute_name: attribute_name,
          },
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // VERIFY ATTRIBUTE PROCEED:
  //  - Verify attribute
  // =======================================================
  verifyAttributeProceed = (attribute_name, verification_code) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${basePath}/current/identities/verify`,
          {
            attribute_name: attribute_name,
            verification_code: verification_code,
          },
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // UPDATE USER STATUS:
  //  - Update user status
  // =======================================================
  updateUserStatus = (
    status_text,
    status_emoji,
    status_busy,
    status_clearance_after
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${basePath}/current/status`,
          {
            status_text: status_text,
            status_emoji: status_emoji,
            status_busy: status_busy,
            status_clearance_after: status_clearance_after,
          },
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // Clear USER STATUS:
  //  - Clear user status
  // =======================================================
  clearUserStatus = () => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${basePath}/current/status`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // UPDATE USER PICTURE:
  //  - Update user picture
  // =======================================================
  updateUserPicture = (picture) => {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("picture", picture, picture.name);
      axios
        .patch(`${basePath}/current/picture`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // DELETE USER PICTURE:
  //  - Delete user picture
  // =======================================================
  clearUserPicture = () => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${basePath}/current/picture`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // DELETE USER:
  //  - Delete user
  // =======================================================
  deleteUser = (username) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${basePath}/${username}`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // SUSPEND USER ACCOUNT:
  //  - suspend user account
  // =======================================================
  suspendUserAccount = (username) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${basePath}/${username}/suspend`,
          {},
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // REINSTATE USER ACCOUNT:
  //  - Reinstate user account
  // =======================================================
  reinstateUserAccount = (username) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${basePath}/${username}/reinstate`,
          {},
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // DEACTIVATE CURRENT USER:
  //  - Deactivate current user
  // =======================================================
  deactivateCurrentUser = () => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${basePath}/current/deactivate`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // DELETE CURRENT USER:
  //  - Delete current user
  // =======================================================
  terminateCurrentUser = () => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${basePath}/current/terminate`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

const instance = new UsersService();

export default instance;
