import {all, takeEvery, put, fork} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import {message} from "antd";
import * as Actions from './actions';
import * as AuthActions from '../signin/actions';
import AuthUtils from '../signin/utils'


export function* identify() {
    yield takeEvery(Actions.IDENTIFY_SUCCESS, function* ({username, remember_me, identification}) {
        if (identification.user_exists) {
            if (!identification.enabled) {
                yield
            }
            else if (['CONFIRMED', 'EXTERNAL_PROVIDER'].includes(identification.user_status)) {
                AuthUtils.setIdentification(identification);
                if (identification.authentication_type === 'PASSWORD') {
                    yield put(
                        push('/auth/sign-in')
                    );
                }
                if (identification.authentication_type === 'PASSWORDLESS')
                    yield put(
                        AuthActions.initiatePasswordLessAuth({username: username})
                    );
                AuthUtils.setStayConnected(remember_me);
            }
        }
        else {
            message.error("Account with this username does not exist", 5);
        }
    });
}


export function* onRequestAccountReactivationSuccess() {
    yield takeEvery(Actions.REQUEST_ACCOUNT_REACTIVATION_SUCCESS, function* () {
        message.success("We've sent you a reactivation email", 5);
        yield
    });
}

export function* onAccountReactivationSuccess() {
    yield takeEvery(Actions.ACCOUNT_REACTIVATION_SUCCESS, function* () {
        message.success("Account successfully reactivated", 5);
        yield put(
            push({
                pathname: '/auth/identify',
            })
        );
    });
}

export function* onRequestCancelTerminationSuccess() {
    yield takeEvery(Actions.REQUEST_CANCEL_TERMINATION_SUCCESS, function* () {
        message.success("We've sent you a reactivation email", 5);
        yield
    });
}

export function* onCancelTerminationSuccess() {
    yield takeEvery(Actions.CANCEL_TERMINATION_SUCCESS, function* () {
        message.success("Account successfully recovered", 5);
        yield put(
            push({
                pathname: '/auth/identify',
            })
        );
    });
}


export default function* rootSaga() {
    yield all([
        fork(identify),
        fork(onRequestAccountReactivationSuccess),
        fork(onAccountReactivationSuccess),
        fork(onRequestCancelTerminationSuccess),
        fork(onCancelTerminationSuccess)
    ]);
}
