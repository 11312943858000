import { all, takeEvery, put, fork } from "redux-saga/effects";
import { notification } from "antd";
import * as Actions from "./actions";
import * as UserActions from "redux/account/profile/actions";
import { push } from "connected-react-router";

export function* onUpdateUsernameSuccess() {
  yield takeEvery(
    Actions.UPDATE_USERNAME_SUCCESS,
    function* ({ preferred_username }) {
      notification.success({
        message: "Username updated",
        description: "Your username updated successfully",
      });
      yield put(
        UserActions.updateUserIdentity("preferred_username", preferred_username)
      );
      yield put(
        push({
          pathname: "/u/identities",
        })
      );
    }
  );
}

export function* onUpdateUsernameFailure() {
  yield takeEvery(Actions.UPDATE_USERNAME_FAILURE, function* () {
    notification.error({
      message: "Username not updated",
      description: "Username already exists",
    });
    yield;
  });
}

export default function* rootSaga() {
  yield all([fork(onUpdateUsernameSuccess), fork(onUpdateUsernameFailure)]);
}
