import React from "react";

const rtl = document.getElementsByTagName("html")[0].getAttribute("dir");

const withDirection = (WrappedComponent) =>
  class extends React.PureComponent {
    render() {
      return <WrappedComponent {...this.props} data-rtl={rtl} />;
    }
  };
export default withDirection;
export { rtl };

//
// import React, { forwardRef } from 'react'
//
// const rtl = document.getElementsByTagName('html')[0].getAttribute('dir');
// const withDirection = forwardRef((props, ref) => {
//     return <div {...props} data-rtl={rtl} />;
// });
//
// export default withDirection;
// export { rtl };
