import axios from "./axios";
import { authHeaders } from "./axios";

const basePath = "/accounts/v1/groups";

class GroupsService {
  // =======================================================
  // LIST GROUPS:
  //  - List groups
  // =======================================================
  listGroups = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // CREATE GROUP:
  //  - Create a new users group
  // =======================================================

  createGroup = (group) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${basePath}`, group, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // DELETE GROUP:
  //  - Delete a specific group
  // =======================================================

  deleteGroup = (group_name) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${basePath}/${group_name}`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // RETRIEVE GROUP:
  //  - Retrieve a specific group
  // =======================================================

  retrieveGroup = (group_name) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/${group_name}`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // UPDATE GROUP:
  //  - Update a specific group
  // =======================================================

  updateGroup = (group) => {
    return new Promise((resolve, reject) => {
      let group_name = group.group_name;
      delete group.group_name;
      group.precedence = parseInt(group.precedence);
      axios
        .patch(`${basePath}/${group_name}`, group, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

const instance = new GroupsService();

export default instance;
