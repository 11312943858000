import { all, takeEvery, put, fork } from "redux-saga/effects";
import { notification } from "antd";
import * as Actions from "./actions";
import * as UserActions from "redux/account/profile/actions";
import { push } from "connected-react-router";

// SUCCESS EFFECTS

export function* onUpdatePhoneSuccess() {
  yield takeEvery(Actions.UPDATE_PHONE_SUCCESS, function* () {
    notification.success({
      message: "Phone number pending verification",
      description: "Your phone number will be updated after the verification",
    });
  });
}

export function* onVerifyPhoneRequestSuccess() {
  yield takeEvery(Actions.VERIFY_PHONE_RQST_SUCCESS, function* () {
    notification.success({
      message: "Verification sms sent",
      description: "We have sent you a verification code",
    });
    yield;
  });
}

export function* onVerifyPhoneSuccess() {
  yield takeEvery(
    Actions.VERIFY_PHONE_PROCEED_SUCCESS,
    function* ({ enable_mfa, new_phone_number }) {
      notification.success({
        message: "Phone verified",
        description: "Your phone number has been verified",
      });
      yield put(
        UserActions.updateUserIdentity("phone_number", new_phone_number)
      );
      if (enable_mfa) {
        yield put(
          push({
            pathname: "/u/security/mfa/sms",
          })
        );
      }
    }
  );
}

// FAILURE EFFECTS

export function* onUpdatePhoneFailure() {
  yield takeEvery(Actions.UPDATE_PHONE_FAILURE, function* ({ phone_number }) {
    notification.success({
      message: "Phone not updated",
      description: "Phone already taken",
    });
    yield;
  });
}

export function* onVerifyPhoneRequestFailure() {
  yield takeEvery(Actions.VERIFY_PHONE_RQST_FAILURE, function* () {
    notification.success({
      message: "Verification sms sent",
      description: "We have sent you a verification code",
    });
    yield;
  });
}

export function* onVerifyPhoneFailure() {
  yield takeEvery(Actions.VERIFY_PHONE_PROCEED_FAILURE, function* () {
    notification.success({
      message: "Phone not verified",
      description:
        "Phone already used by another account or incorrect verification code",
    });
    yield;
  });
}

export default function* rootSaga() {
  yield all([
    fork(onUpdatePhoneSuccess),
    fork(onVerifyPhoneSuccess),
    fork(onVerifyPhoneRequestSuccess),
    fork(onUpdatePhoneFailure),
    fork(onVerifyPhoneRequestFailure),
    fork(onVerifyPhoneFailure),
  ]);
}
