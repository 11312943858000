import * as Actions from './actions';

const initialState = {
    success: false,
    reactivation_instructions_sent: false,
    username: null,
    identification: {
        user_exists: undefined,
        user_status: undefined,
        enabled: undefined,
        user_status_details: {
            deactivation: undefined,
            termination: undefined
        }
    },
    error  : {
        username: null
    }
};

const identify = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.IDENTIFY_SUCCESS:
        {
            return {
                ...initialState,
                success: true,
                username: action.username,
                identification: action.identification,
            };
        }
        case Actions.IDENTIFY_FAILURE:
        {
            return {
                ...initialState,
                success: false,
                error  : action.error
            };
        }
        case Actions.REQUEST_CANCEL_TERMINATION_REQUEST:
        {
            return {
                ...state,
                reactivation_instructions_sent: true
            };
        }
        case Actions.REQUEST_CANCEL_TERMINATION_SUCCESS:
        {
            return {
                ...state,
                reactivation_instructions_sent: true
            };
        }
        case Actions.REQUEST_ACCOUNT_REACTIVATION_REQUEST:
        {
            return {
                ...state,
                reactivation_instructions_sent: true
            };
        }
        case Actions.REQUEST_ACCOUNT_REACTIVATION_SUCCESS:
        {
            return {
                ...state,
                reactivation_instructions_sent: true
            };
        }
        default:
        {
            return state
        }
    }
};

export default identify;