import * as Actions from "./actions";

const initialState = {
  success: false,
  pending_verification_email: null,
  verify_email_request_sent: false,
};

const email = function (state = initialState, action) {
  switch (action.type) {
    case Actions.UPDATE_EMAIL_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.UPDATE_EMAIL_SUCCESS: {
      return {
        ...state,
        success: true,
        verify_email_request_sent: true,
        pending_verification_email: action.pending_verification_email,
      };
    }
    case Actions.UPDATE_EMAIL_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.VERIFY_EMAIL_RQST_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.VERIFY_EMAIL_RQST_SUCCESS: {
      return {
        ...state,
        success: true,
        verify_email_request_sent: true,
      };
    }
    case Actions.VERIFY_EMAIL_RQST_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.VERIFY_EMAIL_PROCEED_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.VERIFY_EMAIL_PROCEED_SUCCESS: {
      return {
        ...state,
        success: true,
        verify_email_request_sent: false,
        pending_verification_email: null,
      };
    }
    case Actions.VERIFY_EMAIL_PROCEED_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.EMAIL_UI_RESET:
      return {
        ...state,
        success: true,
        verify_phone_request_sent: false,
      };
    default: {
      return state;
    }
  }
};

export default email;
