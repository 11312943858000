import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Menu } from "antd";

import Logo from "components/utility/misc/logo";
import { getMenuItem } from "helpers/uiUtilities";
import appActions from "redux/main/app/actions";

import TopbarUser from "../user";
import TopbarWrapper from "./style";

const { Header } = Layout;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }

  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }

  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      (key) => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }

  getAncestorKeys = (key) => {
    const map = {
      sub3: ["sub2"],
    };
    return map[key] || [];
  };

  render() {
    const { app, locale } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const { role } = this.props.UserState;
    const styling = {
      position: "fixed",
      width: "100%",
      height: 50,
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoRight">
            <Logo collapsed={true} />
          </div>
          <Menu
            onClick={this.handleClick}
            className="isoDashboardMenu"
            mode="horizontal"
            openKeys={collapsed ? [] : app.openKeys}
            selectedKeys={app.current}
            onOpenChange={this.onOpenChange}
          >
            {this.props.options.map((singleOption) =>
              getMenuItem({ singleOption, role: role })
            )}
          </Menu>
          <div className="isoLeft">
            <TopbarUser locale={locale} collapsed={collapsed} />
          </div>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    UserState: state.User,
    app: state.App,
    locale: state.LanguageSwitcher.language.locale,
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(Topbar);
