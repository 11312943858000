import React, { Component } from "react";
import { connect } from "react-redux";
import { Emoji } from "emoji-mart";
import { Link } from "react-router-dom";
import { Row, Divider, Typography, Menu, Popover, Button } from "antd";
import { SmileOutlined } from "@ant-design/icons";

import IntlMessages from "components/utility/misc/intlMessages";
import StatusDialog from "containers/account/profile/status";

import * as signInActions from "redux/public/auth/signin/actions";
import appActions from "redux/main/app/actions";

import TopbarDropdownWrapper from "./style";

const default_picture = "/images/profile.jpeg";
const { localSignOut } = signInActions;
const { hideStatusModel, showStatusModel } = appActions;

const Paragraph = Typography.Paragraph;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }

  hide() {
    this.setState({ visible: false });
  }

  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  handleSignOut = (e) => {
    e.preventDefault();
    this.props.localSignOut();
  };

  hideModel = () => {
    this.props.hideStatusModel();
  };

  showModel = () => {
    this.props.showStatusModel();
    this.setState({
      visible: false,
    });
  };

  onSelect = () => {
    this.setState({ visible: false });
  };

  render() {
    const user = this.props.UserState.data;
    const { role } = this.props.UserState;
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <Row style={{ padding: "0px 15px" }}>Signed in as</Row>
        <Row style={{ padding: "4px 15px" }}>
          <Paragraph strong ellipsis style={{ marginBottom: "0em" }}>
            {user.profile.first_name} {user.profile.last_name}
          </Paragraph>
        </Row>
        <Divider style={{ margin: "1px 0px" }} />
        <Row style={{ padding: "5px 5px 7px 5px" }}>
          {user.profile["status_text"] && user.profile["status_emoji"] ? (
            <Button
              type="secondary"
              style={{ width: "100%" }}
              onClick={this.showModel}
              className="statusBtn"
              size="small"
            >
              <Paragraph ellipsis style={{ marginBottom: "0em" }}>
                <Emoji emoji={user.profile["status_emoji"]} size={18} />{" "}
                {user.profile["status_text"]}
              </Paragraph>
            </Button>
          ) : (
            <Button
              type="secondary"
              size="small"
              icon={<SmileOutlined />}
              style={{ width: "100%" }}
              onClick={this.showModel}
            >
              Set status
            </Button>
          )}
        </Row>

        <Divider style={{ margin: "1px 0px" }} />

        <Menu onClick={this.onSelect} mode="inline">
          <Menu.Item className="isoDropdownItem">
            <Link to="/u/profile">
              <IntlMessages id="topbar.user.profile" />
            </Link>
          </Menu.Item>

          <Menu.Item className="isoDropdownItem">
            <Link to="/u/security">
              <IntlMessages id="topbar.user.security" />
            </Link>
          </Menu.Item>

          {role === "admin" && (
            <Menu.Item className="isoDropdownItem">
              <Link to="/a">
                <IntlMessages id="topbar.user.administration" />
              </Link>
            </Menu.Item>
          )}
        </Menu>

        <Divider style={{ margin: "1px 0px" }} />

        <Menu onClick={this.handleClick} mode="inline">
          <Menu.Item className="isoDropdownItem">
            <Link to="">
              <IntlMessages id="topbar.user.feedback" />
            </Link>
          </Menu.Item>

          <Menu.Item className="isoDropdownItem">
            <Link to="">
              <IntlMessages id="topbar.user.help" />
            </Link>
          </Menu.Item>

          <Menu.Item className="isoDropdownItem">
            <Link to="" onClick={this.handleSignOut}>
              <IntlMessages id="topbar.user.sign_out" />
            </Link>
          </Menu.Item>
        </Menu>
      </TopbarDropdownWrapper>
    );

    return (
      <div className="isoUser">
        <Popover
          content={content}
          trigger="click"
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange}
          arrowPointAtCenter={true}
          placement="bottomLeft"
          className="userPopUp"
        >
          <Row type="flex" justify="center">
            <div className="isoImgWrapper">
              <img
                alt="user"
                src={
                  user.profile.picture ? user.profile.picture : default_picture
                }
              />
              <span className="userActivity online" />
            </div>
          </Row>
        </Popover>

        {this.props.appState.statusModelVisible && (
          <StatusDialog
            visible={this.props.appState.statusModelVisible}
            showModel={this.showModel}
            hideModel={this.hideModel}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  SignInState: state.SignIn,
  UserState: state.User,
  appState: state.App,
});

export default connect(mapStateToProps, {
  localSignOut,
  hideStatusModel,
  showStatusModel,
})(TopbarUser);
