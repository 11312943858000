import React from "react";
import {
  AppstoreOutlined,
  ProfileOutlined,
  SafetyOutlined,
  UserOutlined,
} from "@ant-design/icons";

const options = [
  {
    key: "/u/home",
    label: "sidebar.home",
    leftIcon: <AppstoreOutlined />,
  },
  {
    key: "/u/profile",
    label: "sidebar.profile",
    leftIcon: <ProfileOutlined />,
  },
  {
    key: "/u/identities",
    label: "sidebar.identities",
    leftIcon: <UserOutlined />,
  },
  {
    key: "/u/security",
    label: "sidebar.security",
    leftIcon: <SafetyOutlined />,
  },
];
export default options;
