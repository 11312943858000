import passwordService from 'services/password';

export const REQUEST_RESET_PASSWORD_REQUEST = 'REQUEST_RESET_PASSWORD_REQUEST';
export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS';
export const REQUEST_RESET_PASSWORD_FAILURE = 'REQUEST_RESET_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export function requestResetPassword(username) {
    return (dispatch) => {
        dispatch({
            type: REQUEST_RESET_PASSWORD_REQUEST
        });
        return passwordService.requestResetPassword(username)
            .then((data) => {
                    return dispatch({
                        type: REQUEST_RESET_PASSWORD_SUCCESS,
                        feedback: data.feedback
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: REQUEST_RESET_PASSWORD_FAILURE,
                    error: error
                });
            });
    };
}

export function resetPassword({username, new_password, confirmation_code}) {
    return (dispatch) => {
        dispatch({
            type: RESET_PASSWORD_REQUEST
        });
        return passwordService.resetPassword(username, new_password, confirmation_code)
            .then((data) => {
                    return dispatch({
                        type: RESET_PASSWORD_SUCCESS
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: RESET_PASSWORD_FAILURE,
                    error: error
                });
            });
    };

}
