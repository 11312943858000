import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Spin, Modal } from "antd";
import WrappedStatusDialogForm from "containers/account/profile/status/form";
import * as userActions from "redux/account/profile/actions";
import createLoadingSelector from "redux/main/loading/selector";

const { updateUserStatus, clearUserStatus } = userActions;

class StatusDialog extends Component {
  render() {
    return (
      <Modal
        visible={this.props.visible}
        title="Update your status"
        onOk={this.props.hideModel}
        onCancel={this.props.hideModel}
        footer={[
          <Button
            key="update"
            type="primary"
            size="small"
            htmlType="submit"
            form="status-form"
          >
            Update status
          </Button>,
          <Button
            key="clear"
            type="secondary"
            size="small"
            onClick={this.props.clearUserStatus}
          >
            Clear status
          </Button>,
        ]}
      >
        <Spin spinning={this.props.isFetching}>
          <WrappedStatusDialogForm {...this.props} />
        </Spin>
      </Modal>
    );
  }
}

const loadingSelector = createLoadingSelector([
  "UPDATE_USER_STATUS",
  "CLEAR_USER_STATUS",
]);

const mapStateToProps = (state) => ({
  SignInState: state.SignIn,
  isFetching: loadingSelector(state),
});

export default connect(mapStateToProps, { updateUserStatus, clearUserStatus })(
  StatusDialog
);
