import theme_downy from "./theme_downy";
import clone from "clone";

const theme = clone(theme_downy);
theme.palette.primary = [
  "#fff8ee",
  "#fff2dd",
  "#ffebcc",
  "#ffe5bb",
  "#ffdeaa",
  "#ffd798",
  "#ffd187",
  "#ffca76",
  "#ffc465",
  "#FFBD54", // Base
  "#e6aa4c",
  "#cc9743",
  "#b3843b",
  "#997132",
  "#805f2a",
  "#664c22",
  "#4c3919",
  "#332611",
  "#191308",
];
//theme.palette.secondary = ['#0f0'];
export default theme;
