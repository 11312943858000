import axios, { getUserData, authHeaders } from "./axios";

const basePath = "/accounts/v1/password";

class PasswordService {
  // =======================================================
  // REQUEST RESET PASSWORD:
  //  - Request a password reset using user email or phone
  // =======================================================

  requestResetPassword = (alias) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/reset`,
          {
            alias: alias,
          },
          {
            headers: {
              ...getUserData(alias),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // RESET PASSWORD:
  //  - Reset password using received confirmation code
  // =======================================================
  resetPassword = (alias, new_password, confirmation_code) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${basePath}/reset`,
          {
            alias: alias,
            confirmation_code: confirmation_code,
            new_password: new_password,
          },
          {
            headers: {
              ...getUserData(alias),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // CHANGE PASSWORD:
  //  - Change current user password
  // =======================================================
  changePassword = (
    previous_password,
    proposed_password,
    sign_out_all_devices
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${basePath}/change`,
          {
            previous_password: previous_password,
            proposed_password: proposed_password,
            sign_out_all_devices: sign_out_all_devices,
          },
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  forceResetPassword = (username) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${basePath}/force-reset`,
          {
            username: username,
          },
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

const instance = new PasswordService();

export default instance;
