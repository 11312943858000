import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";
import { Spin } from "antd";

import UsersApp from "apps/users/App";
import AdminsApp from "apps/admins/App";
import asyncComponent from "helpers/AsyncFunc";
import createLoadingSelector from "redux/main/loading/selector";
import Gate from "containers/public/gate";

/**
 * Authorization Roles
 */
const authRoles = {
  admin: ["admin"],
  staff: ["admin", "staff"],
  user: ["admin", "staff", "user", "federated_user"],
  onlyGuest: ["guest"],
};

const homeOf = (role) => {
  switch (role) {
    case "guest":
      return (
        <Redirect
          to={{
            pathname: "/auth/identify",
          }}
        />
      );
    case "user":
      return (
        <Redirect
          to={{
            pathname: "/u/home",
          }}
        />
      );
    case "federated_user":
      return (
        <Redirect
          to={{
            pathname: "/u/home",
          }}
        />
      );
    case "admin":
      return (
        <Redirect
          to={{
            pathname: "/a",
          }}
        />
      );
    case "staff":
      return (
        <Redirect
          to={{
            pathname: "/s",
          }}
        />
      );
    default:
      return (
        <Redirect
          to={{
            pathname: "/auth/identify",
          }}
        />
      );
  }
};

const GuestRoute = ({ component: Component, role, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authRoles.onlyGuest.includes(role) ? (
        <Component {...props} />
      ) : (
        homeOf(role)
      )
    }
  />
);

const IdentifiedRoute = ({
  component: Component,
  role,
  identification,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authRoles.onlyGuest.includes(role) && identification.username ? (
        <Component {...props} />
      ) : (
        homeOf("john_doe")
      )
    }
  />
);

const UserRoute = ({ component: Component, role, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authRoles.user.includes(role) ? <Component {...props} /> : homeOf(role)
    }
  />
);

const AdminRoute = ({ component: Component, role, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authRoles.admin.includes(role) ? <Component {...props} /> : homeOf(role)
    }
  />
);

const StaffRoute = ({ component: Component, role, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authRoles.staff.includes(role) ? <Component {...props} /> : homeOf(role)
    }
  />
);

const UserNoAppRoute = ({ component: Component, role, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authRoles.user.includes(role) ? <Component {...props} /> : homeOf(role)
    }
  />
);

const Routes = ({ history, UserState, IdentifyState, isFetching }) => {
  const { role } = UserState;
  const { identification } = IdentifyState;

  return (
    <Spin spinning={isFetching} className="loaderSpinner">
      <Gate />

      {isFetching && <div style={{ height: "100%" }} />}

      {!isFetching && (
        <ConnectedRouter history={history}>
          <>
            <Switch>
              {/* Auth */}
              <GuestRoute
                exact
                path={"/"}
                component={asyncComponent(() =>
                  import("containers/public/auth/identify")
                )}
                role={role}
              />
              <GuestRoute
                exact
                path={"/auth/identify"}
                component={asyncComponent(() =>
                  import("containers/public/auth/identify")
                )}
                role={role}
              />
              <IdentifiedRoute
                exact
                path={"/auth/sign-in"}
                component={asyncComponent(() =>
                  import("containers/public/auth/signin")
                )}
                role={role}
                identification={identification}
              />
              {/* CHALLENGES */}
              <IdentifiedRoute
                exact
                path={"/challenge/mfa/type"}
                component={asyncComponent(() =>
                  import("containers/public/auth/challenge/mfa-type")
                )}
                role={role}
                identification={identification}
              />
              <IdentifiedRoute
                exact
                path={"/challenge/mfa/otp"}
                component={asyncComponent(() =>
                  import("containers/public/auth/challenge/mfa-otp")
                )}
                role={role}
                identification={identification}
              />
              <IdentifiedRoute
                exact
                path={"/challenge/mfa/sms"}
                component={asyncComponent(() =>
                  import("containers/public/auth/challenge/mfa-sms")
                )}
                role={role}
                identification={identification}
              />
              <IdentifiedRoute
                exact
                path={"/challenge/passwordless/email"}
                component={asyncComponent(() =>
                  import("containers/public/auth/challenge/passwordless/email")
                )}
                role={role}
                identification={identification}
              />
              <IdentifiedRoute
                exact
                path={"/challenge/passwordless/phone"}
                component={asyncComponent(() =>
                  import("containers/public/auth/challenge/passwordless/phone")
                )}
                role={role}
                identification={identification}
              />
              <GuestRoute
                exact
                path={"/challenge/password/change"}
                component={asyncComponent(() =>
                  import("containers/public/auth/challenge/password/change")
                )}
                role={role}
              />
              {/* Registration */}
              <GuestRoute
                exact
                path={"/registration/sign-up"}
                component={asyncComponent(() =>
                  import("containers/public/registration/signup")
                )}
                role={role}
              />
              <GuestRoute
                exact
                path={"/registration/confirm/email"}
                component={asyncComponent(() =>
                  import("containers/public/registration/confirm/email")
                )}
                role={role}
              />
              <GuestRoute
                exact
                path={"/registration/confirm/phone"}
                component={asyncComponent(() =>
                  import("containers/public/registration/confirm/phone")
                )}
                role={role}
              />
              {/* Reset Password */}
              <GuestRoute
                exact
                path={"/reset-password/with-email"}
                component={asyncComponent(() =>
                  import("containers/public/reset_password/email")
                )}
                role={role}
              />
              <GuestRoute
                exact
                path={"/reset-password/with-phone"}
                component={asyncComponent(() =>
                  import("containers/public/reset_password/phone")
                )}
                role={role}
              />
              {/* Callbacks */}
              <GuestRoute
                exact
                path={"/cb/confirm-signup"}
                component={asyncComponent(() =>
                  import("containers/cb/confirm_signup")
                )}
                role={role}
              />
              <GuestRoute
                exact
                path={"/cb/accept-invite"}
                component={asyncComponent(() =>
                  import("containers/cb/invite_accept")
                )}
                role={role}
              />
              <GuestRoute
                exact
                path={"/cb/passwordless"}
                component={asyncComponent(() =>
                  import("containers/cb/passwordless")
                )}
                role={role}
              />
              <GuestRoute
                exact
                path={"/cb/reset-password"}
                component={asyncComponent(() =>
                  import("containers/cb/reset_password")
                )}
                role={role}
              />
              <GuestRoute
                exact
                path={"/cb/social"}
                component={asyncComponent(() => import("containers/cb/social"))}
                role={role}
              />
              <GuestRoute
                exact
                path={"/cb/update-attribute"}
                component={asyncComponent(() =>
                  import("containers/cb/update_attribute")
                )}
                role={role}
              />
              <GuestRoute
                exact
                path={"/cb/verify-attribute"}
                component={asyncComponent(() =>
                  import("containers/cb/verify_attribute")
                )}
                role={role}
              />
              <GuestRoute
                exact
                path={"/cb/account/reactivate"}
                component={asyncComponent(() =>
                  import("containers/cb/account_reactivation")
                )}
                role={role}
              />
              <GuestRoute
                exact
                path={"/cb/account/cancel-termination"}
                component={asyncComponent(() =>
                  import("containers/cb/account_recovery")
                )}
                role={role}
              />
              {/* Checkups */}
              <UserNoAppRoute
                exact
                path={"/checkups"}
                component={asyncComponent(() =>
                  import("containers/account/checkups")
                )}
                role={role}
              />
              {/* Account */}
              <UserRoute path={"/u"} component={UsersApp} role={role} />
              <AdminRoute path={"/a"} component={AdminsApp} role={role} />
              {/* Errors */}
              <StaffRoute
                exact
                path={"/500"}
                component={asyncComponent(() =>
                  import("containers/shared/500")
                )}
                role={role}
              />
              <Route
                path={"/"}
                component={asyncComponent(() =>
                  import("containers/shared/404")
                )}
                role={role}
              />
            </Switch>
          </>
        </ConnectedRouter>
      )}
    </Spin>
  );
};

const loadingSelector = createLoadingSelector(["AUTHENTICATION"]);
const mapStateToProps = (state) => ({
  UserState: state.User,
  IdentifyState: state.Identify,
  isFetching: loadingSelector(state),
});

export default connect(mapStateToProps, {})(Routes);
