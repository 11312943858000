import React from "react";
import { Link } from "react-router-dom";
import { Row } from "antd";
import {Illustration} from "./illustration";

export default () => {
  return (
    <div className="isoLogoWrapper">
      <Link to="/" style={{ width: "100%", height: "100%" }}>
        <Row
          justify={"center"}
          align={"middle"}
          style={{ width: "100%", height: "100%" }}
        >
            {process.env.REACT_APP_LOGO_URL ?
                <img
                    style={{ width: 30, height: 30 }}
                    src={process.env.REACT_APP_LOGO_URL}
                />
                :
                <Illustration
                    style={{ width: 30, height: 30 }}
                    illustration="logo_placeholder"
                />
            }
        </Row>
      </Link>
    </div>
  );
};
