import { all, takeEvery, put, fork } from "redux-saga/effects";
import { notification } from "antd";
import * as Actions from "./actions";
import * as UserActions from "../../../../account/profile/actions";

// SUCCESS EFFECTS

export function* onUpdateMFAPreferenceSuccess() {
  yield takeEvery(
    Actions.UPDATE_MFA_PREFERENCE_SUCCESS,
    function* ({ preference }) {
      notification.success({
        message: "MFA Preference change",
        description:
          "Your mfa preference changed, changes will take effect in next sign in.",
      });
      if (preference.sms_mfa_settings)
        yield put(
          UserActions.updateUserData(
            "sms_mfa_settings",
            preference.sms_mfa_settings
          )
        );
      if (preference.software_token_mfa_settings)
        yield put(
          UserActions.updateUserData(
            "software_token_mfa_settings",
            preference.software_token_mfa_settings
          )
        );
    }
  );
}

export function* onEnableTOTPMFASuccess() {
  yield takeEvery(Actions.ENABLE_TOTP_MFA_SUCCESS, function* ({ settings }) {
    notification.success({
      message: "MFA Preference change",
      description:
        "Your mfa preference changed, changes will take effect in next sign in.",
    });
    yield put(
      UserActions.updateUserData("software_token_mfa_settings", settings)
    );
  });
}

export function* onDisableTOTPMFASuccess() {
  yield takeEvery(Actions.DISABLE_TOTP_MFA_SUCCESS, function* ({ settings }) {
    notification.success({
      message: "MFA Preference change",
      description:
        "Your mfa preference changed, changes will take effect in next sign in.",
    });
    yield put(
      UserActions.updateUserData("software_token_mfa_settings", settings)
    );
  });
}

export function* onPreferTOTPMFASuccess() {
  yield takeEvery(Actions.PREFER_TOTP_MFA_SUCCESS, function* ({ settings }) {
    yield put(
      UserActions.updateUserData("software_token_mfa_settings", settings)
    );
  });
}

export function* onDispreferTOTPMFASuccess() {
  yield takeEvery(Actions.DISPREFER_TOTP_MFA_SUCCESS, function* ({ settings }) {
    yield put(
      UserActions.updateUserData("software_token_mfa_settings", settings)
    );
  });
}

export function* onEnableSMSMFASuccess() {
  yield takeEvery(Actions.ENABLE_SMS_MFA_SUCCESS, function* ({ settings }) {
    notification.success({
      message: "MFA Preference change",
      description:
        "Your mfa preference changed, changes will take effect in next sign in.",
    });
    yield put(UserActions.updateUserData("sms_mfa_settings", settings));
  });
}

export function* onDisableSMSMFASuccess() {
  yield takeEvery(Actions.DISABLE_SMS_MFA_SUCCESS, function* ({ settings }) {
    notification.success({
      message: "MFA Preference change",
      description:
        "Your mfa preference changed, changes will take effect in next sign in.",
    });
    yield put(UserActions.updateUserData("sms_mfa_settings", settings));
  });
}

export function* onPreferSMSMFASuccess() {
  yield takeEvery(Actions.PREFER_SMS_MFA_SUCCESS, function* ({ settings }) {
    yield put(UserActions.updateUserData("sms_mfa_settings", settings));
  });
}

export function* onDispreferSMSMFASuccess() {
  yield takeEvery(Actions.DISPREFER_SMS_MFA_SUCCESS, function* ({ settings }) {
    yield put(UserActions.updateUserData("sms_mfa_settings", settings));
  });
}

// FAILURE EFFECTS

export function* onUpdateMFAPreferenceFailure() {
  yield takeEvery(Actions.UPDATE_MFA_PREFERENCE_FAILURE, function* () {
    notification.success({
      message: "Failed to change mfa preference",
      description: "Failed to change MFA Preference, please try again.",
    });
    yield;
  });
}

export default function* rootSaga() {
  yield all([
    fork(onUpdateMFAPreferenceSuccess),
    fork(onEnableTOTPMFASuccess),
    fork(onDisableTOTPMFASuccess),
    fork(onPreferTOTPMFASuccess),
    fork(onDispreferTOTPMFASuccess),
    fork(onEnableSMSMFASuccess),
    fork(onDisableSMSMFASuccess),
    fork(onPreferSMSMFASuccess),
    fork(onDispreferSMSMFASuccess),
    fork(onUpdateMFAPreferenceFailure),
  ]);
}
