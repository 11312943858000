import authService from 'services/auth';

export const RESPOND_NEW_PASSWORD_REQUIRED_CHALLENGE_REQUEST = 'RESPOND_NEW_PASSWORD_REQUIRED_CHALLENGE_REQUEST';
export const RESPOND_NEW_PASSWORD_REQUIRED_CHALLENGE_SUCCESS = 'RESPOND_NEW_PASSWORD_REQUIRED_CHALLENGE_SUCCESS';
export const RESPOND_NEW_PASSWORD_REQUIRED_CHALLENGE_FAILURE = 'RESPOND_NEW_PASSWORD_REQUIRED_CHALLENGE_FAILURE';

export function respondNewPasswordRequired({username, new_password}) {
    return (dispatch) => {
        dispatch({
            type: RESPOND_NEW_PASSWORD_REQUIRED_CHALLENGE_REQUEST
        });
        authService.respondNewPasswordRequired(username, new_password)
            .then((data) => {
                    return dispatch({
                        type: RESPOND_NEW_PASSWORD_REQUIRED_CHALLENGE_SUCCESS,
                        auth: data
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: RESPOND_NEW_PASSWORD_REQUIRED_CHALLENGE_FAILURE,
                    error: error
                });
            });
    }
}
