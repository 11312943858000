import { all, takeEvery, put, fork } from "redux-saga/effects";
import { notification } from "antd";
import { push } from "connected-react-router";
import * as Actions from "./actions";
import * as MFAPreferenceActions from "../preference/actions";

// SUCCESS EFFECTS

export function* onAssociateOTPSuccess() {
  yield takeEvery(Actions.ASSOCIATE_OTP_SUCCESS, function* () {
    notification.success({
      message: "Associate OTP",
      description: "Please associate OTP and provide time token",
    });
    yield;
  });
}

export function* onVerifyOTPSuccess() {
  yield takeEvery(Actions.VERIFY_OTP_SUCCESS, function* () {
    notification.success({
      message: "Associated",
      description: "Software token associated",
    });
    yield put(MFAPreferenceActions.enableSoftwareTokenMFA());
    yield put(push("/u/security"));
  });
}

// FAILURE EFFECTS

export function* onAssociateOTPFailure() {
  yield takeEvery(Actions.ASSOCIATE_OTP_FAILURE, function* () {
    notification.success({
      message: "Failed to associate",
      description: "Failed to associate otp device",
    });
    yield;
  });
}

export function* onVerifyOTPFailure() {
  yield takeEvery(Actions.VERIFY_OTP_FAILURE, function* () {
    notification.success({
      message: "Failed to verify",
      description: "You entered a wrong otp code",
    });
    yield;
  });
}

export default function* rootSaga() {
  yield all([
    fork(onAssociateOTPSuccess),
    fork(onVerifyOTPSuccess),
    fork(onAssociateOTPFailure),
    fork(onVerifyOTPFailure),
  ]);
}
