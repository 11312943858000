import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as Actions from "./actions";
import { push } from "connected-react-router";

export function* onListUsersSuccess() {
  yield takeEvery(
    Actions.LIST_USERS_IDENTITIES_SUCCESS,
    function* ({ identities }) {
      yield;
    }
  );
}

export function* onGetUserDetailsSuccess() {
  yield takeEvery(Actions.GET_USER_DETAILS_SUCCESS, function* ({ user }) {
    yield;
  });
}

export function* onDeleteUserSuccess() {
  yield takeEvery(Actions.DELETE_USER_SUCCESS, function* () {
    yield put(
      push({
        pathname: "/a/identities",
      })
    );
  });
}

export function* onInviteUserSuccess() {
  yield takeEvery(Actions.INVITE_USER_SUCCESS, function* () {
    yield put(
      push({
        pathname: "/a/identities",
      })
    );
  });
}

export default function* rootSaga() {
  yield all([
    fork(onListUsersSuccess),
    fork(onGetUserDetailsSuccess),
    fork(onDeleteUserSuccess),
    fork(onInviteUserSuccess),
  ]);
}
