import * as Actions from './actions';

const initialState = {
    success: false
};

const respondSMSMFA = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.RESPOND_SMS_CHALLENGE_SUCCESS:
        {
            return {
                ...initialState,
                success: true
            };
        }
        case Actions.RESPOND_SMS_CHALLENGE_FAILURE:
        {
            return {
                success: false
            };
        }
        default:
        {
            return state
        }
    }
};

export default respondSMSMFA;
