import React, {Component} from 'react';
import {connect} from "react-redux";
import {Typography, Modal, message} from 'antd'
import createLoadingSelector from 'redux/main/loading/selector';
import * as ResetPasswordActions from "redux/public/reset_password/actions";
import * as confirmationActions from 'redux/public/registration/confirm/actions';
import * as Actions from "redux/public/auth/identify/actions";

const {requestConfirmation} = confirmationActions;
const {requestResetPassword} = ResetPasswordActions;
const {requestAccountReactivation, requestCancelTermination} = Actions;

const confirm = Modal.confirm;
const {Text, Paragraph} = Typography;

class Gate extends Component {
    state = {};


    componentDidUpdate = () => {
        this.gate();
    };

    handleAccountDeactivation = () => {
        let {user_status_details, username} = this.props.IdentifyState.identification;
        if (user_status_details){
            if (user_status_details.deactivation) {
                let deactivation = user_status_details.deactivation;
                if (deactivation.requester === 'USER')
                    confirm({
                        title: "Account Deactivated",
                        content: <>
                            <Paragraph>Account was deactivated, will be reactivated automatically in <Text
                                strong>{deactivation.reactivation_time}</Text></Paragraph>
                            <Paragraph>In case you want to reactivate this account we can send you reactivation instructions to
                                your email ?</Paragraph>
                        </>,
                        onOk: () => {
                            return this.props.requestAccountReactivation(username);
                        },
                        onCancel() {},
                        okText: "Yes",
                        cancelText: "Cancel"
                    });
                else if (deactivation.requester === 'ADMIN') {
                    confirm({
                        title: "Account Suspended",
                        content: <>
                            <Paragraph>Your account was suspended at <Text strong>{deactivation.suspension_time}</Text></Paragraph>
                            <Paragraph>Please reach out to support for further information!</Paragraph>
                        </>,
                        okText: "Ok",
                        cancelText: null
                    });
                }
            }
            else if (user_status_details.termination) {
                let termination = user_status_details.termination;
                if (termination.requester === 'USER')
                    confirm({
                        title: "Account Pending Termination",
                        content: <>
                            <Paragraph>Account was deactivated, and will be terminated automatically in <Text
                                strong>{termination.termination_time}</Text></Paragraph>
                            <Paragraph>In case you want to reactivate this account we can send you reactivation instructions to
                                your email ?</Paragraph>
                        </>,
                        onOk: () => {
                            return this.props.requestCancelTermination(username);
                        },
                        onCancel() {
                        },
                        okText: "Yes",
                        cancelText: "Cancel"
                    });
            }
        }
    };

    disabledEnabledGate = () => {
        Modal.error({
            title: "Account Suspended",
            content:
                "Your account was suspended, please refer to support",
            onOk: () => {

            },
            onCancel() {
            },
            okText: "OK",
        });
    };

    confirmedUnconfirmedGate = () => {
        Modal.confirm({
            title: "Account Unconfirmed",
            content:
                "Your account is unconfirmed, you need to confirm your identity in order to use it",
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.props.requestConfirmation(this.props.IdentifyState.identification.username).then(() => {
                        resolve()
                    })
                }).catch((err) => {
                    console.log(err);
                });
            },
            onCancel() {
            },
            okText: "CONFIRM",
        });
    };

    resetPasswordRequiredGate = () => {
        Modal.warning({
            title: "Reset Password Required",
            content:
                "We have noticed a strange activity on your account, to protect you account you need to reset you password",
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.props.requestResetPassword(this.props.IdentifyState.identification.username).then(() => {
                        resolve()
                    })
                }).catch((err) => {
                    console.log(err);
                });
            },
            onCancel() {
            },
            okText: "RESET PASSWORD",
        });
    };

    handleAccountSuspension = (deactivation) => {

    }


    gate = () => {
        /* DEACTIVATION/REACTIVATION GATE */
        if (!this.props.IdentifyState.identification.enabled && !this.props.IdentifyState.reactivation_instructions_sent) {
            this.handleAccountDeactivation();
        }
        /* CONFIRMED/UNCONFIRMED GATE */
        else if (this.props.IdentifyState.identification.user_status === 'UNCONFIRMED' && !this.props.ConfirmSignUpState.success) {
            this.confirmedUnconfirmedGate();
        }
        /* RESET PASSWORD REQUIRED GATE */
        else if (this.props.IdentifyState.identification.user_status === 'RESET_REQUIRED' && !this.props.ResetPasswordState.success) {
            this.resetPasswordRequiredGate();
        }
    };

    render() {
        return (
            <>
            </>
        );
    }
}

const loadingSelector = createLoadingSelector(['REQUEST_ACCOUNT_REACTIVATION', 'REQUEST_CANCEL_TERMINATION']);

const mapStateToProps = (state) => (
    {
        IdentifyState: state.Identify,
        ResetPasswordState: state.ResetPassword,
        ConfirmSignUpState: state.ConfirmSignUp,
        isLoading: loadingSelector(state),
    }
);

export default connect(
    mapStateToProps,
    {
        requestResetPassword, requestConfirmation, requestAccountReactivation, requestCancelTermination
    }
)(Gate);
