import mfaService from "services/mfa";

export const UPDATE_MFA_PREFERENCE_REQUEST = "UPDATE_MFA_PREFERENCE_REQUEST";
export const UPDATE_MFA_PREFERENCE_FAILURE = "UPDATE_MFA_PREFERENCE_FAILURE";
export const UPDATE_MFA_PREFERENCE_SUCCESS = "UPDATE_MFA_PREFERENCE_SUCCESS";

export const ENABLE_TOTP_MFA_REQUEST = "ENABLE_TOTP_MFA_REQUEST";
export const ENABLE_TOTP_MFA_FAILURE = "ENABLE_TOTP_MFA_FAILURE";
export const ENABLE_TOTP_MFA_SUCCESS = "ENABLE_TOTP_MFA_SUCCESS";

export const DISABLE_TOTP_MFA_REQUEST = "DISABLE_TOTP_MFA_REQUEST";
export const DISABLE_TOTP_MFA_FAILURE = "DISABLE_TOTP_MFA_FAILURE";
export const DISABLE_TOTP_MFA_SUCCESS = "DISABLE_TOTP_MFA_SUCCESS";

export const PREFER_TOTP_MFA_REQUEST = "PREFER_TOTP_MFA_REQUEST";
export const PREFER_TOTP_MFA_FAILURE = "PREFER_TOTP_MFA_FAILURE";
export const PREFER_TOTP_MFA_SUCCESS = "PREFER_TOTP_MFA_SUCCESS";

export const DISPREFER_TOTP_MFA_REQUEST = "DISPREFER_TOTP_MFA_REQUEST";
export const DISPREFER_TOTP_MFA_FAILURE = "DISPREFER_TOTP_MFA_FAILURE";
export const DISPREFER_TOTP_MFA_SUCCESS = "DISPREFER_TOTP_MFA_SUCCESS";

export const ENABLE_SMS_MFA_REQUEST = "ENABLE_SMS_MFA_REQUEST";
export const ENABLE_SMS_MFA_FAILURE = "ENABLE_SMS_MFA_FAILURE";
export const ENABLE_SMS_MFA_SUCCESS = "ENABLE_SMS_MFA_SUCCESS";

export const DISABLE_SMS_MFA_REQUEST = "DISABLE_SMS_MFA_REQUEST";
export const DISABLE_SMS_MFA_FAILURE = "DISABLE_SMS_MFA_FAILURE";
export const DISABLE_SMS_MFA_SUCCESS = "DISABLE_SMS_MFA_SUCCESS";

export const PREFER_SMS_MFA_REQUEST = "PREFER_SMS_MFA_REQUEST";
export const PREFER_SMS_MFA_FAILURE = "PREFER_SMS_MFA_FAILURE";
export const PREFER_SMS_MFA_SUCCESS = "PREFER_SMS_MFA_SUCCESS";

export const DISPREFER_SMS_MFA_REQUEST = "DISPREFER_SMS_MFA_REQUEST";
export const DISPREFER_SMS_MFA_FAILURE = "DISPREFER_SMS_MFA_FAILURE";
export const DISPREFER_SMS_MFA_SUCCESS = "DISPREFER_SMS_MFA_SUCCESS";

export function updateMFAPreference(preference) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MFA_PREFERENCE_REQUEST,
    });
    mfaService
      .updateMFAPreference(preference)
      .then((data) => {
        return dispatch({
          type: UPDATE_MFA_PREFERENCE_SUCCESS,
          preference: preference,
        });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_MFA_PREFERENCE_FAILURE,
          error: error,
        });
      });
  };
}

export function enableSoftwareTokenMFA() {
  return (dispatch) => {
    dispatch({
      type: ENABLE_TOTP_MFA_REQUEST,
    });
    mfaService
      .enableSoftwareTokenMFA()
      .then((data) => {
        return dispatch({
          type: ENABLE_TOTP_MFA_SUCCESS,
          settings: data.software_token_mfa_settings,
        });
      })
      .catch((error) => {
        return dispatch({
          type: ENABLE_TOTP_MFA_FAILURE,
          error: error,
        });
      });
  };
}

export function disableSoftwareTokenMFA() {
  return (dispatch) => {
    dispatch({
      type: DISABLE_TOTP_MFA_REQUEST,
    });
    mfaService
      .disableSoftwareTokenMFA()
      .then((data) => {
        return dispatch({
          type: DISABLE_TOTP_MFA_SUCCESS,
          settings: data.software_token_mfa_settings,
        });
      })
      .catch((error) => {
        return dispatch({
          type: DISABLE_TOTP_MFA_FAILURE,
          error: error,
        });
      });
  };
}

export function preferSoftwareTokenMFA() {
  return (dispatch) => {
    dispatch({
      type: PREFER_TOTP_MFA_REQUEST,
    });
    mfaService
      .preferSoftwareTokenMFA()
      .then((data) => {
        return dispatch({
          type: PREFER_TOTP_MFA_SUCCESS,
          settings: data.software_token_mfa_settings,
        });
      })
      .catch((error) => {
        return dispatch({
          type: PREFER_TOTP_MFA_FAILURE,
          error: error,
        });
      });
  };
}

export function dispreferSoftwareTokenMFA() {
  return (dispatch) => {
    dispatch({
      type: DISPREFER_TOTP_MFA_REQUEST,
    });
    mfaService
      .dispreferSoftwareTokenMFA()
      .then((data) => {
        return dispatch({
          type: DISPREFER_TOTP_MFA_SUCCESS,
          settings: data.software_token_mfa_settings,
        });
      })
      .catch((error) => {
        return dispatch({
          type: DISPREFER_TOTP_MFA_FAILURE,
          error: error,
        });
      });
  };
}

export function enableSMSMFA() {
  return (dispatch) => {
    dispatch({
      type: ENABLE_SMS_MFA_REQUEST,
    });
    mfaService
      .enableSMSMFA()
      .then((data) => {
        return dispatch({
          type: ENABLE_SMS_MFA_SUCCESS,
          settings: data.sms_mfa_settings,
        });
      })
      .catch((error) => {
        return dispatch({
          type: ENABLE_SMS_MFA_FAILURE,
          error: error,
        });
      });
  };
}

export function disableSMSMFA() {
  return (dispatch) => {
    dispatch({
      type: DISABLE_SMS_MFA_REQUEST,
    });
    mfaService
      .disableSMSMFA()
      .then((data) => {
        return dispatch({
          type: DISABLE_SMS_MFA_SUCCESS,
          settings: data.sms_mfa_settings,
        });
      })
      .catch((error) => {
        return dispatch({
          type: DISABLE_SMS_MFA_FAILURE,
          error: error,
        });
      });
  };
}

export function preferSMSMFA() {
  return (dispatch) => {
    dispatch({
      type: PREFER_SMS_MFA_REQUEST,
    });
    mfaService
      .preferSMSMFA()
      .then((data) => {
        return dispatch({
          type: PREFER_SMS_MFA_SUCCESS,
          settings: data.sms_mfa_settings,
        });
      })
      .catch((error) => {
        return dispatch({
          type: PREFER_SMS_MFA_FAILURE,
          error: error,
        });
      });
  };
}

export function dispreferSMSMFA() {
  return (dispatch) => {
    dispatch({
      type: DISPREFER_SMS_MFA_REQUEST,
    });
    mfaService
      .dispreferSMSMFA()
      .then((data) => {
        return dispatch({
          type: DISPREFER_SMS_MFA_SUCCESS,
          settings: data.sms_mfa_settings,
        });
      })
      .catch((error) => {
        return dispatch({
          type: DISPREFER_SMS_MFA_FAILURE,
          error: error,
        });
      });
  };
}
