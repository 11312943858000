import usersService from 'services/users';

export const INITIATE_SIGNUP_REQUEST = 'INITIATE_SIGNUP_REQUEST';
export const INITIATE_SIGNUP_SUCCESS = 'INITIATE_SIGNUP_SUCCESS';
export const INITIATE_SIGNUP_FAILURE = 'INITIATE_SIGNUP_FAILURE';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export function initiateSignUp(user) {
    return (dispatch) =>
    {
        dispatch({
            type: INITIATE_SIGNUP_REQUEST
        });
        if (process.env.REACT_APP_AUTHENTICATION_TYPE === 'PASSWORD')
            return usersService.initiateSignUp(user.email, user.password)
                .then((data) => {
                        return dispatch({
                            type: INITIATE_SIGNUP_SUCCESS,
                            user: user,
                            ask_extra_info: data.feedback.ask_extra_info
                        });
                    }
                )
                .catch(error => {
                    return dispatch({
                        type: INITIATE_SIGNUP_FAILURE,
                        error: error
                    });
                });
        else if (process.env.REACT_APP_AUTHENTICATION_TYPE === 'PASSWORDLESS')
            return usersService.initiatePasswordlessSignUp(user.email)
                .then((data) => {
                        return dispatch({
                            type: INITIATE_SIGNUP_SUCCESS,
                            user: user,
                            feedback: data.feedback
                        });
                    }
                )
                .catch(error => {
                    return dispatch({
                        type: INITIATE_SIGNUP_FAILURE,
                        error: error
                    });
                });
    };
}

export function signUp(user) {
    return (dispatch) => {
        dispatch({
            type: SIGNUP_REQUEST
        });
        if (process.env.REACT_APP_AUTHENTICATION_TYPE === 'PASSWORD')
            return usersService.signUp(user)
                .then((data) => {
                        user.username = data.feedback.username;
                        return dispatch({
                            type: SIGNUP_SUCCESS,
                            user: user,
                            feedback: data.feedback
                        });
                    }
                )
                .catch(error => {
                    return dispatch({
                        type: SIGNUP_FAILURE,
                        error: error
                    });
                });
        else if (process.env.REACT_APP_AUTHENTICATION_TYPE === 'PASSWORDLESS')
            return usersService.passwordlessSignUp(user)
                .then((data) => {
                        user.username = data.feedback.username;
                        return dispatch({
                            type: SIGNUP_SUCCESS,
                            user: user,
                            feedback: data.feedback
                        });
                    }
                )
                .catch(error => {
                    return dispatch({
                        type: SIGNUP_FAILURE,
                        error: error
                    });
                });
    };
}

