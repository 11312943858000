import usersService from 'services/users';


export const IDENTIFY_REQUEST = 'IDENTIFY_REQUEST';
export const IDENTIFY_FAILURE = 'IDENTIFY_FAILURE';
export const IDENTIFY_SUCCESS = 'IDENTIFY_SUCCESS';

export const REQUEST_ACCOUNT_REACTIVATION_REQUEST = 'REQUEST_ACCOUNT_REACTIVATION_REQUEST';
export const REQUEST_ACCOUNT_REACTIVATION_FAILURE = 'REQUEST_ACCOUNT_REACTIVATION_FAILURE';
export const REQUEST_ACCOUNT_REACTIVATION_SUCCESS = 'REQUEST_ACCOUNT_REACTIVATION_SUCCESS';

export const ACCOUNT_REACTIVATION_REQUEST = 'ACCOUNT_REACTIVATION_REQUEST';
export const ACCOUNT_REACTIVATION_FAILURE = 'ACCOUNT_REACTIVATION_FAILURE';
export const ACCOUNT_REACTIVATION_SUCCESS = 'ACCOUNT_REACTIVATION_SUCCESS';

export const REQUEST_CANCEL_TERMINATION_REQUEST = 'REQUEST_CANCEL_TERMINATION_REQUEST';
export const REQUEST_CANCEL_TERMINATION_FAILURE = 'REQUEST_CANCEL_TERMINATION_FAILURE';
export const REQUEST_CANCEL_TERMINATION_SUCCESS = 'REQUEST_CANCEL_TERMINATION_SUCCESS';

export const CANCEL_TERMINATION_REQUEST = 'CANCEL_TERMINATION_REQUEST';
export const CANCEL_TERMINATION_FAILURE = 'CANCEL_TERMINATION_FAILURE';
export const CANCEL_TERMINATION_SUCCESS = 'CANCEL_TERMINATION_SUCCESS';

export function identifyUser({username, remember_me}) {
    return (dispatch) => {
        dispatch({
            type: IDENTIFY_REQUEST
        });
        return usersService.identifyUser(username)
            .then((identification) => {
                    return dispatch({
                        type: IDENTIFY_SUCCESS,
                        username: username,
                        remember_me: remember_me,
                        identification: identification
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: IDENTIFY_FAILURE,
                    error: error
                });
            });
    };
}

export function requestAccountReactivation(username) {
    return (dispatch) => {
        dispatch({
            type: REQUEST_ACCOUNT_REACTIVATION_REQUEST
        });
        return usersService.requestAccountReactivation(username)
            .then((data) => {
                    return dispatch({
                        type: REQUEST_ACCOUNT_REACTIVATION_SUCCESS,
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: REQUEST_ACCOUNT_REACTIVATION_FAILURE,
                    error: error
                });
            });
    };
}

export function accountReactivation(username, token) {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_REACTIVATION_REQUEST
        });
        return usersService.accountReactivation(username, token)
            .then((data) => {
                    return dispatch({
                        type: ACCOUNT_REACTIVATION_SUCCESS,
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: ACCOUNT_REACTIVATION_FAILURE,
                    error: error
                });
            });
    };
}


export function requestCancelTermination(username) {
    return (dispatch) => {
        dispatch({
            type: REQUEST_CANCEL_TERMINATION_REQUEST
        });
        return usersService.requestCancelTermination(username)
            .then((data) => {
                    return dispatch({
                        type: REQUEST_CANCEL_TERMINATION_SUCCESS,
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: REQUEST_CANCEL_TERMINATION_FAILURE,
                    error: error
                });
            });
    };
}

export function cancelTermination(username, token) {
    return (dispatch) => {
        dispatch({
            type: CANCEL_TERMINATION_REQUEST
        });
        return usersService.cancelTermination(username, token)
            .then((data) => {
                    return dispatch({
                        type: CANCEL_TERMINATION_SUCCESS,
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: CANCEL_TERMINATION_FAILURE,
                    error: error
                });
            });
    };
}
