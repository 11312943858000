import React from "react";

const pic = `/images/flags/`;
const st = { height: "20px" };

const locales = [
  {
    label: (
      <span>
        <img style={st} src={pic + "uk.svg"} alt="uk" /> English
      </span>
    ),
    value: "uk",
  },
  {
    label: (
      <span>
        <img style={st} src={pic + "es.svg"} alt="es" /> Spanish
      </span>
    ),
    value: "es",
  },
  {
    label: (
      <span>
        <img style={st} src={pic + "fr.svg"} alt="fr" /> French
      </span>
    ),
    value: "fr",
  },
  {
    label: (
      <span>
        <img style={st} src={pic + "ch.svg"} alt="ch" /> Chinese
      </span>
    ),
    value: "ch",
  },
  {
    label: (
      <span>
        <img style={st} src={pic + "de.svg"} alt="de" /> German
      </span>
    ),
    value: "de",
  },
  {
    label: (
      <span>
        <img style={st} src={pic + "ru.svg"} alt="ru" /> Russian
      </span>
    ),
    value: "ru",
  },
  {
    label: (
      <span>
        <img style={st} src={pic + "ar.svg"} alt="ar" /> Arabic
      </span>
    ),
    value: "ar",
  },
];

export default locales;
