import axios from "./axios";
import { authHeaders } from "./axios";

const basePath = "/accounts/v1/devices";

class DevicesService {
  // =======================================================
  // LIST DEVICES:
  //  - Return the list of tracked devices
  // =======================================================
  listDevices = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // LIST DEVICES:
  //  - Retrieve a specific device
  // =======================================================
  retrieveDevice = (device_key) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/${device_key}`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // CONFIRM DEVICE:
  //  - Confirm a specific device
  // =======================================================
  confirmDevice = ({
    device_key,
    device_password_verifier,
    device_salt,
    device_name,
  }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/${device_key}/confirm`,
          {
            device_password_verifier: device_password_verifier,
            device_salt: device_salt,
            device_name: device_name,
          },
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // FORGET DEVICE:
  //  - Forget a specific device
  // =======================================================
  forgetDevice = (device_key) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/${device_key}/forget`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // UPDATE DEVICE STATUS:
  //  - Update a specific device status
  // =======================================================
  updateDeviceStatus = (device_key, new_status) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${basePath}/${device_key}/update-status`,
          {
            new_status: new_status,
          },
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  deauthorizeInactiveDevices = () => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${basePath}/deauthorize-inactive`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

const instance = new DevicesService();

export default instance;
