import * as Actions from "./actions";

const initialState = {
  success: false,
  devices: [],
};

const device = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CONFIRM_DEVICE_REQUEST: {
      return {
        ...initialState,
        success: true,
      };
    }
    case Actions.CONFIRM_DEVICE_SUCCESS: {
      return {
        ...initialState,
        success: true,
      };
    }
    case Actions.CONFIRM_DEVICE_FAILURE: {
      return {
        ...initialState,
        success: true,
      };
    }
    case Actions.REMEMBER_DEVICE_REQUEST: {
      return {
        ...initialState,
        success: true,
      };
    }
    case Actions.REMEMBER_DEVICE_SUCCESS: {
      return {
        ...initialState,
        success: true,
      };
    }
    case Actions.REMEMBER_DEVICE_FAILURE: {
      return {
        ...initialState,
        success: true,
      };
    }
    case Actions.FORGET_DEVICE_REQUEST: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.FORGET_DEVICE_SUCCESS: {
      return {
        ...state,
        devices: state.devices.filter(function (d) {
          return d.key !== action.device_key;
        }),
        success: true,
      };
    }
    case Actions.FORGET_DEVICE_FAILURE: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.LIST_DEVICES_REQUEST: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.LIST_DEVICES_SUCCESS: {
      return {
        ...initialState,
        devices: action.devices,
      };
    }
    case Actions.LIST_DEVICES_FAILURE: {
      return {
        ...state,
        success: false,
        devices: [],
      };
    }
    default: {
      return state;
    }
  }
};

export default device;
