import * as Actions from "./actions";

const initialState = {
  success: false,
  pending_verification_phone_number: null,
  verify_phone_request_sent: false,
};

const phone = function (state = initialState, action) {
  switch (action.type) {
    case Actions.UPDATE_PHONE_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.UPDATE_PHONE_SUCCESS: {
      return {
        ...state,
        success: true,
        verify_phone_request_sent: true,
        pending_verification_phone_number:
          action.pending_verification_phone_number,
      };
    }
    case Actions.UPDATE_PHONE_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.VERIFY_PHONE_RQST_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.VERIFY_PHONE_RQST_SUCCESS: {
      return {
        ...state,
        success: true,
        verify_phone_request_sent: true,
      };
    }
    case Actions.VERIFY_PHONE_RQST_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.VERIFY_PHONE_PROCEED_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.VERIFY_PHONE_PROCEED_SUCCESS: {
      return {
        ...state,
        success: true,
        pending_verification_phone_number: null,
        verify_phone_request_sent: false,
      };
    }
    case Actions.VERIFY_PHONE_PROCEED_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.PHONE_UI_RESET:
      return {
        ...state,
        success: true,
        verify_phone_request_sent: false,
      };
    default: {
      return state;
    }
  }
};

export default phone;
