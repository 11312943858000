import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as Actions from "./actions";
import AuthUtils from "redux/public/auth/signin/utils";

export function* onConfirmDeviceSuccess() {
  yield takeEvery(
    Actions.CONFIRM_DEVICE_SUCCESS,
    function* ({ new_device, remember_device }) {
      if (remember_device) {
        yield put(Actions.rememberDevice(new_device));
      }
    }
  );
}

export function* onRememberDeviceSuccess() {
  yield takeEvery(Actions.REMEMBER_DEVICE_SUCCESS, function* ({ new_device }) {
    AuthUtils.setDeviceMetadata(new_device);
    yield;
  });
}

export function* onDeauthorizeInactiveDevicesSuccess() {
  yield takeEvery(Actions.DEAUTHORIZE_INACTIVE_DEVICES_SUCCESS, function* () {
    yield put(Actions.listDevices());
  });
}

export default function* rootSaga() {
  yield all([
    fork(onConfirmDeviceSuccess),
    fork(onRememberDeviceSuccess),
    fork(onDeauthorizeInactiveDevicesSuccess),
  ]);
}
