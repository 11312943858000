import React from "react";
import {
  ApartmentOutlined,
  AppstoreOutlined,
  ControlOutlined,
  SafetyOutlined,
  TeamOutlined,
} from "@ant-design/icons";

const options = [
  {
    key: "/a",
    label: "sidebar.dashboard",
    leftIcon: <AppstoreOutlined />,
  },
  {
    key: "/a/identities",
    label: "sidebar.identities",
    leftIcon: <SafetyOutlined />,
  },
  {
    key: "/a/profiles",
    label: "sidebar.profiles",
    leftIcon: <TeamOutlined />,
  },
  {
    key: "/a/groups",
    label: "sidebar.groups",
    leftIcon: <ApartmentOutlined />,
  },
  {
    key: "/a/authorizer",
    label: "sidebar.authorizer",
    leftIcon: <ControlOutlined />,
  },
];

export default options;
