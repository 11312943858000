import {all, takeEvery, put, fork} from 'redux-saga/effects';
import * as Actions from './actions';
import {push} from 'connected-react-router';
import AuthUtils from "redux/public/auth/signin/utils";


export function* onRespondSelectMFATypeChallengeSuccess() {
    yield takeEvery(Actions.RESPOND_SELECT_MFA_TYPE_CHALLENGE_SUCCESS, function* ({auth}) {
        // CHALLENGE: Set session
        if (auth.challenge_session) {
            AuthUtils.setChallengeSession(auth.challenge_session)
        }
        // CHALLENGE: TOTP MFA
        if (auth.challenge_name === 'SOFTWARE_TOKEN_MFA') {
            yield put(
                push({
                    pathname: '/challenge/mfa/otp'
                })
            );
        }
        // CHALLENGE: SMS MFA
        if (auth.challenge_name === 'SMS_MFA') {
            yield put(
                push({
                    pathname: '/challenge/mfa/sms'
                })
            );
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(onRespondSelectMFATypeChallengeSuccess),
    ]);
}
