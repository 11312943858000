import axios from "./axios";
import { authHeaders } from "./axios";

const basePath = "/accounts/v1/mfa";

class MFAService {
  // =======================================================
  // RETRIEVE MFA PREFERENCE:
  //  - Setup MFA Preference (Preferred/Enabled methods)
  // =======================================================
  retrieveMFAPreference = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/preference`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // UPDATE MFA PREFERENCE:
  //  - Setup MFA Preference (Preferred/Enabled methods)
  // =======================================================
  updateMFAPreference = (preference) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${basePath}/preference`, preference, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // ASSOCIATE SOFTWARE TOKEN:
  //  - Associate software token, get software token
  // =======================================================

  associateSoftwareToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/associate-software-token`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // VERIFY SOFTWARE TOKEN:
  //  - Verify time-base one-time software token password
  // =======================================================
  verifySoftwareToken = (user_code, friendly_device_name) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/verify-software-token`,
          {
            user_code: user_code,
            friendly_device_name: friendly_device_name,
          },
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // Enable TOTP:
  //  - Enable software token MFA
  // =======================================================
  enableSoftwareTokenMFA = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/preference/totp/enable`,
          {},
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // Disable TOTP:
  //  - Disbale software token MFA
  // =======================================================
  disableSoftwareTokenMFA = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/preference/totp/disable`,
          {},
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  // =======================================================
  // Prefer TOTP:
  //  - Enable software token MFA
  // =======================================================
  preferSoftwareTokenMFA = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/preference/totp/prefer`,
          {},
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // Disprefer TOTP:
  //  - Disprefer software token MFA
  // =======================================================
  dispreferSoftwareTokenMFA = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/preference/totp/disprefer`,
          {},
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // Enable SMS:
  //  - Enable SMS MFA
  // =======================================================
  enableSMSMFA = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/preference/sms/enable`,
          {},
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // Disable SMS:
  //  - Disbale SMS MFA
  // =======================================================
  disableSMSMFA = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/preference/sms/disable`,
          {},
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  // =======================================================
  // Prefer SMS:
  //  - Enable SMS MFA
  // =======================================================
  preferSMSMFA = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/preference/sms/prefer`,
          {},
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // Disprefer SMS:
  //  - Disprefer SMS MFA
  // =======================================================
  dispreferSMSMFA = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/preference/sms/disprefer`,
          {},
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

const instance = new MFAService();

export default instance;
