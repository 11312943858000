export function getView(width) {
  let newView = "MobileView";
  if (width > 1220) {
    newView = "DesktopView";
  } else if (width > 767) {
    newView = "TabView";
  }
  return newView;
}

const actions = {
  COLLPSE_CHANGE: "COLLPSE_CHANGE",
  COLLPSE_OPEN_DRAWER: "COLLPSE_OPEN_DRAWER",
  CHANGE_OPEN_KEYS: "CHANGE_OPEN_KEYS",
  TOGGLE_ALL: "TOGGLE_ALL",
  CHANGE_CURRENT: "CHANGE_CURRENT",
  CLEAR_MENU: "CLEAR_MENU",
  SHOW_STATUS_MODEL: "SHOW_STATUS_MODEL",
  HIDE_STATUS_MODEL: "HIDE_STATUS_MODEL",
  SHOW_ABOUT_FORM: "SHOW_ABOUT_FORM",
  HIDE_ABOUT_FORM: "HIDE_ABOUT_FORM",
  SHOW_LOCALE_FORM: "SHOW_LOCALE_FORM",
  HIDE_LOCALE_FORM: "HIDE_LOCALE_FORM",
  SET_REDIRECT_URL: "SET_REDIRECT_URL",

  toggleCollapsed: () => ({
    type: actions.COLLPSE_CHANGE,
  }),
  toggleAll: (width, height) => {
    const view = getView(width);
    const collapsed = view !== "DesktopView";
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height,
    };
  },
  toggleOpenDrawer: () => ({
    type: actions.COLLPSE_OPEN_DRAWER,
  }),
  changeOpenKeys: (openKeys) => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys,
  }),
  changeCurrent: (current) => ({
    type: actions.CHANGE_CURRENT,
    current,
  }),
  clearMenu: () => ({ type: actions.CLEAR_MENU }),

  showStatusModel: () => ({
    type: actions.SHOW_STATUS_MODEL,
  }),
  hideStatusModel: () => ({
    type: actions.HIDE_STATUS_MODEL,
  }),

  showAboutForm: () => ({
    type: actions.SHOW_ABOUT_FORM,
  }),
  hideAboutForm: () => ({
    type: actions.HIDE_ABOUT_FORM,
  }),

  showLocaleForm: () => ({
    type: actions.SHOW_LOCALE_FORM,
  }),
  hideLocaleForm: () => ({
    type: actions.HIDE_LOCALE_FORM,
  }),

  setRedirectUrl: (redirectUrl) => ({
    type: actions.SET_REDIRECT_URL,
    redirectUrl: redirectUrl,
  }),
};
export default actions;
