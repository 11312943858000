// axios.js
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import axios from "axios";
import AuthUtils from "redux/public/auth/signin/utils";
import { store } from "redux/store";
import { refreshAuth, localSignOut } from "redux/public/auth/signin/actions";
//import {aws4Interceptor} from './signer'

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// const interceptor = aws4Interceptor();
// axios.interceptors.request.use(interceptor);

export function authHeaders() {
  return {
    Authorization: "Bearer " + Cookies.get("access_token"),
    "MIMOTO-ID-TOKEN": Cookies.get("id_token"),
    "MIMOTO-REFRESH-TOKEN": Cookies.get("refresh_token"),
  };
}

export function jwtTokens() {
  return {
    MIMOTO_ID_TOKEN: Cookies.get("id_token"),
    MIMOTO_REFRESH_TOKEN: Cookies.get("refresh_token"),
  };
}

export function deviceHeaders() {
  let headers = {};
  let identification = AuthUtils.getIdentification();
  let username = identification.username;
  let device_key = Cookies.get(`${username}-device_key`);
  if (device_key) headers["MIMOTO-DEVICE-KEY"] = device_key;
  return headers;
}

export function refreshHeaders() {
  return {
    "MIMOTO-REFRESH-TOKEN": Cookies.get("refresh_token"),
    ...deviceHeaders(),
  };
}

export function challengeSession() {
  return {
    "MIMOTO-CHALLENGE-SESSION": Cookies.get("challenge_session"),
  };
}

export function getUserContext() {
  return jwtDecode(Cookies.get("access_token"));
}

export function getUserData(username) {
  return {
    "MIMOTO-ENCODED-DATA": window.AmazonCognitoAdvancedSecurityData.getData(
      username,
      process.env.REACT_APP_USER_POOL_ID,
      process.env.REACT_APP_USER_POOL_CLIENT_ID
    ),
  };
}

axios.interceptors.request.use(
  async function (config) {
    let access_token = config.headers.Authorization;
    // ========================= PUBLIC ENDPOINT ===============================
    if (!access_token) {
      console.log("Skip, Public Endpoint.");
      return config;
    }
    // ====================== PRIVATE ENDPOINT ==============================
    else {
      console.log("Private Endpoint. checking");
      let valid_token = AuthUtils.isAuthTokenValid(access_token);
      // JWT valid
      if (valid_token) return config;
      // JWT non valid
      else {
        let deviceMetadata = AuthUtils.getDeviceMetadata();
        let stay_connected = AuthUtils.getStayConnected();
        if (deviceMetadata.device_key && stay_connected) {
          await store.dispatch(refreshAuth());
          let auth = AuthUtils.getAuth();
          config.headers.Authorization = `Bearer ${auth.access_token}`;
          return config;
        } else {
          await store.dispatch(localSignOut());
          throw new axios.Cancel("Operation canceled, user not authenticated.");
        }
      }
    }
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      // HANDLE ERROR
      return Promise.reject(error.response.data.error);
    } else {
      console.log(error);
      return Promise.reject("Network, API Gateway and Authorization error");
    }
  }
);

export default axios;
