import * as Actions from "./actions";

const initialState = {
  success: false,
  groups: [],
};

const groups = function (state = initialState, action) {
  switch (action.type) {
    case Actions.LIST_GROUPS_REQUEST: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.LIST_GROUPS_SUCCESS: {
      return {
        success: false,
        groups: action.groups,
      };
    }
    case Actions.LIST_GROUPS_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default groups;
