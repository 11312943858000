import App from "./main/app/reducer";
import LanguageSwitcher from "./main/languageSwitcher/reducer";
import Loading from "./main/loading/reducer";
import SignUp from "./public/registration/signup/reducer";
import ConfirmSignUp from "./public/registration/confirm/reducer";
import Identify from "./public/auth/identify/reducer";
import ResetPassword from "./public/reset_password/reducer";
import SignIn from "./public/auth/signin/reducer";
import ChangePassword from "./account/security/password/reducer";
import Username from "./account/identities/username/reducer";
import Email from "./account/identities/email/reducer";
import Phone from "./account/identities/phone/reducer";
import OTPMFA from "./account/security/mfa/otp/reducer";
import MFAPreference from "./account/security/mfa/preference/reducer";
import User from "./account/profile/reducer";
import OTPMFAChallenge from "./public/auth/challenge/otp/reducer";
import SMSMFAChallenge from "./public/auth/challenge/sms/reducer";
import NewPasswordRequiredChallenge from "./public/auth/challenge/password/reducer";
import PasswordlessMFAChallenge from "./public/auth/challenge/passwordless/reducer";
import Device from "./account/security/device/reducer";
import Users from "./manage/users/reducer";
import Groups from "./manage/groups/reducer";
import Authorizer from "./manage/authorizer/reducer";

export default {
  App,
  LanguageSwitcher,
  SignUp,
  ConfirmSignUp,
  Identify,
  SignIn,
  User,
  ResetPassword,
  ChangePassword,
  OTPMFA,
  Username,
  Email,
  Phone,
  MFAPreference,
  OTPMFAChallenge,
  SMSMFAChallenge,
  NewPasswordRequiredChallenge,
  PasswordlessMFAChallenge,
  Device,
  Loading,
  Users,
  Groups,
  Authorizer,
};
