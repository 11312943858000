import {all, takeEvery, put, fork} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import {message, notification} from "antd";
import * as Actions from './actions';


export function* initiateSignUpSuccess() {
    yield takeEvery(Actions.INITIATE_SIGNUP_SUCCESS, function* ({user, ask_extra_info}) {
        if (!ask_extra_info)
            yield put(
                Actions.signUp(user)
            );
    });
}

export function* initiateSignUpFailure() {
    yield takeEvery(Actions.INITIATE_SIGNUP_FAILURE, function* ({error}) {
        const msg = () => {
            message.error(
                "One or more invalid fields",
                3
            );
        };
        yield put(
            msg
        )
    });
}

export function* signUp() {
    yield takeEvery(Actions.SIGNUP_SUCCESS, function* ({user, feedback}) {
        if (feedback.confirmation_required) {
            let context = {
                username: feedback.username,
                alias: feedback.alias,
                from: '/registration/sign-up'
            };
            if (feedback.delivery_medium === 'EMAIL')
                yield put(
                    push({
                        pathname: '/registration/confirm/email',
                        state: context
                    })
                );
            else if (feedback.delivery_medium === 'SMS')
                yield put(
                    push({
                        pathname: '/registration/confirm/phone',
                        state: context
                    })
                );
            else {
                yield put(
                    push({
                        pathname: '/auth/identify',
                    })
                );
            }
        }
        else {
            yield put(
                push({
                    pathname: '/auth/identify',
                })
            );
            notification.success({
                message: 'Registered successfully',
                description: 'Registered successfully, welcome.'
            });
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(initiateSignUpSuccess),
        fork(initiateSignUpFailure),
        fork(signUp),
    ]);
}
