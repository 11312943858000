import passwordService from "services/password";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

export function changePassword({
  previous_password,
  proposed_password,
  sign_out_all_devices,
}) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD_REQUEST,
    });
    return passwordService
      .changePassword(
        previous_password,
        proposed_password,
        sign_out_all_devices
      )
      .then((data) => {
        return dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
          sign_out_all_devices: sign_out_all_devices,
        });
      })
      .catch((error) => {
        return dispatch({
          type: CHANGE_PASSWORD_FAILURE,
          error: error,
        });
      });
  };
}
