import {all, takeEvery, put, fork} from 'redux-saga/effects';
import * as Actions from './actions';
import * as AuthActions from "redux/public/auth/signin/actions";


export function* onRespondPasswordLessAuthSuccess() {
    yield takeEvery(Actions.RESPOND_PASSWORDLESS_CHALLENGE_SUCCESS, function* ({auth}) {
        yield put({type: AuthActions.POST_AUTHENTICATION, auth: auth, remember_device: null});
    });
}

export default function* rootSaga() {
    yield all([
        fork(onRespondPasswordLessAuthSuccess),
    ]);
}
