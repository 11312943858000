import {all, takeEvery, put, fork, select} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie"
import * as Actions from './actions';
import * as UsersActions from 'redux/account/profile/actions';
//import appActions from "redux/main/app/actions";
import AuthUtils from './utils'
import * as DeviceActions from "../../../account/security/device/actions";

export const getApp = (state) => state.App;

const challenges = {
    CUSTOM_CHALLENGE: 'CUSTOM_CHALLENGE', // PASSWORDLESS
    SELECT_MFA_TYPE: 'SELECT_MFA_TYPE',
    SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
    SMS_MFA: 'SMS_MFA',
    DEVICE_SRP_AUTH: 'DEVICE_SRP_AUTH',
    NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED'
};

export function* initiateAuthSuccess() {
    yield takeEvery(Actions.INITIATE_AUTH_SUCCESS, function* ({auth}) {
        // NO CHALLENGE: Allow
        if (auth.authenticated){
            yield put({type: Actions.POST_AUTHENTICATION, auth: auth});
        }
        // CHALLENGE: Set session
        if (auth.challenge_session) {
            AuthUtils.setChallengeSession(auth.challenge_session)
        }
        // CHALLENGE: Check type and dispatch
        switch (auth.challenge_name) {
            // CHALLENGE: PASSWORDLESS
            case challenges.CUSTOM_CHALLENGE:
                if (auth.challenge_parameters.email)
                    yield put(
                        push({
                            pathname: '/challenge/passwordless/email'
                        })
                    );
                else if (auth.challenge_parameters.phone_number)
                    yield put(
                        push({
                            pathname: '/challenge/passwordless/phone'
                        })
                    );
                break;
            // CHALLENGE: SELECT MFA TYPE
            case challenges.SELECT_MFA_TYPE:
                yield put(
                    push({
                        pathname: '/challenge/mfa/type'
                    })
                );
                break;
            // CHALLENGE: TOTP MFA
            case challenges.SOFTWARE_TOKEN_MFA:
                yield put(
                    push({
                        pathname: '/challenge/mfa/otp'
                    })
                );
                break;
            // CHALLENGE: SMS MFA
            case challenges.SMS_MFA:
                yield put(
                    push({
                        pathname: '/challenge/mfa/sms'
                    })
                );
                break;
            // CHALLENGE: DEVICE AUTH
            case challenges.DEVICE_SRP_AUTH:
                yield put(
                    Actions.deviceAuth(auth.challenge_parameters.USER_ID_FOR_SRP)
                );
                break;
            // CHALLENGE: RESET REQUIRED
            case challenges.NEW_PASSWORD_REQUIRED:
                yield put(
                    push({
                        pathname: '/challenge/password/change',
                        state: {
                            user: {
                                username: auth.challenge_parameters.USER_ID_FOR_SRP
                            }
                        }
                    })
                );
                break;
            default:
                break;
        }
    });
}

export function* onAutoLoginRefreshAuth() {
    yield takeEvery(Actions.AUTO_LOGIN_REFRESH_AUTH, function* () {
        yield put(
            Actions.refreshAuth()
        );
    });
}

export function* onAutoLoginCurrentUser() {
    yield takeEvery(Actions.AUTO_LOGIN_CURRENT_USER, function* () {
        yield put(
            UsersActions.getCurrentUser()
        );
    });
}

export function* onAutoLogout() {
    yield takeEvery(Actions.AUTO_LOGOUT, function* () {
        yield put(
            Actions.localSignOut()
        );
        yield put(
            push({
                pathname: '/auth/identify'
            })
        );
    });
}

export function* onDeviceAuthSuccess() {
    yield takeEvery(Actions.DEVICE_AUTH_SUCCESS, function* ({auth}) {
        if (auth.authenticated){
            yield put({type: Actions.POST_AUTHENTICATION, auth: auth});
        }
        yield put(Actions.booted());
    });
}

export function* onSSOAuthSuccess() {
    yield takeEvery(Actions.SSO_SUCCESS, function* ({auth}) {
        if (auth.authenticated){
            yield put({type: Actions.POST_AUTHENTICATION, auth: auth});
        }
        yield put(Actions.booted());
    });
}


export function* onRefreshAuthSuccess() {
    yield takeEvery(Actions.REFRESH_AUTH_SUCCESS, function* ({auth}) {
        if (auth.authenticated){
            yield put({type: Actions.POST_AUTHENTICATION, auth: auth});
        }
        yield put(Actions.booted());
    });
}

export function* onRefreshAuthFailure() {
    yield takeEvery(Actions.REFRESH_AUTH_FAILURE, function* ({auth}) {
        yield put(Actions.localSignOut());
        yield put(Actions.booted());
    });
}

export function* onSignOutSuccess() {
    yield takeEvery(Actions.SIGN_OUT_SUCCESS, function* () {
        yield put(
            UsersActions.clearUserData(),
        );
        yield put(
            Actions.booted()
        );
        yield put(
            push({
                pathname: '/auth/identify'
            })
        );
    });
}

export function* onPostAuthentication() {
    yield takeEvery(Actions.POST_AUTHENTICATION, function* ({auth, remember_device}) {
        console.log(auth);
        AuthUtils.setSession(auth.id_token, auth.access_token, auth.refresh_token);
        yield put(Actions.setAuthData(auth));
        yield put(UsersActions.setUserData(auth.user),);
        if (auth.new_device)
            yield put(DeviceActions.confirmDevice(auth.new_device, remember_device));

        // For suppressing MFA challenge
        if (remember_device) {
            AuthUtils.setDeviceMetadata(auth.new_device)
        }

        // For refreshing token
        let stay_connected = AuthUtils.getStayConnected();
        if (stay_connected) {
            AuthUtils.setDeviceKey(jwtDecode(Cookies.get('access_token')).device_key)
        }

        let app = yield select(getApp);
        // if (!auth.user.data.attributes.email_verified || !auth.user.data.attributes.phone_number_verified) {
        //     yield put(
        //         push({
        //             pathname: '/checkups'
        //         })
        //     );
        // }
        //
        // else {
        //     if (app.redirectUrl) {
        //         // appActions.setRedirectUrl(null);
        //         window.location.assign(app.redirectUrl);
        //     }
        // }
        if (app.redirectUrl) {
            // appActions.setRedirectUrl(null);
            window.location.assign(app.redirectUrl);
        }
        yield put(
            Actions.authorize()
        );
    });
}

export function* onPostAuthorization() {
    yield takeEvery(Actions.STS_AUTHORIZATION_SUCCESS, function* ({authorization}) {
        AuthUtils.setAWSSTSAuthorization(authorization);
        yield
    });
}


export default function* rootSaga() {
    yield all([
        fork(initiateAuthSuccess),
        fork(onAutoLoginRefreshAuth),
        fork(onAutoLoginCurrentUser),
        fork(onAutoLogout),
        fork(onRefreshAuthSuccess),
        fork(onSignOutSuccess),
        fork(onRefreshAuthFailure),
        fork(onDeviceAuthSuccess),
        fork(onPostAuthentication),
        fork(onPostAuthorization),
        fork(onSSOAuthSuccess)
    ]);
}
