import usersService from "services/users";

export const UPDATE_EMAIL_REQUEST = "UPDATE_EMAIL_REQUEST";
export const UPDATE_EMAIL_FAILURE = "UPDATE_EMAIL_FAILURE";
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS";

export const VERIFY_EMAIL_RQST_REQUEST = "VERIFY_EMAIL_RQST_REQUEST";
export const VERIFY_EMAIL_RQST_FAILURE = "VERIFY_EMAIL_RQST_FAILURE";
export const VERIFY_EMAIL_RQST_SUCCESS = "VERIFY_EMAIL_RQST_SUCCESS";

export const VERIFY_EMAIL_PROCEED_REQUEST = "VERIFY_EMAIL_PROCEED_REQUEST";
export const VERIFY_EMAIL_PROCEED_FAILURE = "VERIFY_EMAIL_PROCEED_FAILURE";
export const VERIFY_EMAIL_PROCEED_SUCCESS = "VERIFY_EMAIL_PROCEED_SUCCESS";

export const EMAIL_UI_RESET = "EMAIL_UI_RESET";

export function updateEmail({ email }) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_EMAIL_REQUEST,
    });
    return usersService
      .updateUserIdentity("email", email)
      .then((data) => {
        return dispatch({
          type: UPDATE_EMAIL_SUCCESS,
          pending_verification_email: email,
        });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_EMAIL_FAILURE,
          error: error,
        });
      });
  };
}

export function verifyEmailRequest() {
  return (dispatch) => {
    dispatch({
      type: VERIFY_EMAIL_RQST_REQUEST,
    });
    return usersService
      .verifyAttributeRequest("email")
      .then((data) => {
        return dispatch({
          type: VERIFY_EMAIL_RQST_SUCCESS,
        });
      })
      .catch((error) => {
        return dispatch({
          type: VERIFY_EMAIL_RQST_FAILURE,
          error: error,
        });
      });
  };
}

export function verifyEmailProceed({
  verification_code,
  pending_verification_email,
}) {
  return (dispatch) => {
    dispatch({
      type: VERIFY_EMAIL_PROCEED_REQUEST,
    });
    return usersService
      .verifyAttributeProceed("email", verification_code)
      .then((data) => {
        return dispatch({
          type: VERIFY_EMAIL_PROCEED_SUCCESS,
          new_email: pending_verification_email,
        });
      })
      .catch((error) => {
        return dispatch({
          type: VERIFY_EMAIL_PROCEED_FAILURE,
          error: error,
        });
      });
  };
}

export function emailUIReset() {
  return (dispatch) => {
    return dispatch({
      type: EMAIL_UI_RESET,
    });
  };
}
