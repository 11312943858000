import * as Actions from './actions';

const initialState = {
    success: false,
    ask_extra_info: false,
    error  : {
        email: null,
        password: null,
        phone_number: null
    },
    user: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        confirm: null,
        phone_number: null,
        birthdate: null,
        gender: null
    }
};

const signUp = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.INITIATE_SIGNUP_SUCCESS:
        {
            return {
                ...initialState,
                success: true,
                user: action.user,
                ask_extra_info: action.ask_extra_info
            };
        }
        case Actions.INITIATE_SIGNUP_FAILURE:
        {
            console.info(action.error.fields);
            return {
                ...initialState,
                success: false,
                error  : {
                    email: action.error.fields.alias || null,
                    password: action.error.fields.password || null
                }
            };
        }
        case Actions.SIGNUP_SUCCESS:
        {
            return {
                ...initialState,
                success: true,
                user: action.user,
                dialog: action
            };
        }
        case Actions.SIGNUP_FAILURE:
        {
            return {
                ...initialState,
                success: false,
                error  : {
                    phone_number: 'User with phone number already exist.'
                },
            };
        }
        default:
        {
            return state
        }
    }
};

export default signUp;