import mfaService from "services/mfa";

export const ASSOCIATE_OTP_REQUEST = "ASSOCIATE_OTP_REQUEST";
export const ASSOCIATE_OTP_FAILURE = "ASSOCIATE_OTP_FAILURE";
export const ASSOCIATE_OTP_SUCCESS = "ASSOCIATE_OTP_SUCCESS";

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";

export const OTP_MFA_UI_RESET = "OTP_MFA_UI_RESET";

export function associateOTP() {
  return (dispatch) => {
    dispatch({
      type: ASSOCIATE_OTP_REQUEST,
    });
    mfaService
      .associateSoftwareToken()
      .then((data) => {
        return dispatch({
          type: ASSOCIATE_OTP_SUCCESS,
          secret_code: data.secret_code,
          qr_code: data.qr_code,
        });
      })
      .catch((error) => {
        return dispatch({
          type: ASSOCIATE_OTP_FAILURE,
          error: error,
        });
      });
  };
}

export function verifyOTP({ user_code, friendly_device_name }) {
  return (dispatch) => {
    dispatch({
      type: VERIFY_OTP_REQUEST,
    });
    mfaService
      .verifySoftwareToken(user_code, friendly_device_name)
      .then((data) => {
        return dispatch({
          type: VERIFY_OTP_SUCCESS,
        });
      })
      .catch((error) => {
        return dispatch({
          type: VERIFY_OTP_FAILURE,
          error: error,
        });
      });
  };
}

export function otpMFAUIReset() {
  return (dispatch) => {
    return dispatch({
      type: OTP_MFA_UI_RESET,
    });
  };
}
