import styled from "styled-components";
import {
  transition,
  borderRadius,
  boxShadow,
} from "settings/styles/style-util";
import WithDirection from "settings/styles/withDirection";

const TopbarDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: -12px -16px;
  width: 360px;
  min-width: 160px;
  flex-shrink: 0;
  ${borderRadius("5px")};
  ${boxShadow("0 2px 10px rgba(0,0,0,0.2)")};
  ${transition()};

  @media only screen and (max-width: 767px) {
    width: 310px;
  }

  &.isoUserDropdown {
    padding: 7px 0;
    display: flex;
    flex-direction: column;
    width: 180px;
    min-width: 160px;
    flex-shrink: 0;
    ${borderRadius("5px")};
    ${boxShadow("0 2px 10px rgba(0,0,0,0.2)")};
    ${transition()};

    .isoDropdownItem {
      height: 25px;
      line-height: 25px;
      text-decoration: none;
      display: flex;
      justify-content: flex-start;
      ${transition()};
    }
  }

  .statusBtn span {
    vertical-align: text-bottom;
  }
`;

export default WithDirection(TopbarDropdownWrapper);
