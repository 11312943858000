import usersService from "services/users";

export const UPDATE_USERNAME_REQUEST = "UPDATE_USERNAME_REQUEST";
export const UPDATE_USERNAME_FAILURE = "UPDATE_USERNAME_FAILURE";
export const UPDATE_USERNAME_SUCCESS = "UPDATE_USERNAME_SUCCESS";

export function updateUsername({ preferred_username }) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USERNAME_REQUEST,
    });
    return usersService
      .updateUserIdentity("preferred_username", preferred_username)
      .then((data) => {
        return dispatch({
          type: UPDATE_USERNAME_SUCCESS,
          preferred_username: preferred_username,
        });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_USERNAME_FAILURE,
          error: error,
        });
      });
  };
}
