import authorizerService from "services/authorizer";

export const LIST_GROUP_POLICIES_ATTACHMENTS_REQUEST =
  "LIST_GROUP_POLICIES_ATTACHMENTS_REQUEST";
export const LIST_GROUP_POLICIES_ATTACHMENTS_FAILURE =
  "LIST_GROUP_POLICIES_ATTACHMENTS_FAILURE";
export const LIST_GROUP_POLICIES_ATTACHMENTS_SUCCESS =
  "LIST_GROUP_POLICIES_ATTACHMENTS_SUCCESS";

export const ATTACH_POLICY_REQUEST = "ATTACH_POLICY_REQUEST";
export const ATTACH_POLICY_FAILURE = "ATTACH_POLICY_FAILURE";
export const ATTACH_POLICY_SUCCESS = "ATTACH_POLICY_SUCCESS";

export const DETACH_POLICY_REQUEST = "DETACH_POLICY_REQUEST";
export const DETACH_POLICY_FAILURE = "DETACH_POLICY_FAILURE";
export const DETACH_POLICY_SUCCESS = "DETACH_POLICY_SUCCESS";

export const RETRIEVE_POLICY_REQUEST = "RETRIEVE_POLICY_REQUEST";
export const RETRIEVE_POLICY_FAILURE = "RETRIEVE_POLICY_FAILURE";
export const RETRIEVE_POLICY_SUCCESS = "RETRIEVE_POLICY_SUCCESS";

export const RETRIEVE_ROLE_REQUEST = "RETRIEVE_ROLE_REQUEST";
export const RETRIEVE_ROLE_FAILURE = "RETRIEVE_ROLE_FAILURE";
export const RETRIEVE_ROLE_SUCCESS = "RETRIEVE_ROLE_SUCCESS";

export const UPDATE_STS_SESSION_DURATION_REQUEST =
  "UPDATE_STS_SESSION_DURATION_REQUEST";
export const UPDATE_STS_SESSION_DURATION_FAILURE =
  "UPDATE_STS_SESSION_DURATION_FAILURE";
export const UPDATE_STS_SESSION_DURATION_SUCCESS =
  "UPDATE_STS_SESSION_DURATION_SUCCESS";

export function listGroupPoliciesAttachments(group_name) {
  return (dispatch) => {
    dispatch({
      type: LIST_GROUP_POLICIES_ATTACHMENTS_REQUEST,
    });
    return authorizerService
      .listGroupPoliciesAttachments(group_name)
      .then((data) => {
        return dispatch({
          type: LIST_GROUP_POLICIES_ATTACHMENTS_SUCCESS,
          policies: data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: LIST_GROUP_POLICIES_ATTACHMENTS_FAILURE,
          error: error,
        });
      });
  };
}

export function attachPolicy(group_name, policy_name, policy_type) {
  return (dispatch) => {
    dispatch({
      type: ATTACH_POLICY_REQUEST,
    });
    return authorizerService
      .attachPolicy(group_name, policy_name, policy_type)
      .then((data) => {
        return dispatch({
          type: ATTACH_POLICY_SUCCESS,
          group_name: group_name,
        });
      })
      .catch((error) => {
        return dispatch({
          type: ATTACH_POLICY_FAILURE,
          error: error,
        });
      });
  };
}

export function detachPolicy(group_name, policy_name, policy_type) {
  return (dispatch) => {
    dispatch({
      type: DETACH_POLICY_REQUEST,
    });
    return authorizerService
      .detachPolicy(group_name, policy_name, policy_type)
      .then((data) => {
        return dispatch({
          type: DETACH_POLICY_SUCCESS,
          group_name: group_name,
        });
      })
      .catch((error) => {
        return dispatch({
          type: DETACH_POLICY_FAILURE,
          error: error,
        });
      });
  };
}

export function retrievePolicy(policy_name, policy_type) {
  return (dispatch) => {
    dispatch({
      type: RETRIEVE_POLICY_REQUEST,
    });
    return authorizerService
      .retrievePolicy(policy_name, policy_type)
      .then((data) => {
        return dispatch({
          type: RETRIEVE_POLICY_SUCCESS,
          policy_document: data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: RETRIEVE_POLICY_FAILURE,
          error: error,
        });
      });
  };
}

export function retrieveRole(group_name) {
  return (dispatch) => {
    dispatch({
      type: RETRIEVE_ROLE_REQUEST,
    });
    return authorizerService
      .retrieveRole(group_name)
      .then((data) => {
        return dispatch({
          type: RETRIEVE_ROLE_SUCCESS,
          role: data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: RETRIEVE_ROLE_FAILURE,
          error: error,
        });
      });
  };
}

export function updateSTSSessionDuration(group_name, max_session_duration) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_STS_SESSION_DURATION_REQUEST,
    });
    return authorizerService
      .updateSTSSessionDuration(group_name, max_session_duration)
      .then((data) => {
        return dispatch({
          type: UPDATE_STS_SESSION_DURATION_SUCCESS,
          max_session_duration: max_session_duration,
        });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_STS_SESSION_DURATION_FAILURE,
          error: error,
        });
      });
  };
}
