import axios, { deviceHeaders, refreshHeaders } from "./axios";
import { authHeaders, getUserData, challengeSession } from "./axios";

const basePath = "/accounts/v1/auth";

class AuthService {
  // =======================================================
  // INITIATE AUTH:
  //  - Initiate user authentication using NO SRP AUTH Flow
  // =======================================================

  initiateAuth = (username, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/initiate/password`,
          {
            username: username,
            password: password,
          },
          {
            headers: {
              ...deviceHeaders(),
              ...getUserData(username),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // SSO AUTH:
  //  - Exchange sso code with jwt tokens
  // =======================================================
  ssoAuth = (code) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${basePath}/sso`, {
          code: code,
          redirect_uri: process.env.REACT_APP_SOCIAL_REDIRECT_URI,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // INITIATE PASSWORDLESS AUTH:
  //  - Initiate user authentication using CUSTOM AUTH Flow
  // =======================================================

  initiatePasswordLessAuth = (username) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/initiate/passwordless`,
          {
            username: username,
          },
          {
            headers: {
              ...deviceHeaders(),
              ...getUserData(username),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // RESPOND TO PASSWORDLESS CHALLENGE:
  //  - Respond to passwordless challenge
  // =======================================================

  respondPasswordLess = (username, answer) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/respond/passwordless`,
          {
            username: username,
            answer: answer,
          },
          {
            headers: {
              ...challengeSession(),
              ...getUserData(username),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // RESPOND TO SELECT MFA TYPE CHALLENGE:
  //  - Respond to select mfa type challenge
  // =======================================================

  respondSelectMFAType = (username, answer) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/respond/select-mfa-type`,
          {
            username: username,
            answer: answer,
          },
          {
            headers: {
              ...challengeSession(),
              ...getUserData(username),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // RESPOND TO SOFTWARE TOKEN MFA:
  //  - Respond to software token mfa challenge
  // =======================================================

  respondSoftwareTokenMFA = (username, software_token_mfa_code) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/respond/software-token-mfa`,
          {
            username: username,
            software_token_mfa_code: software_token_mfa_code,
          },
          {
            headers: {
              ...getUserData(username),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // RESPOND TO SMS TOKEN MFA:
  //  - Respond to sms mfa challenge
  // =======================================================

  respondSMSMFA = (username, sms_mfa_code) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/respond/sms-mfa`,
          {
            username: username,
            sms_mfa_code: sms_mfa_code,
          },
          {
            headers: {
              ...getUserData(username),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // RESPOND DEVICE AUTH:
  //  - Respond to device auth
  // =======================================================

  respondDeviceAuth = (username, device) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/respond/device-auth`,
          {
            username,
            device_group_key: device.device_group_key,
            device_password: device.device_password,
          },
          {
            headers: {
              ...deviceHeaders(),
              ...getUserData(username),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // RESPOND NEW PASSWORD REQUIRED:
  //  - Respond to new device required
  // =======================================================
  respondNewPasswordRequired = (username, new_password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/respond/new-password-required`,
          {
            username,
            new_password: new_password,
          },
          {
            headers: {
              ...deviceHeaders(),
              ...getUserData(username),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // REFRESH AUTH:
  //  - Refresh authentication tokens using refresh token
  // =======================================================

  refreshAuth = (username) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${basePath}/refresh`,
          {
            username: username,
          },
          {
            headers: {
              ...refreshHeaders(),
              ...getUserData(username),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // GLOBAL SIGN OUT:
  //  - Sign out user globally
  // =======================================================
  globalSignOut = () => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${basePath}/terminate`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          this.revokeSession();
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // LOCAL SIGN OUT:
  //  - Sign out user locally
  // =======================================================
  localSignOut = () => {
    this.revokeSession();
  };
}

const instance = new AuthService();

export default instance;
