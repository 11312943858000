import groupsService from "services/groups";

export const LIST_GROUPS_REQUEST = "LIST_GROUPS_REQUEST";
export const LIST_GROUPS_FAILURE = "LIST_GROUPS_FAILURE";
export const LIST_GROUPS_SUCCESS = "LIST_GROUPS_SUCCESS";

export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST";
export const CREATE_GROUP_FAILURE = "CREATE_GROUP_FAILURE";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";

export const DELETE_GROUP_REQUEST = "DELETE_GROUP_REQUEST";
export const DELETE_GROUP_FAILURE = "DELETE_GROUP_FAILURE";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";

export const RETRIEVE_GROUP_REQUEST = "RETRIEVE_GROUP_REQUEST";
export const RETRIEVE_GROUP_FAILURE = "RETRIEVE_GROUP_FAILURE";
export const RETRIEVE_GROUP_SUCCESS = "RETRIEVE_GROUP_SUCCESS";

export const UPDATE_GROUP_REQUEST = "UPDATE_GROUP_REQUEST";
export const UPDATE_GROUP_FAILURE = "UPDATE_GROUP_FAILURE";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";

export function listGroups() {
  return (dispatch) => {
    dispatch({
      type: LIST_GROUPS_REQUEST,
    });
    return groupsService
      .listGroups()
      .then((data) => {
        return dispatch({
          type: LIST_GROUPS_SUCCESS,
          groups: data.groups,
        });
      })
      .catch((error) => {
        return dispatch({
          type: LIST_GROUPS_FAILURE,
          error: error,
        });
      });
  };
}

export function createGroup(group) {
  return (dispatch) => {
    dispatch({
      type: CREATE_GROUP_REQUEST,
    });
    return groupsService
      .createGroup(group)
      .then((data) => {
        return dispatch({
          type: CREATE_GROUP_SUCCESS,
        });
      })
      .catch((error) => {
        return dispatch({
          type: CREATE_GROUP_FAILURE,
          error: error,
        });
      });
  };
}

export function deleteGroup(group_name) {
  return (dispatch) => {
    dispatch({
      type: DELETE_GROUP_REQUEST,
    });
    return groupsService
      .deleteGroup(group_name)
      .then((data) => {
        return dispatch({
          type: DELETE_GROUP_SUCCESS,
        });
      })
      .catch((error) => {
        return dispatch({
          type: DELETE_GROUP_FAILURE,
          error: error,
        });
      });
  };
}

export function retrieveGroup(group_name) {
  return (dispatch) => {
    dispatch({
      type: RETRIEVE_GROUP_REQUEST,
    });
    return groupsService
      .retrieveGroup(group_name)
      .then((data) => {
        return dispatch({
          type: RETRIEVE_GROUP_SUCCESS,
          data: data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: RETRIEVE_GROUP_FAILURE,
          error: error,
        });
      });
  };
}

export function updateGroup(group) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_GROUP_REQUEST,
    });
    return groupsService
      .updateGroup(group)
      .then((data) => {
        return dispatch({
          type: UPDATE_GROUP_SUCCESS,
        });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_GROUP_FAILURE,
          error: error,
        });
      });
  };
}
