import { language } from "settings";

const englishLang = "/images/flags/uk.svg";
const chineseLang = "/images/flags/china.svg";
const spanishLang = "/images/flags/spain.svg";
const frenchLang = "/images/flags/france.svg";
const italianLang = "/images/flags/italy.svg";

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: "english",
      locale: "en",
      text: "English",
      icon: englishLang,
    },
    {
      languageId: "chinese",
      locale: "zh",
      text: "Chinese",
      icon: chineseLang,
    },
    {
      languageId: "spanish",
      locale: "es",
      text: "Spanish",
      icon: spanishLang,
    },
    {
      languageId: "french",
      locale: "fr",
      text: "French",
      icon: frenchLang,
    },
    {
      languageId: "italian",
      locale: "it",
      text: "Italian",
      icon: italianLang,
    },
  ],
};

export function getCurrentLanguage(lang) {
  let selectedLanguage = config.options[0];
  config.options.forEach((language) => {
    if (language.languageId === lang) {
      selectedLanguage = language;
    }
  });
  return selectedLanguage;
}

export default config;
