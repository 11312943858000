import usersService from 'services/users';


export const CONFIRM_SIGNUP_REQUEST = 'CONFIRM_SIGNUP_REQUEST';
export const CONFIRM_SIGNUP_SUCCESS = 'CONFIRM_SIGNUP_SUCCESS';
export const CONFIRM_SIGNUP_FAILURE = 'CONFIRM_SIGNUP_FAILURE';

export const RESEND_SIGNUP_CONFIRMATION_REQUEST = 'RESEND_SIGNUP_CONFIRMATION_REQUEST';
export const RESEND_SIGNUP_CONFIRMATION_SUCCESS = 'RESEND_SIGNUP_CONFIRMATION_SUCCESS';
export const RESEND_SIGNUP_CONFIRMATION_FAILURE = 'RESEND_SIGNUP_CONFIRMATION_FAILURE';

export const REQUEST_CONFIRMATION_REQUEST = 'REQUEST_CONFIRMATION_REQUEST';
export const REQUEST_CONFIRMATION_SUCCESS = 'REQUEST_CONFIRMATION_SUCCESS';
export const REQUEST_CONFIRMATION_FAILURE = 'REQUEST_CONFIRMATION_FAILURE';

export function confirm({username, confirmation_code})
{
    return (dispatch) => {
        dispatch({
            type: CONFIRM_SIGNUP_REQUEST
        });
        return usersService.confirm(username, confirmation_code)
            .then((data) => {
                    return dispatch({
                        type: CONFIRM_SIGNUP_SUCCESS
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type   : CONFIRM_SIGNUP_FAILURE,
                    error  : error
                });
            });
    };
}

export function resendConfirmation(username)
{
    return (dispatch) => {
        dispatch({
            type: RESEND_SIGNUP_CONFIRMATION_REQUEST
        });
        return usersService.resendConfirmation(username)
            .then((data) => {
                    return dispatch({
                        type: RESEND_SIGNUP_CONFIRMATION_SUCCESS
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type   : RESEND_SIGNUP_CONFIRMATION_FAILURE,
                    payload: error
                });
            });
    };

}

export function requestConfirmation(username)
{
    return (dispatch) => {
        dispatch({
            type: REQUEST_CONFIRMATION_REQUEST
        });
        return usersService.resendConfirmation(username)
            .then((data) => {
                    return dispatch({
                        type: REQUEST_CONFIRMATION_SUCCESS,
                        feedback: data.feedback
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type   : REQUEST_CONFIRMATION_FAILURE,
                    payload: error
                });
            });
    };

}
