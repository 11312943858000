import axios from "./axios";
import { authHeaders } from "./axios";

const basePath = "/accounts/v1/authorizer";

class AuthorizerService {
  // =======================================================
  // AUTHORIZE USER:
  //  - Authorize user using jwt id tokens
  // =======================================================
  authorize = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/authorize`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // LIST Group Policies Attachments:
  //  - List apis to authorize
  // =======================================================
  listGroupPoliciesAttachments = (group_name) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/groups/${group_name}/policies`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // ATTACH POLICY:
  //  - Attach policy to group IAM role
  // =======================================================

  attachPolicy = (group_name, policy_name, policy_type) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${basePath}/groups/${group_name}/policies/${policy_name}/attachment`,
          {},
          {
            headers: {
              ...authHeaders(),
            },
            params: {
              policy_type: policy_type,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // DETACH POLICY:
  //  - Detach policy from group IAM role
  // =======================================================

  detachPolicy = (group_name, policy_name, policy_type) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${basePath}/groups/${group_name}/policies/${policy_name}/attachment`,
          {
            headers: {
              ...authHeaders(),
            },
            params: {
              policy_type: policy_type,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // =======================================================
  // DETACH POLICY:
  //  - Detach policy from group IAM role
  // =======================================================

  retrievePolicy = (policy_name, policy_type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/policies/${policy_name}`, {
          headers: {
            ...authHeaders(),
          },
          params: {
            policy_type: policy_type,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  retrieveRole = (group_name) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${basePath}/groups/${group_name}/role`, {
          headers: {
            ...authHeaders(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateSTSSessionDuration = (group_name, max_session_duration) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${basePath}/groups/${group_name}/sts-session-duration`,
          {
            max_session_duration: max_session_duration,
          },
          {
            headers: {
              ...authHeaders(),
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

const instance = new AuthorizerService();

export default instance;
