import * as Actions from "./actions";

const initialState = {
  success: false,
};

const mfaPreference = function (state = initialState, action) {
  switch (action.type) {
    case Actions.UPDATE_MFA_PREFERENCE_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.UPDATE_MFA_PREFERENCE_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.UPDATE_MFA_PREFERENCE_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default mfaPreference;
