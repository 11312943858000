import { createGlobalStyle } from "styled-components";
import { palette } from "styled-theme";
import "antd/lib/style/themes/default.less";

const GlobalStyles = createGlobalStyle`
  
  .ant-layout {
    &.isoContentMainLayout {
      overflow: auto;
      overflow-x: hidden;
      @media only screen and (min-width: 768px) and (max-width: 1220px) {
        width: calc(100% - 180px);
        flex-shrink: 0;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        flex-shrink: 0;
      }
    }
  }
  
  //

  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .isoRightComponent {
    display: flex;
    align-items: right;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  //
  
  .ant-spin-nested-loading > div > .loaderSpinner {
    background-color: rgb(33, 37, 49);
    max-height: 100%;
    .ant-spin-blur {
        opacity: 0;
    }
  }
  
  .loaderSpinner {
    width: 100%;
    height: 100%;
    min-height: 100vh; 
  }
  
  //

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }
  
  /*-----------------------------------------------*/ 
  // style for Inputs
  /*-----------------------------------------------*/
  
  .isoInputWrapper {
    height: 50px;

    &:last-of-type {
      height: 40px;
    }
  }
  
    
  /*-----------------------------------------------*/ 
  // Logo head wrapper
  /*-----------------------------------------------*/
  
  .logoHeadWrapper {
    margin-bottom: 30px;
    vertical-align: center;

    span {
      margin-left: 6px;
      font-size: 36px;
      font-weight: 300;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
    
    a {
      display: block;
      text-decoration: none;
    }
  }
  
  .isoPublicCardContent {
      padding: 0 10px;
      border-radius: 3px;
      box-shadow: 0 10px 20px 0 rgba(0,0,0,.02), 0 6px 6px 0 rgba(0,0,0,.02);
      
      @media only screen and (max-width: 576px) {
        min-height: 100%;
        padding: 70px 20px;
        box-shadow: none;
        border: 0 !important;
      }
  }
  
  .ant-table-thead > tr > th {
    border-bottom: 1px solid ${palette("primary", 9)} !important;
  }
`;

export default GlobalStyles;
