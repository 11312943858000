import { all, takeEvery, fork, put } from "redux-saga/effects";
import * as Actions from "./actions";
import { notification } from "antd";

export function* onAttachPolicySuccess() {
  yield takeEvery(Actions.ATTACH_POLICY_SUCCESS, function* ({ group_name }) {
    notification.success({
      message: "Policy attached",
      description: "IAM Policy successfully attached to group IAM Role",
    });
    yield put(Actions.listGroupPoliciesAttachments(group_name));
  });
}

export function* onDetachPolicySuccess() {
  yield takeEvery(Actions.DETACH_POLICY_SUCCESS, function* ({ group_name }) {
    notification.success({
      message: "Policy detached",
      description: "IAM Policy successfully detached from group IAM Role",
    });
    yield put(Actions.listGroupPoliciesAttachments(group_name));
  });
}

export default function* rootSaga() {
  yield all([fork(onAttachPolicySuccess), fork(onDetachPolicySuccess)]);
}
