import { all } from "redux-saga/effects";
import signUpSagas from "./public/registration/signup/saga";
import confirmSignUpSagas from "./public/registration/confirm/saga";
import identifySagas from "./public/auth/identify/saga";
import signInSagas from "./public/auth/signin/saga";
import resetPasswordSagas from "./public/reset_password/saga";
import changePasswordSagas from "./account/security/password/saga";
import usernameSagas from "./account/identities/username/saga";
import emailSagas from "./account/identities/email/saga";
import phoneSagas from "./account/identities/phone/saga";
import otpMFASagas from "./account/security/mfa/otp/saga";
import mfaPreferenceSagas from "./account/security/mfa/preference/saga";
import userSagas from "./account/profile/saga";
import mfaTypeChallengeSagas from "./public/auth/challenge/mfa_type/saga";
import otpMFAChallengeSagas from "./public/auth/challenge/otp/saga";
import smsMFAChallengeSagas from "./public/auth/challenge/sms/saga";
import passwordlessChallengeSagas from "./public/auth/challenge/passwordless/saga";
import newPasswordRequiredChallengeSagas from "./public/auth/challenge/password/saga";
import deviceSagas from "./account/security/device/saga";
import usersSagas from "./manage/users/saga";
import groupsSagas from "./manage/groups/saga";
import authorizerSagas from "./manage/authorizer/saga";

export default function* rootSaga(getState) {
  yield all([
    signUpSagas(),
    confirmSignUpSagas(),
    identifySagas(),
    signInSagas(),
    userSagas(),
    resetPasswordSagas(),
    changePasswordSagas(),
    usernameSagas(),
    emailSagas(),
    phoneSagas(),
    otpMFASagas(),
    mfaPreferenceSagas(),
    otpMFAChallengeSagas(),
    mfaTypeChallengeSagas(),
    smsMFAChallengeSagas(),
    newPasswordRequiredChallengeSagas(),
    passwordlessChallengeSagas(),
    deviceSagas(),
    usersSagas(),
    groupsSagas(),
    authorizerSagas(),
  ]);
}
