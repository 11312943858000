import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as Actions from "./actions";
import appActions from "redux/main/app/actions";
import * as AuthActions from "redux/public/auth/signin/actions";

export function* onGetCurrentUserSuccess() {
  yield takeEvery(Actions.GET_CURRENT_USER_SUCCESS, function* () {
    yield put(AuthActions.booted());
  });
}

export function* onUpdateUserAboutSuccess() {
  yield takeEvery(Actions.UPDATE_USER_ABOUT_SUCCESS, function* ({ profile }) {
    yield put(Actions.updateProfile(profile));
    yield put(appActions.hideAboutForm());
  });
}

export function* onUpdateUserLocaleSuccess() {
  yield takeEvery(Actions.UPDATE_USER_LOCALE_SUCCESS, function* ({ profile }) {
    yield put(Actions.updateProfile(profile));
    yield put(appActions.hideLocaleForm());
  });
}

export function* onUpdateUserPictureSuccess() {
  yield takeEvery(Actions.UPDATE_USER_PICTURE_SUCCESS, function* ({ picture }) {
    yield put(Actions.updateProfileAttribute("picture", picture));
    yield put(appActions.hideStatusModel());
  });
}

export function* onClearUserPictureSuccess() {
  yield takeEvery(Actions.CLEAR_USER_PICTURE_SUCCESS, function* () {
    yield put(Actions.updateProfileAttribute("picture", null));
    yield put(appActions.hideStatusModel());
  });
}

export function* onUpdateUserStatusSuccess() {
  yield takeEvery(Actions.UPDATE_USER_STATUS_SUCCESS, function* ({ status }) {
    yield put(appActions.hideStatusModel());
    yield put(Actions.updateProfile(status));
  });
}

export function* onClearUserStatusSuccess() {
  yield takeEvery(Actions.CLEAR_USER_STATUS_SUCCESS, function* ({ status }) {
    yield put(appActions.hideStatusModel());
    yield put(Actions.updateProfile(status));
  });
}

export function* onDeactivateCurrentUserSuccess() {
  yield takeEvery(Actions.DEACTIVATE_CURRENT_USER_SUCCESS, function* () {
    yield put(AuthActions.localSignOut());
  });
}

export function* onTerminateCurrentUserSuccess() {
  yield takeEvery(Actions.TERMINATE_CURRENT_USER_SUCCESS, function* () {
    yield put(AuthActions.localSignOut());
  });
}

export default function* rootSaga() {
  yield all([
    fork(onGetCurrentUserSuccess),
    fork(onUpdateUserPictureSuccess),
    fork(onClearUserPictureSuccess),
    fork(onUpdateUserStatusSuccess),
    fork(onClearUserStatusSuccess),
    fork(onUpdateUserAboutSuccess),
    fork(onUpdateUserLocaleSuccess),
    fork(onDeactivateCurrentUserSuccess),
    fork(onTerminateCurrentUserSuccess),
  ]);
}
