import theme_downy from "./theme_downy";
import clone from "clone";

const theme = clone(theme_downy);
theme.palette.primary = [
  "#feeef0",
  "#fddde1",
  "#fdcbd2",
  "#fcbac3",
  "#fba9b4",
  "#fa98a5",
  "#f98796",
  "#f97587",
  "#f86478",
  "#f75369", // Base
  "#de4b5f",
  "#c64254",
  "#ad3a4a",
  "#94323f",
  "#7c2a35",
  "#63212a",
  "#4a191f",
  "#311115",
  "#19080a",
];
//theme.palette.secondary = ['#0f0'];
export default theme;
