import deviceService from "services/devices";
import { deviceDetect } from "react-device-detect";

export const CONFIRM_DEVICE_REQUEST = "CONFIRM_DEVICE_REQUEST";
export const CONFIRM_DEVICE_FAILURE = "CONFIRM_DEVICE_FAILURE";
export const CONFIRM_DEVICE_SUCCESS = "CONFIRM_DEVICE_SUCCESS";

export const JUST_CONFIRM_DEVICE_REQUEST = "CONFIRM_DEVICE_REQUEST";
export const JUST_CONFIRM_DEVICE_FAILURE = "CONFIRM_DEVICE_FAILURE";
export const JUST_CONFIRM_DEVICE_SUCCESS = "CONFIRM_DEVICE_SUCCESS";

export const REMEMBER_DEVICE_REQUEST = "REMEMBER_DEVICE_REQUEST";
export const REMEMBER_DEVICE_FAILURE = "REMEMBER_DEVICE_FAILURE";
export const REMEMBER_DEVICE_SUCCESS = "REMEMBER_DEVICE_SUCCESS";

export const FORGET_DEVICE_REQUEST = "FORGET_DEVICE_REQUEST";
export const FORGET_DEVICE_FAILURE = "FORGET_DEVICE_FAILURE";
export const FORGET_DEVICE_SUCCESS = "FORGET_DEVICE_SUCCESS";

export const LIST_DEVICES_REQUEST = "LIST_DEVICES_REQUEST";
export const LIST_DEVICES_FAILURE = "LIST_DEVICES_FAILURE";
export const LIST_DEVICES_SUCCESS = "LIST_DEVICES_SUCCESS";

export const DEAUTHORIZE_INACTIVE_DEVICES_REQUEST =
  "DEAUTHORIZE_INACTIVE_DEVICES_REQUEST";
export const DEAUTHORIZE_INACTIVE_DEVICES_FAILURE =
  "DEAUTHORIZE_INACTIVE_DEVICES_FAILURE";
export const DEAUTHORIZE_INACTIVE_DEVICES_SUCCESS =
  "DEAUTHORIZE_INACTIVE_DEVICES_SUCCESS";

export function listDevices() {
  return (dispatch) => {
    dispatch({
      type: LIST_DEVICES_REQUEST,
    });
    deviceService
      .listDevices()
      .then((data) => {
        return dispatch({
          type: LIST_DEVICES_SUCCESS,
          devices: data.devices,
        });
      })
      .catch((error) => {
        return dispatch({
          type: LIST_DEVICES_FAILURE,
          error: error,
        });
      });
  };
}

export function confirmDevice(new_device, remember_device) {
  return (dispatch) => {
    dispatch({
      type: CONFIRM_DEVICE_REQUEST,
    });
    new_device.device_name = JSON.stringify(deviceDetect());
    deviceService
      .confirmDevice(new_device)
      .then((data) => {
        return dispatch({
          type: CONFIRM_DEVICE_SUCCESS,
          new_device: new_device,
          remember_device: remember_device,
        });
      })
      .catch((error) => {
        return dispatch({
          type: CONFIRM_DEVICE_FAILURE,
          error: error,
        });
      });
  };
}

export function JustConfirmDevice(new_device, remember_device) {
  return (dispatch) => {
    dispatch({
      type: JUST_CONFIRM_DEVICE_REQUEST,
    });
    new_device.device_name = JSON.stringify(deviceDetect());
    deviceService
      .confirmDevice(new_device)
      .then((data) => {
        return dispatch({
          type: JUST_CONFIRM_DEVICE_SUCCESS,
          new_device: new_device,
          remember_device: remember_device,
        });
      })
      .catch((error) => {
        return dispatch({
          type: JUST_CONFIRM_DEVICE_FAILURE,
          error: error,
        });
      });
  };
}

export function rememberDevice(new_device) {
  return (dispatch) => {
    dispatch({
      type: REMEMBER_DEVICE_REQUEST,
    });
    deviceService
      .updateDeviceStatus(new_device.device_key, "remembered")
      .then((data) => {
        return dispatch({
          type: REMEMBER_DEVICE_FAILURE,
          new_device: new_device,
        });
      })
      .catch((error) => {
        return dispatch({
          type: REMEMBER_DEVICE_SUCCESS,
          error: error,
        });
      });
  };
}

export function forgetDevice(device_key, index) {
  return (dispatch) => {
    dispatch({
      type: FORGET_DEVICE_REQUEST,
    });
    deviceService
      .updateDeviceStatus(device_key, "not_remembered")
      .then((data) => {
        return dispatch({
          type: FORGET_DEVICE_SUCCESS,
          device_key: device_key,
          index: index,
        });
      })
      .catch((error) => {
        return dispatch({
          type: FORGET_DEVICE_FAILURE,
          error: error,
        });
      });
  };
}

export function deauthorizeInactiveDevices() {
  return (dispatch) => {
    dispatch({
      type: DEAUTHORIZE_INACTIVE_DEVICES_REQUEST,
    });
    return deviceService
      .deauthorizeInactiveDevices()
      .then((data) => {
        return dispatch({
          type: DEAUTHORIZE_INACTIVE_DEVICES_SUCCESS,
        });
      })
      .catch((error) => {
        return dispatch({
          type: DEAUTHORIZE_INACTIVE_DEVICES_FAILURE,
          error: error,
        });
      });
  };
}
