import styled from "styled-components";
import WithDirection from "settings/styles/withDirection";

const StatusDialogFormWrapper = styled.div`
  .suggestionBtn .emoji-mart-emoji {
    vertical-align: text-bottom;
  }

  .suggestionBtn > *:first-child {
    margin-right: 10px;
  }
`;

export default WithDirection(StatusDialogFormWrapper);
