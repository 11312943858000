import {all, takeEvery, put, fork} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import * as Actions from './actions';
import {notification} from "antd";

export function* confirmSignUp() {
    yield takeEvery(Actions.CONFIRM_SIGNUP_SUCCESS, function* () {
        yield put(
            push({
                pathname: '/auth/identify'
            })
        );
    });
}

export function* resendSignUpConfirmation() {
    yield takeEvery(Actions.RESEND_SIGNUP_CONFIRMATION_SUCCESS, function* () {
        notification.success({
            message: 'Confirmation code sent',
            description:
                'We got your back, we sent you another confirmation code.'
        });
        yield
    });
}

export function* onRequestConfirmationSuccess() {
    yield takeEvery(Actions.REQUEST_CONFIRMATION_SUCCESS, function* ({feedback}) {
        let context = {
            username: feedback.username,
            alias: feedback.alias,
            from: '/auth/identify'
        };
        if (feedback.delivery_medium === 'EMAIL')
            yield put(
                push({
                    pathname: '/registration/confirm/email',
                    state: context
                })
            );
        else
            yield put(
                push({
                    pathname: '/registration/confirm/phone',
                    state: context
                })
            );
    });
}

export default function* rootSaga() {
    yield all([
        fork(confirmSignUp),
        fork(resendSignUpConfirmation),
        fork(onRequestConfirmationSuccess)
    ]);
}
