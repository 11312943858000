import theme_downy from "./theme_downy";
import clone from "clone";

const theme = clone(theme_downy);
theme.palette.primary = [
  "#f3f3fe",
  "#e7e6fe",
  "#dbdafd",
  "#cfcdfd",
  "#c4c1fc",
  "#b8b5fb",
  "#aca8fb",
  "#a09cfa",
  "#948ffa",
  "#8883F9", // Base
  "#7a76e0",
  "#6d69c7",
  "#5f5cae",
  "#524f95",
  "#44427d",
  "#363464",
  "#29274b",
  "#1b1a32",
  "#0e0d19",
];
//theme.palette.secondary = ['#0f0'];
export default theme;
