import * as Actions from "./actions";

const initialState = {
  success: false,
  identities: [],
  profiles: [],
};

const users = function (state = initialState, action) {
  switch (action.type) {
    case Actions.LIST_USERS_IDENTITIES_REQUEST: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.LIST_USERS_IDENTITIES_SUCCESS: {
      return {
        ...state,
        success: true,
        identities: action.identities,
        next_page: action.next_page,
      };
    }
    case Actions.LIST_USERS_IDENTITIES_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.LIST_USERS_PROFILES_REQUEST: {
      return {
        ...state,
        success: true,
      };
    }
    case Actions.LIST_USERS_PROFILES_SUCCESS: {
      return {
        ...state,
        success: false,
        profiles: action.profiles,
      };
    }
    case Actions.LIST_USERS_PROFILES_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default users;
