import * as Actions from "./actions";

const initialState = {
  success: false,
  error: {
    previous_password: null,
  },
};

const changePassword = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...initialState,
        success: true,
      };
    }
    case Actions.CHANGE_PASSWORD_FAILURE: {
      return {
        success: false,
        error: {
          previous_password: "Incorrect password.",
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default changePassword;
