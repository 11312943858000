import theme_default from "./theme_default";
import theme_blue from "./theme_blue";
import theme_downy from "./theme_downy";
import theme_carnation from "./theme_carnation";
import theme_orange from "./theme_orange";
import theme_portage from "./theme_portage";

const themes = {
  theme_blue,
  theme_downy,
  theme_carnation,
  theme_orange,
  theme_portage,
  theme_default,
};
export default themes;
