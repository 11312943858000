import authService from 'services/auth';
import authZService from 'services/authorizer';
import AuthUtils from './utils'
import {SET_USER_DATA} from "redux/account/profile/actions";

export const AUTHENTICATION_REQUEST = 'AUTHENTICATION_REQUEST';
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';

export const SSO_REQUEST = 'SSO_REQUEST';
export const SSO_FAILURE = 'SSO_FAILURE';
export const SSO_SUCCESS = 'SSO_SUCCESS';

export const STS_AUTHORIZATION_REQUEST = 'STS_AUTHORIZATION_REQUEST';
export const STS_AUTHORIZATION_FAILURE = 'STS_AUTHORIZATION_FAILURE';
export const STS_AUTHORIZATION_SUCCESS = 'STS_AUTHORIZATION_SUCCESS';

export const INITIATE_AUTH_REQUEST = 'INITIATE_AUTH_REQUEST';
export const INITIATE_AUTH_FAILURE = 'INITIATE_AUTH_FAILURE';
export const INITIATE_AUTH_SUCCESS = 'INITIATE_AUTH_SUCCESS';

export const REFRESH_AUTH_REQUEST = 'REFRESH_AUTH_REQUEST';
export const REFRESH_AUTH_FAILURE = 'REFRESH_AUTH_FAILURE';
export const REFRESH_AUTH_SUCCESS = 'REFRESH_AUTH_SUCCESS';

export const DEVICE_AUTH_REQUEST = 'DEVICE_AUTH_REQUEST';
export const DEVICE_AUTH_FAILURE = 'DEVICE_AUTH_FAILURE';
export const DEVICE_AUTH_SUCCESS = 'DEVICE_AUTH_SUCCESS';

export const SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST';
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';

export const AUTO_LOGIN_REFRESH_AUTH = 'AUTO_LOGIN_REFRESH_AUTH';
export const AUTO_LOGIN_CURRENT_USER = 'AUTO_LOGIN_CURRENT_USER';
export const AUTO_LOGOUT = 'AUTO_LOGOUT';

export const SET_AUTH_DATA = 'SET_AUTH_DATA';
export const SET_AWS_STS_AUTHORIZATION = 'SET_AWS_STS_AUTHORIZATION';
export const POST_AUTHENTICATION = 'POST_AUTHENTICATION';


export function setAuthData(auth) {
    return (dispatch) => {
        /*
        Set Auth Data
         */
        dispatch({
            type: SET_AUTH_DATA,
            auth: auth
        })
    }
}

export function setAWSSTSAuthorization(authorization) {
    return (dispatch) => {
        /*
        Set AWS STS Credentials
         */
        dispatch({
            type: SET_AWS_STS_AUTHORIZATION,
            authorization: authorization
        })
    }
}

export function initiateAuth({username, password}) {
    return (dispatch) => {
        dispatch({
            type: INITIATE_AUTH_REQUEST
        });
        return authService.initiateAuth(username, password)
            .then((data) => {
                    return dispatch({
                        type: INITIATE_AUTH_SUCCESS,
                        auth: data
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: INITIATE_AUTH_FAILURE,
                    error: error
                });
            });
    };
}

export function ssoAuth(code) {
    return (dispatch) => {
        dispatch({
            type: SSO_REQUEST
        });
        return authService.ssoAuth(code)
            .then((data) => {
                    return dispatch({
                        type: SSO_SUCCESS,
                        auth: data
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: SSO_FAILURE,
                    error: error
                });
            });
    };
}

export function initiatePasswordLessAuth({username}) {
    return (dispatch) => {
        dispatch({
            type: INITIATE_AUTH_REQUEST
        });
        return authService.initiatePasswordLessAuth(username)
            .then((data) => {
                    return dispatch({
                        type: INITIATE_AUTH_SUCCESS,
                        auth: data
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: INITIATE_AUTH_FAILURE,
                    error: error
                });
            });
    };

}

export function refreshAuth() {
    return (dispatch) => {
        dispatch({
            type: REFRESH_AUTH_REQUEST
        });
        let identification = AuthUtils.getIdentification();
        return authService.refreshAuth(identification.username)
            .then((data) => {
                    return dispatch({
                        type: REFRESH_AUTH_SUCCESS,
                        auth: data
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: REFRESH_AUTH_FAILURE,
                    error: error
                });
            });
    };
}


export function deviceAuth(username) {
    return (dispatch) => {
        dispatch({
            type: DEVICE_AUTH_REQUEST
        });
        return authService.respondDeviceAuth(username, AuthUtils.getDeviceMetadata())
            .then((data) => {
                    return dispatch({
                        type: DEVICE_AUTH_SUCCESS,
                        auth: data
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: DEVICE_AUTH_FAILURE,
                    error: error
                });
            });
    };
}

export function authorize() {
    return (dispatch) => {
        dispatch({
            type: STS_AUTHORIZATION_REQUEST
        });
        return authZService.authorize()
            .then((data) => {
                    // Remove user data
                    return dispatch({
                        type: STS_AUTHORIZATION_SUCCESS,
                        authorization: data
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: STS_AUTHORIZATION_FAILURE,
                    payload: error
                });
            });
    }
}

export function globalSignOut() {
    return (dispatch) => {
        dispatch({
            type: SIGN_OUT_REQUEST
        });
        return authService.globalSignOut()
            .then((data) => {
                    // Remove user data
                    return dispatch({
                        type: SIGN_OUT_SUCCESS
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type: SIGN_OUT_FAILURE,
                    payload: error
                });
            });
    }
}

export function localSignOut() {
    return (dispatch) => {
        dispatch({
            type: SIGN_OUT_REQUEST
        });
        AuthUtils.localSignOut();
        return dispatch({
            type: SIGN_OUT_SUCCESS
        });
    };
}


export function booted() {
    return (dispatch) => {
        return dispatch({
            type: AUTHENTICATION_SUCCESS
        });
    }
}

export function checkAuthentication() {
    return (dispatch) => {

        dispatch({
            type: AUTHENTICATION_REQUEST
        });

        let auth = AuthUtils.getAuth();
        let deviceMetadata = AuthUtils.getDeviceMetadata();
        let stay_connected = AuthUtils.getStayConnected();

        // ========================= USER SIGNED IN ===============================
        if (!auth.access_token || !auth.id_token) {
            console.log("User not signed in, no tokens, initiate auth to get tokens and possibly new device " +
                "metadata if user has mfa enabled.");
            return dispatch({
                type: AUTHENTICATION_SUCCESS
            });
        }
        console.log("User already signed in with current device, checking tokens validity ...");

        // ====================== USER AUTHENTICATED ==============================
        let valid_jwt = AuthUtils.isAuthTokenValid(auth.access_token);

        if (valid_jwt) {
            console.log("User authenticated, get user details from local storage.");
            dispatch({
                type: SET_USER_DATA,
                user: AuthUtils.getUser()
            });
            dispatch({
                type: SET_AWS_STS_AUTHORIZATION,
                authorization: AuthUtils.getAWSSTSAuthorization()
            });
            return dispatch({
                type: AUTHENTICATION_SUCCESS
            });
        }

        // ======================= USER NOT AUTHENTICATED =========================
        else {
            if (auth.refresh_token && deviceMetadata.device_key && stay_connected) {
                console.log("User not authenticated, user chooses to stay connected. auto refresh tokens.");
                return dispatch({
                    type: AUTO_LOGIN_REFRESH_AUTH
                });
            }

            else {
                console.log("User not authenticated, user chooses to lock session, cleaning old tokens.");
                return dispatch({
                    type: AUTO_LOGOUT
                });
            }
        }
    };
}

export function preRequestAuthCheck(config) {
    let access_token = config.headers.Authorization;

    // ========================= PUBLIC ENDPOINT ===============================
    if (!access_token) {
        console.log("Skip, Public Endpoint.");
        return config;
    }
    // ====================== PRIVATE ENDPOINT ==============================
    else {
        console.log("Private Endpoint. checking");
        let valid_token = AuthUtils.isAuthTokenValid(access_token);
        if (valid_token) return config;
        else {
            console.log('Called');
            refreshAuth().then((response) => {
                let auth = AuthUtils.getAuth();
                config.headers.Authorization = `Bearer ${auth.access_token}`;
                return config;
            });
        }
    }
}


