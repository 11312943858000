import * as Actions from "./actions";

const initialState = {
  success: false,
  associate_request_sent: false,
  secret_code: null,
  qr_code: null,
};

const otpMFA = function (state = initialState, action) {
  switch (action.type) {
    case Actions.ASSOCIATE_OTP_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.ASSOCIATE_OTP_SUCCESS: {
      return {
        ...state,
        success: true,
        secret_code: action.secret_code,
        qr_code: action.qr_code,
        associate_request_sent: true,
      };
    }
    case Actions.ASSOCIATE_OTP_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.VERIFY_OTP_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        success: true,
        associate_request_sent: false,
      };
    }
    case Actions.VERIFY_OTP_FAILURE: {
      return {
        ...state,
        success: false,
      };
    }
    case Actions.OTP_MFA_UI_RESET:
      return {
        ...state,
        success: true,
        associate_request_sent: false,
      };
    default: {
      return state;
    }
  }
};

export default otpMFA;
