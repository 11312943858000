import React, {useEffect} from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import {Affix, Space, Switch, Tag, Select} from "antd";
import { useThemeSwitcher } from "react-css-theme-switcher";

import styledThemes from "settings/themes";

const initial = {
  user: null,
};

const { Option } = Select;

const colorsVariations = {
  downy: "#5CD6AD",
  blue: "#00B0FF",
  carnation: "#F75369",
  orange: "#FFBD54",
  portage: "#8883F9",
};

const handleMenuClick = e => {
    message.info('Click on menu item.');
    console.log('click', e);
};


const themesOptions = {
  // Dark
  dark_downy: "/css/themes/dark/downy.css",
  dark_blue: "/css/themes/dark/blue.css",
  dark_orange: "/css/themes/dark/orange.css",
  dark_portage: "/css/themes/dark/portage.css",
  dark_carnation: "/css/themes/dark/carnation.css",
  // Light
  light_downy: "/css/themes/light/downy.css",
  light_blue: "/css/themes/light/blue.css",
  light_orange: "/css/themes/light/orange.css",
  light_portage: "/css/themes/light/portage.css",
  light_carnation: "/css/themes/light/carnation.css",
};

const ThemeContext = React.createContext(initial);

function ThemeProvider({ children }) {
  const { status, switcher, currentTheme, themes } = useThemeSwitcher();

  // Avoid theme change flicker
  if (status === "loading") {
    return null;
  }

  useEffect(() => {
      let storedTheme = localStorage.getItem("theme") || "light_downy";
      const T = storedTheme.split("_");
      const mode=T [0], variation = T[1]
    if (process.env.REACT_APP_THEME_VARIANT && process.env.REACT_APP_THEME_VARIANT !== variation) {
        localStorage.setItem("theme", `${mode}_${process.env.REACT_APP_THEME_VARIANT}`);
        switcher({ theme: themes[`${mode}_${process.env.REACT_APP_THEME_VARIANT}`] });
    }
  });

  const toggleMode = (isChecked) => {
    const T = currentTheme.split("_");
    const mode=T [0], variation = T[1]

    if (isChecked) localStorage.setItem("theme", `dark_${variation}`);
    else localStorage.setItem("theme", `light_${variation}`);
    switcher({ theme: isChecked ? themes[`dark_${variation}`] : themes[`light_${variation}`] });
  };

    const handleChange = (new_variation) => {
        const T = currentTheme.split("_");
        const mode=T [0], variation = T[1]

        localStorage.setItem("theme", `${mode}_${new_variation}`);
        switcher({ theme: themes[`${mode}_${new_variation}`] });
    };

  return (
    <ThemeContext.Provider value={{ user: null }}>
        <StyledThemeProvider theme={styledThemes[`theme_${currentTheme ? currentTheme.split("_")[1] : "downy" }`]}>
            {children}
        </StyledThemeProvider>

      <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
          <Space align="center" size={"small"}>
              {
                  !process.env.REACT_APP_THEME_VARIANT
                  &&
                  <Select
                      size={"small"}
                      dropdownMatchSelectWidth={false}
                      placement="topRight"
                      showArrow={false}
                      bordered={false}
                      defaultValue={currentTheme ? currentTheme.split("_")[1] : "downy"}
                      onChange={handleChange}
                  >
                      <Option value="downy"><Tag color={colorsVariations["downy"]} style={{width: "100%", color: "#333", textAlign: "center"}}>downy</Tag></Option>
                      <Option value="blue"><Tag color={colorsVariations["blue"]} style={{width: "100%", color: "#333", textAlign: "center"}}>blue</Tag></Option>
                      <Option value="carnation"><Tag color={colorsVariations["carnation"]} style={{width: "100%", textAlign: "center"}}>carnation</Tag></Option>
                      <Option value="orange"><Tag color={colorsVariations["orange"]} style={{width: "100%", color: "#333", textAlign: "center"}}>orange</Tag></Option>
                      <Option value="portage"><Tag color={colorsVariations["portage"]} style={{width: "100%", textAlign: "center"}}>portage</Tag></Option>
                  </Select>
              }

            <Switch
                checked={currentTheme ? currentTheme.startsWith("dark_") : false}
                onChange={toggleMode}
                checkedChildren={<span>🌙</span>}
                unCheckedChildren={<span>☀</span>}
                style={{ background: "#555" }}
                size={"small"}
            />
          </Space>
      </Affix>
    </ThemeContext.Provider>
  );
}

const useTheme = () => React.useContext(ThemeContext);
export { ThemeProvider, useTheme, themesOptions };
