let mimotoDefaults = {
  REACT_APP_ENV: "DEV",
  REACT_APP_AUTHENTICATION_TYPE: "PASSWORD",
  REACT_APP_BUSINESS_NAME: "Default",
  REACT_APP_SENTRY_DSN: "add-dsn-here",
  REACT_APP_API_URL: "https://api.kodhive.com",
  REACT_APP_SOCIAL_REDIRECT_URI: "http://localhost:3000/cb/social",
  REACT_APP_SOCIAL_GOOGLE_AUTH_URL:
    "https://id.kodhive.com/oauth2/authorize?redirect_uri=http://localhost:3000/cb/social&prompt=select_account&response_type=code&client_id=3qdp4aepukuqv5k7q0aupo4dra&identity_provider=Google",
  REACT_APP_SOCIAL_FACEBOOK_AUTH_URL:
    "https://id.kodhive.com/oauth2/authorize?redirect_uri=http://localhost:3000/cb/social&prompt=select_account&response_type=code&client_id=3qdp4aepukuqv5k7q0aupo4dra&identity_provider=Facebook",
  REACT_APP_SOCIAL_GOOGLE_AUTH_ENABLED: "true",
  REACT_APP_SOCIAL_FACEBOOK_AUTH_ENABLED: "true",
  REACT_APP_SOCIAL_REDIRECT_PATH: "/callbacks/social",
  REACT_APP_MAP_KEY: "AIzaSyAYdp6JWyJaD-2Jiil3ggePxUBDIMpD-Kg",
  REACT_APP_DOMAIN_NAME: "localhost",
  REACT_APP_USER_POOL_ID: "us-east-1_EyugkQ3MJ",
  REACT_APP_USER_POOL_CLIENT_ID: "3qdp4aepukuqv5k7q0aupo4dra",
  REACT_APP_THEME_ENABLE_DARK_MODE: "true",
  REACT_APP_THEME_VARIANT: "downy",
};

const variables =
  typeof window !== "undefined" ? window["mimoto"] : mimotoDefaults;

const language = "english";

export { language, variables };
