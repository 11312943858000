import React from "react";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { ThemeProvider, themesOptions } from "context/ThemeProvider";
import { IntlProvider } from "react-intl";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";

import { store, history } from "redux/store";
import Boot from "redux/boot";
import Routes from "routes/root";
import AppLocale from "i18n";

import GlobalStyles from "settings/styles/globalStyle";
import { variables } from "settings";

import config, { getCurrentLanguage } from "i18n/switcher/config";

const currentAppLocale =
  AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];

const App = () => (
  <ThemeSwitcherProvider
    themeMap={themesOptions}
    defaultTheme={localStorage.getItem("theme") || "light_downy"}
  >
    <ThemeProvider>
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
            <Provider store={store}>
              <Routes history={history} />
            </Provider>
            <GlobalStyles />
        </IntlProvider>
      </ConfigProvider>
    </ThemeProvider>
  </ThemeSwitcherProvider>
);

Boot()
  .then(() => {
    return App();
  })
  .catch((error) => console.error(error));

export default App;
export { AppLocale };
