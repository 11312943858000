import * as Actions from './actions';

const initialState = {
    success: false,
    error  : {
        username: null
    }
};

const requestResetPassword = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.REQUEST_RESET_PASSWORD_SUCCESS:
        {
            return {
                ...initialState,
                success: true,
            };
        }
        case Actions.REQUEST_RESET_PASSWORD_FAILURE:
        {
            return {
                success: false,
                error  : {
                    username: 'User does not exist.'
                }
            };
        }
        case Actions.RESET_PASSWORD_SUCCESS:
        {
            return {
                ...initialState,
                success: true,
            };
        }
        case Actions.RESET_PASSWORD_FAILURE:
        {
            return {
                success: false,
                error  : {
                    confirmation_code: 'Incorrect confirmation code.'
                }
            };
        }
        default:
        {
            return state
        }
    }
};

export default requestResetPassword;