import {all, takeEvery, put, fork} from 'redux-saga/effects';
import * as Actions from './actions';
import * as AuthActions from "../../signin/actions";


export function* onRespondNewPasswordRequiredChallengeSuccess() {
    yield takeEvery(Actions.RESPOND_NEW_PASSWORD_REQUIRED_CHALLENGE_SUCCESS, function* ({auth}) {
        console.log(auth);
        yield put({type: AuthActions.POST_AUTHENTICATION, auth: auth, remember_device: false});
    });
}

export default function* rootSaga() {
    yield all([
        fork(onRespondNewPasswordRequiredChallengeSuccess),
    ]);
}
