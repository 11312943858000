const theme = {};

theme.palette = {
  // DARK GUNMETAL
  primary: [
    "#effbf7", // 0
    "#def7ef", // 1
    "#cef3e6", // 2
    "#beefde", // 3
    "#aeebd6", // 4
    "#9de6ce", // 5
    "#8de2c6", // 6
    "#7ddebd", // 7
    "#6cdab5", // 8
    "#5cd6ad", // 9 Base
    "#53c19c", // 10
    "#4aab8a", // 11
    "#409679", // 12
    "#378068", // 13
    "#2e6b57", // 14
    "#255645", // 15
    "#1c4034", // 16
    "#122b23", // 17
    "#091511", // 18
  ],
  secondary: [
    "#e9e9eb",
    "#d2d3d6",
    "#bcbec2",
    "#a6a8ad",
    "#909299",
    "#797c84",
    "#636670",
    "#4d515b",
    "#363b47",
    "#202532",
    "#1d212d",
    "#1a1e28",
    "#161a23",
    "#13161e",
    "#101319",
    "#0d0f14",
    "#0a0b0f",
    "#06070a",
    "#030405",
  ],
  tertiary: [
    "#fef3f5",
    "#fee7eb",
    "#fddce1",
    "#fcd0d7",
    "#fcc4cd",
    "#fbb8c3",
    "#faacb9",
    "#f9a1af",
    "#f995a5",
    "#f8899b",
    "#df7b8c",
    "#c66e7c",
    "#ae606d",
    "#95525d",
    "#7c454e",
    "#63373e",
    "#4a292e",
    "#321b1f",
    "#190e0f",
  ],
  color: [
    "#FEAC01", // 0: Orange
    "#42299a", // 1: Purple
    "#F75D81", // 2: Pink
    "#7ED321", // 3: LimeGreen
    "#39435f", // 4: BlueShade
    "#FFCA28", // 5: Yellow
    "#F2BD1B", // 6: Yellow Darken 5%
    "#3b5998", // 7: Facebook
    "#344e86", // 8: Facebook Darken 5%
    "#dd4b39", // 9: Google Plus
    "#d73925", // 10: Google Plus Darken 5%
    "#e14615", // 11: Auth0
    "#ca3f13", // 12: Auth0
    "#e0364c", // 13: themeColor--AlizarinCrimson
    "#292939", // 14
    "#ED6D52", // 15
  ],
  colors: {
    pink: "#FF0080",
    skyBlue: "#a3e8ff",
    darkBlue: "#357EDD",
    darkenBlue: "#0F256E",
    mayaBlue: "#80c3ff",
    red: "#f44336",
    gray: "#f5f5f5",
    black: "#000",
    cyan: "#3cb4c4",
    darkGray: "#808080",
    lightGray: "#afaeae",
    facebook: "#3B5998",
    twitter: "#55ACEE",
    pinterest: "#CB2027",
    white: "#fff",
    light: "#fcfeff",
    lightBlack: "#1a1a1a",
    darkPink: "#fd0b7b",
    smoke: "#d4d3d3",
    google: "#4285F4",
    lightGreen: "#00E398",
    lightPink: "#ff3399",
  },

  warning: [
    "#ffbf00", // 0: Warning
  ],

  success: [
    "#00b16a", // 0: Success
    "#5cd6ad", // 1: Lighten
  ],

  error: [
    "#f64744", // 0: Error
    "#EC3D3A", // 1: Darken 4%
    "#FF5B58", // 2: Lighten 8%
  ],

  grayscale: [
    "#bababa", // 0: GreyShade
    "#c1c1c1", // 1: GreyDark
    "#D8D8D8", // 2: Grey
    "#f1f1f1", // 3: GreyAlt
    "#F3F3F3", // 4: GreyLight
    "#fafafa", // 5: DarkWhite
    "#F9F9F9", // 6: DarkerWhite
    "#fcfcfc", // 7: #fff Darken 1%
    "#eeeeee", // 8:
    "#fbfbfb", // 9:
    "#f5f5f5", // 10:
    "#f7f8f9", // 11: today-highlight-bg
  ],

  text: [
    "#323332", // 0: Heading
    "#595959", // 1: HeadingLight
    "#979797", // 2: Text
    "#797979", // 3: TextDark
    "#6a6c6a", // 4: Heading Lighten 22%
  ],

  border: [
    "#e9e9e9", // 0: Border
    "#d8d8d8", // 1: BorderDark
    "#ebebeb", // 2: BorderLight
    "#d3d3d3", // 3:
    "rgba(228, 228, 228, 0.65)", // 4:
  ],
};

theme.fonts = {
  primary: "Roboto, sans-serif",
  pre: "Consolas, Liberation Mono, Menlo, Courier, monospace",
  weights: {
    normal: "400",
    medium: "500",
    bold: "700",
  },
};

theme.screens = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1600px",
};

export default theme;
