import theme_downy from "./theme_downy";
import clone from "clone";

const theme = clone(theme_downy);
theme.palette.primary = [
  "#e6f7ff",
  "#ccefff",
  "#b3e7ff",
  "#99dfff",
  "#80d8ff",
  "#66d0ff",
  "#4dc8ff",
  "#33c0ff",
  "#1ab8ff",
  "#00b0ff", // Base
  "#009ee6",
  "#008dcc",
  "#007bb3",
  "#006a99",
  "#005880",
  "#004666",
  "#00354c",
  "#002333",
  "#001219",
];


//theme.palette.secondary = ['#0f0'];
export default theme;
