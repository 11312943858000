import * as Actions from './actions';

const initialState = {
    success: false,
    auth: {
        authenticated: null,
        id_token: null,
        access_token: null,
        refresh_token: null,
        new_device: {
            device_key: null,
            device_group_key: null,
        },
        challenge_session: null,
        challenge_name: null,
        challenge_parameters: {
            USER_ID_FOR_SRP: null
        }
    },
    authorization: {
        identity_id: null,
        access_key_id: null,
        secret_key: null,
        session_token: null,
        sts_expires_in: null
    },
    error  : {
        password: null
    }
};

const initiateAuth = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.SET_AUTH_DATA:
        {
            return {
                success: true,
                auth   : action.auth
            };
        }
        case Actions.INITIATE_AUTH_SUCCESS:
        {
            return {
                ...initialState,
                success: true,
                auth   : action.auth
            };
        }
        case Actions.SET_AWS_STS_AUTHORIZATION:
        {
            return {
                success       : true,
                authorization : action.authorization
            };
        }
        case Actions.STS_AUTHORIZATION_SUCCESS:
        {
            return {
                ...initialState,
                success       : true,
                authorization   : action.authorization
            };
        }
        case Actions.INITIATE_AUTH_FAILURE:
        {
            return {
                ...initialState,
                success: false,
                error  : action.error
            };
        }
        case Actions.REFRESH_AUTH_SUCCESS:
        {
            return {
                ...initialState,
                success: true,
                auth   : action.auth
            };
        }
        case Actions.REFRESH_AUTH_FAILURE:
        {
            return {
                success: false,
                error  : action.error
            };
        }
        case Actions.SIGN_OUT_SUCCESS:
        {
            return {
                ...initialState,
                success: true,
            };
        }
        default:
        {
            return state
        }
    }
};

export default initiateAuth;